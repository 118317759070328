import {
	renameModelConfigAction,
	setModelConfigIsCreating,
	setModelConfigNameError,
} from '@Actions/model.actions';
import { StylovyzeDialog } from '@innovyze/stylovyze';
import { DialogProps, FormControl, Grid, TextField } from '@mui/material';
import {
	selectModelConfig,
	selectModelConfigNameError,
	selectModelIsSaving,
	selectModelSetting,
} from '@Selectors/model.selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModelConfig } from '@Types';
import { useGlobalization } from '@Translations/useGlobalization';

interface Props {
	open: boolean;
	onClose: (saved: boolean) => void;
}

export const RenameModel = ({ open, onClose }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const settings = selectModelSetting();
	const modelConfig = selectModelConfig();

	const [newName, setNewName] = useState('');
	const configNameError = selectModelConfigNameError();
	const isSaving = selectModelIsSaving();

	const handleCancelNewModel = () => {
		onClose(false);
	};

	useEffect(() => {
		dispatch(setModelConfigNameError(''));
		setNewName(settings.name);
	}, [settings, open]);

	useEffect(() => {
		if (open && !isSaving && configNameError.length == 0) {
			onClose(true);
		}
	}, [isSaving]);

	const handleRenameModel = () => {
		if (configNameError.length == 0) {
			dispatch(setModelConfigIsCreating(false));
			const newSettings = { ...settings };
			newSettings.name = newName.trim();

			const newModelConfig: ModelConfig = {
				...modelConfig,
				modelSetting: newSettings,
			};
			dispatch(renameModelConfigAction(newModelConfig));
		}
	};

	const handlelNameChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		let error = '';
		if (event.target && event.target.value !== '') {
			setNewName(event.target.value);
			const rexp = new RegExp('^[a-zA-Z0-9 ]+$');
			const test = rexp.test(event.target.value);
			if (!test) {
				error = t('Only alphanumeric characters are allowed');
			}

			const rexp2 = new RegExp('^.{1,50}$');
			const test2 = rexp2.test(event.target.value);
			if (!test2) {
				error = t('Name cannot contain more than 50 characters');
			}
		} else {
			setNewName('');
			error = t('Name is required');
		}
		dispatch(setModelConfigNameError(error));
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: open,
		fullWidth: true,
		maxWidth: 'sm',
		onClose: onCloseDialog,
	};

	return (
		<StylovyzeDialog
			dialogProps={dialogProps}
			data-cy="new-dialog"
			title={t('Rename Model Model')}
			open={open}
			cancelText={t('Cancel')}
			cancelButtonProps={{
				disabled: isSaving,
				dataCy: 'rename-cancel',
			}}
			onCancel={handleCancelNewModel}
			confirmText={t('OK')}
			confirmButtonProps={{
				disabled:
					isSaving ||
					configNameError.length !== 0 ||
					settings.name.trim().toLowerCase() ===
						newName.trim().toLowerCase(),
				dataCy: 'rename-confirm',
			}}
			onConfirm={handleRenameModel}>
			<Grid container>
				<Grid item xs={12}>
					<FormControl variant="outlined" margin="normal" fullWidth>
						<TextField
							fullWidth
							defaultValue={settings.name}
							error={configNameError.length !== 0}
							required
							name="name"
							label={t('Edit Name')}
							variant="outlined"
							onChange={handlelNameChangeValue}
							helperText={configNameError}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</StylovyzeDialog>
	);
};
