import { NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const UtilStoreSelector = createSelector(parseNamespace, state => state.util);

const RiskScoresSelector = createSelector(
	UtilStoreSelector,
	state => state.riskScores,
);

const RiskScoresEditSelector = createSelector(
	UtilStoreSelector,
	state => state.riskEditScores,
);

const RiskUnitScoresSelector = createSelector(
	UtilStoreSelector,
	state => state.riskUnitScores,
);

const RiskScoreTableSelector = createSelector(
	UtilStoreSelector,
	state => state.riskScoreTable,
);

const RiskScoreDataGridSelector = createSelector(
	UtilStoreSelector,
	state => state.riskScoreDataGrid,
);

const RiskMultiScoreDataGridSelector = createSelector(
	UtilStoreSelector,
	state => state.riskMultiScoreDataGrid,
);

const RiskMultiScoreEditDataGridSelector = createSelector(
	UtilStoreSelector,
	state => state.riskMultiScoreEditDataGrid,
);

const RiskNullScoreSelector = createSelector(
	UtilStoreSelector,
	state => state.riskNullScore,
);

const RiskStatusSelector = createSelector(
	UtilStoreSelector,
	state => state.riskStatus,
);

const RiskRunningSelector = createSelector(
	UtilStoreSelector,
	state => state.riskRunning,
);

const RiskLastRunSelector = createSelector(
	UtilStoreSelector,
	state => state.riskLastRun,
);

export const selectRiskScores = () => useSelector(RiskScoresSelector);

export const selectRiskEditScores = () => useSelector(RiskScoresEditSelector);

export const selectRiskUnitScores = () => useSelector(RiskUnitScoresSelector);

export const selectRiskScoreTable = () => useSelector(RiskScoreTableSelector);

export const selectRiskScoreDataGrid = () =>
	useSelector(RiskScoreDataGridSelector);

export const selectRiskMultiScoreDataGrid = () =>
	useSelector(RiskMultiScoreDataGridSelector);

export const selectRiskMultiScoreEditDataGrid = () =>
	useSelector(RiskMultiScoreEditDataGridSelector);

export const selectRiskNullScore = () => useSelector(RiskNullScoreSelector);

export const selectRiskStatus = () => useSelector(RiskStatusSelector);

export const selectRiskRunning = () => useSelector(RiskRunningSelector);

export const selectRiskLastRun = () => useSelector(RiskLastRunSelector);

export const DataGridSateSelector = createSelector(
	UtilStoreSelector,
	state => state.dataGridState,
);

const selectorDataGridState = (key: string) => {
	return createSelector(DataGridSateSelector, state => {
		if (state) {
			return state[key];
		}
	});
};

export const selectDataGridState = (key: string) =>
	useSelector(selectorDataGridState(key));

export default selectRiskScores;
