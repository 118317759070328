import {
	componentReducer as ComponentStore,
	initState as componentInitState,
} from './component.reducers';
import {
	modelReducer as ModelStore,
	initState as modelInitState,
} from './model.reducers';
import {
	failureReducer as FailureStore,
	initState as failureInitState,
} from './failure.reducers';
import {
	riskReducer as RiskStore,
	initState as riskInitState,
} from './Risk.reducers';
import {
	assetReducer as assetFields,
	initState as assetFieldsState,
} from './asset.reducers';
import {
	modelConfigsListReducer as modelConfigsList,
	initialState as modelConfigsListState,
} from './modelConfigsList.reducer';
import { modelConfigsReducer as modelConfigs } from './modelConfigs.reducer';
import {
	failureConfigsListReducer as failureConfigsList,
	initialState as failureConfigsListState,
} from './failureConfigsList.reducer';
import { failureConfigsReducer as failureConfigs } from './failureConfigs.reducer';

import { StoreState } from '@Types';
import { combineReducers } from 'redux';
import { moduleNamespace } from '@Store/config.store';
import { utilReducer as util } from './util.reducer';
import { utilStoreStateInitial } from '@Types/utils.types';
import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';

export const initialState: StoreState = {
	ModelStore: modelInitState,
	FailureStore: failureInitState,
	RiskStore: riskInitState,
	assetFields: assetFieldsState,
	ComponentStore: componentInitState,
	util: utilStoreStateInitial(),
	modelConfigs: paginationInitialState,
	modelConfigsList: modelConfigsListState,
	failureConfigs: paginationInitialState,
	failureConfigsList: failureConfigsListState,
};

export default combineReducers({
	ModelStore,
	FailureStore,
	RiskStore,
	assetFields,
	ComponentStore,
	util,
	modelConfigs,
	modelConfigsList,
	failureConfigs,
	failureConfigsList,
});

export const reducerKey = moduleNamespace;
