import { GridColDef } from '@mui/x-data-grid-premium';
import { useGlobalization } from '@Translations/useGlobalization';

export const tableColumns = (): GridColDef[] => {
	const { t } = useGlobalization();

	return [
		{
			field: 'name',
			headerName: t('Name'),
			sortable: false,
			hideable: false,
			align: 'left',
			flex: 1,
		},
		{
			field: 'type',
			headerName: t('Type'),
			sortable: false,
			hideable: false,
			align: 'left',
			flex: 1,
		},
		{
			field: 'dataSource',
			headerName: t('Data Source'),
			sortable: false,
			hideable: false,
			align: 'left',
			flex: 1,
		},
		{
			field: 'field',
			headerName: t('Field'),
			sortable: false,
			hideable: false,
			align: 'left',
			flex: 1,
		},
		{
			field: 'distance',
			headerName: t('Distance'),
			sortable: false,
			hideable: false,
			align: 'left',
			flex: 1,
		},
	];
};
