import {
	Button,
	CancellableEvent,
	error,
	getSubscriptions,
	useIsFeatureEnabled,
	success,
	Text,
	WarningDialog,
	NavigationPrompt,
	useCompanyDateTime,
} from '@innovyze/stylovyze';
import { useAuth0 } from '@innovyze/stylovyze/lib/contexts/AuthenticationWrapper';
import { Category, Component } from '@Types';
import { RenameModel, EditModel } from '@Components';
import { FullPageWrapper } from '@Utils/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	selectModelTab,
	selectModelStore,
	selectModelConfig,
	selectModelSetting,
} from '@Selectors';
import {
	setModelSaveRun,
	// getProximityAttributeFields,
	getSpatialLayerList,
	setModelRefreshList,
	getModelConfig,
	setModelSetupDirty,
	changeModelConfigsPage,
	getAttributeFields,
	getAssetFields,
	getProximityAttributeFields,
} from '@Actions';
import { viewerAccess } from '@innovyze/shared-utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { addRouteNamespace } from '@Utils/actions';
import { DialogProps, Grid, IconButton } from '@mui/material';
import { Create } from '@mui/icons-material';
import { useGlobalization } from '@Translations/useGlobalization';
import { selectModelConfigsPage } from '@Selectors/modelConfigs.selectors';

export const ModelManagement = ({ page }: { page?: number }) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const params: { configId: string } = useParams();
	const history = useHistory();
	const location = useLocation<{ pageIndex: number }>();
	const pageIndex = location.state?.pageIndex || page;
	const { token } = useAuth0();
	// const isModelSetupDirty = selectIsModelSetupDirty();

	const subscriptions = getSubscriptions();
	const hasViewerAccess = viewerAccess(subscriptions);

	const [isNew, setNew] = useState(true);

	// Initial 2 groups
	const [groups, setGroups] = useState<Component[]>([
		{
			name: '',
			select: false,
			weighting: 0,
			type: '',
			metadata: undefined,
			metadataType: undefined,
			maxDistance: undefined,
			field: undefined,
			fieldAlias: undefined,
			nullScore: undefined,
		},
		{
			name: '',
			select: false,
			weighting: 0,
			type: '',
			metadata: undefined,
			metadataType: undefined,
			maxDistance: undefined,
			field: undefined,
			fieldAlias: undefined,
			nullScore: undefined,
			unused: true,
		},
	]);

	const tab = selectModelTab();
	const modelStore = selectModelStore();
	const modelPage = selectModelConfigsPage();
	const modelConfig = selectModelConfig();
	const modelSetting = selectModelSetting();
	const [openCancel, setOpenCancel] = useState(false);

	const [editName, setEditName] = useState(false);
	const [completeLoad, setCompleteLoad] = useState(false);

	useEffect(() => {
		// This is to stop it reloading everytime the tab changes
		if (modelStore.modelConfig.modelSetting.configId !== params.configId) {
			setCompleteLoad(true);
			dispatch(getModelConfig(params.configId));

			// If we have a new config ID and we aren't on the details tab switch to it
			if (tab != 'details')
				history.push(addRouteNamespace(`results/${params.configId}`));
		}
	}, []);

	useEffect(() => {
		// This so we dont try and load everything at once
		if (
			completeLoad &&
			modelStore.modelConfig.modelSetting.configId === params.configId
		) {
			setCompleteLoad(false);
			// dispatch(getLog(params.configId));
			dispatch(
				getAttributeFields(
					modelStore.modelConfig.modelSetting.assetType,
				),
			);
			// dispatch(
			// 	getAssetFields(modelStore.modelConfig.modelSetting.assetType),
			// );
			dispatch(getSpatialLayerList());
			dispatch(
				getProximityAttributeFields(
					modelStore.modelConfig.modelSetting.assetType,
				),
			);
		}
	}, [completeLoad, modelStore]);

	const modelTab = (
		<>
			<EditModel
				isNew={true}
				assetType={modelStore.modelConfig.modelSetting.assetType}
				countKey={isNew ? 'NewConfig' : params.configId}
				groups={groups}
			/>
			{/* <NavigationPrompt
				title={t('Add New Component')}
				content={t(
					'Navigating away from this page will cancel the new component. Do you want to continue to navigate away from this page?',
				)}
				cancelText={t('No')}
				confirmText={t('Yes')}
				when={true}
				shouldBlockReload={true}
				navigate={nextLocation => {
					history.push(nextLocation);
				}}
				shouldBlockNavigation={() => true}
			/> */}
		</>
	);

	// const riskTab = <RiskSetup />;

	const refresh = () => {
		dispatch(getModelConfig(params.configId));
	};

	const isConfigValid = () => {
		return true;
	};

	const canBeSaved = () => {
		// return lofStore.isChanged && isConfigValid();
		return false;
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const saveConfig = () => {
		dispatch(setModelSetupDirty(false));
		dispatch(setModelSaveRun(true));
		dispatch(setModelRefreshList(true));
	};

	const onEditName = () => {
		setEditName(true);
	};

	const onEditNameClose = (saved: boolean) => {
		setEditName(false);
		if (saved) {
			dispatch(changeModelConfigsPage(modelPage.page));
		}
	};

	const getSecondary = () => {
		if (tab === 'lof' || tab === 'cof') {
			return (
				<Grid container width="350px" style={{ textAlign: 'end' }}>
					<Grid item xs={7} style={{ alignSelf: 'center' }}></Grid>
					<Grid item xs={5}>
						<Button
							disabled={!canBeSaved()}
							variant="contained"
							color="primary"
							style={{
								height: '36px',
								marginLeft: '12px',
								width: '100%',
							}}
							onClick={saveConfig}>
							{t('Save')}
						</Button>
					</Grid>
				</Grid>
			);
		} else {
			return (
				<Grid container width="350px" style={{ textAlign: 'end' }}>
					<Grid item xs={12} style={{ alignSelf: 'center' }}></Grid>
				</Grid>
			);
		}
	};

	const beforeTabChange = (event: CancellableEvent): CancellableEvent => {
		event.cancelled = true;
		switch (event.toIndex) {
			case 0:
				history.push(
					addRouteNamespace(`modelSetup/${params.configId}`),
				);
				break;
		}
		return event;
	};

	const back = () => {
		history.push('/deterioration/');
	};

	const isLoaded = modelStore.modelConfig.modelSetting.name.length != 0;

	return (
		<>
			<FullPageWrapper
				title={
					modelStore.modelConfig.modelSetting.name.length == 0
						? ' '
						: modelStore.modelConfig.modelSetting.name
				}
				minorHeader={
					!hasViewerAccess && isLoaded ? (
						<div
							style={{
								paddingLeft: '20px',
								paddingRight: '20px',
							}}>
							<IconButton
								title={t('Edit Name')}
								data-cy="editButton"
								onClick={onEditName}>
								<Create />
							</IconButton>
						</div>
					) : (
						<></>
					)
				}
				applyPadding={true}
				headerUnderline
				secondary={
					modelStore.modelConfig.modelSetting ? getSecondary() : <></>
				}
				back={{
					action: back,
					label: t('Back to Deterioration Models'),
				}}
				secondaryNav={{
					items: [
						{
							content: modelTab,
							title: t('Deterioration Model'),
							disabled: !isLoaded || hasViewerAccess,
						},
						{
							content: [],
							title: t('Results'),
							disabled: !isLoaded || hasViewerAccess,
						},
						{
							content: [],
							title: t('Logs'),
							disabled: !isLoaded || hasViewerAccess,
						},
					],
					itemIndex: pageIndex,
					beforeItemIndexChanged: beforeTabChange,
				}}></FullPageWrapper>

			<RenameModel open={editName} onClose={onEditNameClose} />
		</>
	);
};

export default ModelManagement;
