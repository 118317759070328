/*eslint import/no-unresolved: [2, { ignore: ['\\.lib_am_common/Reducers$'] }]*/
import * as AM_COMMON from '@innovyze/lib_am_common/Reducers';

import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
// import { mapRowToCells, mapRowToCellsNoCD } from '@Reducers';
// import { noConditionData } from '@Components/ModelDetails/ModelDetails.component';
import { ModelDetailsRow } from '@Types';
import { GridRowId } from '@innovyze/stylovyze';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const modelStoreSelector = createSelector(
	parseNamespace,
	state => state.ModelStore,
);

export const ModelConfigSelector = createSelector(
	modelStoreSelector,
	ModelStore => ModelStore.modelConfig,
);

const ModelSettingSelector = createSelector(
	modelStoreSelector,
	ModelStore => ModelStore.modelConfig.modelSetting,
);

const ModelTabSelector = createSelector(
	modelStoreSelector,
	riskStore => riskStore.tab,
);

export const selectorModelDetailsPagination = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails?.pagination,
);

export const selectorModelDetailsRows = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails?.rows as unknown as ModelDetailsRow[],
);

const selectorIsModelSetupDirty = createSelector(
	modelStoreSelector,
	riskStore => riskStore.isSetupDirty,
);

export const searchModelQuerySelector = createSelector(
	modelStoreSelector,
	riskStore =>
		riskStore.search.length
			? `&search=${encodeURIComponent(riskStore.search)}`
			: '',
);

// export const modelDetailsQuerySelector = createSelector(
// 	riskStoreSelector,
// 	selectorPaginationQuery,
// 	selectorFilterQuery,
// 	searchQuerySelector,
// 	(riskStore, pag, filters, search) => {
// 		const settings = riskStore.modelConfig.modelSetting;
// 		const modelDetails = riskStore.modelDetails;
// 		let q = pag;
// 		q = q.length
// 			? q + `&configId=${settings.configId}`
// 			: `?configId=${settings.configId}`;
// 		if (modelDetails.scanId) {
// 			q = q.length
// 				? q + `&scanId=${modelDetails.scanId}`
// 				: `?scanId=${modelDetails.scanId}`;
// 		}
// 		if (filters.length) {
// 			q += filters;
// 		}
// 		if (search.length) {
// 			q += search;
// 		}
// 		q = q + '&includeScores=true';
// 		return q;
// 	},
// );

// const selectorModelDetailsTablePage = createSelector(
// 	[
// 		selectorModelDetailsPagination,
// 		selectorModelDetailsRows,
// 		ModelConfigSelector,
// 	],
// 	(pagination, rows, config) => ({
// 		pages: pagination.pages,
// 		page: pagination.page,
// 		cells: rows.map(r =>
// 			noConditionData(config.modelSetting.assetType)
// 				? mapRowToCellsNoCD(r)
// 				: mapRowToCells(r),
// 		),
// 		rowIds: rows.map(r => r?._id || ''),
// 		sortKey: pagination.sortKey,
// 		sortDescending: pagination.sortDescending,
// 		total: pagination.total,
// 	}),
// );

const selectorCanReload = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails?.pagination?.total > 0,
);

const selectorRunCompleteReload = createSelector(
	modelStoreSelector,
	riskStore => riskStore.runCompleteReload,
);

const selectorModelDetailsSlice = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails.pagination.slice,
);

const selectorModelDetailsIsLoading = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails.isLoading,
);
const selectorIsLoading = createSelector(
	modelStoreSelector,
	riskStore => riskStore.isLoading,
);

const selectorModelDetailsIsWaiting = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails.isWaiting,
);

const selectorSaveRunSettings = createSelector(
	modelStoreSelector,
	riskStore => riskStore.saveRun,
);

const selectorModelDetailsSearch = createSelector(
	modelStoreSelector,
	riskStore => riskStore.search,
);

const selectorModelConfigNameError = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelConfigNameError,
);

const selectorModelConfigIsCreating = createSelector(
	modelStoreSelector,
	riskStore => riskStore.isCreating,
);

const selectorModelConfigIsSaving = createSelector(
	modelStoreSelector,
	riskStore => riskStore.isSaving,
);

const selectorModelIsDeleting = createSelector(
	modelStoreSelector,
	riskStore => riskStore.isDeleting,
);

const modelDetailsStatusSelector = createSelector(
	modelStoreSelector,
	riskStore => riskStore.modelDetails.status,
);

const selectorRefreshList = createSelector(
	modelStoreSelector,
	riskStore => riskStore.refreshList,
);

const passInSelection = (
	state: NamespacedStoreState,
	selectedItems: GridRowId[],
): GridRowId[] => selectedItems;

export const selectSelectionGridRows = createSelector(
	[selectorModelDetailsRows, passInSelection],
	(rows, selection) => rows?.filter(({ _id }) => selection.includes(_id)),
);

export const selectSelectedModelRows = createSelector(
	[selectSelectionGridRows],
	rows =>
		rows.map(({ _id }) => ({
			_id,
		})),
);

export const selectCanReload = () => useSelector(selectorCanReload);

export const selectorRowsPerPage = (dispatchSliceFn: (slice: number) => void) =>
	createSelector(selectorModelDetailsSlice, slice =>
		AM_COMMON.rowsPerPage(slice || 10, dispatchSliceFn),
	);
export const selectRunCompleteReload = () =>
	useSelector(selectorRunCompleteReload);

export const selectRefreshList = () => useSelector(selectorRefreshList);

export const selectModelStore = () => useSelector(modelStoreSelector);

export const selectModelConfig = () => useSelector(ModelConfigSelector);

export const selectModelSetting = () => useSelector(ModelSettingSelector);

export const selectModelTab = () => useSelector(ModelTabSelector);

// export const selectModelDetailsQuery = () =>
// 	useSelector(modelDetailsQuerySelector);

// export const selectModelDetailsTablePage = () =>
// 	useSelector(selectorModelDetailsTablePage);

export const selectModelDetailsPagination = () =>
	useSelector(selectorModelDetailsPagination);

export const selectRowsPerPage = (dispatchSliceFn: (slice: number) => void) =>
	useSelector(selectorRowsPerPage(dispatchSliceFn));
export const selectModelDetailsIsLoading = () =>
	useSelector(selectorModelDetailsIsLoading);
export const selectIsLoading = () => useSelector(selectorIsLoading);
export const selectModelDetailsIsWaiting = () =>
	useSelector(selectorModelDetailsIsWaiting);

export const selectSaveRunSettings = () => useSelector(selectorSaveRunSettings);

export const selectModelDetailsSearch = () =>
	useSelector(selectorModelDetailsSearch);
export const selectModelDetailsFilterProps = () =>
	useSelector(selectorIsModelSetupDirty);
export const selectModelConfigNameError = () =>
	useSelector(selectorModelConfigNameError);
export const selectModelConfigIsCreating = () =>
	useSelector(selectorModelConfigIsCreating);
export const selectModelIsSaving = () =>
	useSelector(selectorModelConfigIsSaving);
export const selectModelIsDeleting = () => useSelector(selectorModelIsDeleting);

export const selectModelDetailsStatus = () =>
	useSelector(modelDetailsStatusSelector);
