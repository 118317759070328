import React, { FocusEvent, useState } from 'react';
import {
	CurrencyField,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	UnitField,
} from '@innovyze/stylovyze';
import { TextField } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-premium';

interface RangeEditCellProps {
	id: GridRowId;
	value: string;
	onRangeChange: (index: number, value: number, valueUnit?: number) => void;
	disabled: boolean;
	unit?: string;
	currency?: string;
	precision?: number;
}

export const RangeEditCell = ({
	id,
	value,
	onRangeChange,
	disabled,
	unit,
	currency,
	precision,
}: RangeEditCellProps) => {
	const [changed, setChanged] = useState<boolean>(false);

	const step =
		precision === undefined || precision === 0
			? '1'
			: (1.0 / Math.pow(10, precision)).toFixed(precision);

	if (unit && unit.length !== 0) {
		return (
			<UnitField
				data-cy={`range-${id}`}
				style={{ width: '100%' }}
				unit={unit}
				defaultValue={value}
				disabled={disabled}
				step={step}
				onChange={() => {
					setChanged(true);
					// Dont do anything else on change otherwise it will adjust the ranges as they type the first character
				}}
				onBlur={(
					e: FocusEvent<
						HTMLInputElementExtended | HTMLTextAreaElementExtended
					>,
				) => {
					if (changed) {
						const newValue = parseFloat(e.target.value);
						const valueUnit = e.target.originalValue
							? parseFloat(e.target.originalValue)
							: undefined;

						if (!isNaN(newValue)) {
							onRangeChange(+id, newValue, valueUnit);
							setChanged(false);
						}
					}
				}}
				size="small"
			/>
		);
	} else if (currency !== undefined) {
		return (
			<CurrencyField
				data-cy={`score-${id}`}
				style={{ width: '100%' }}
				// For the moment just use the default system currency... this MIGHT be used later
				// currency={currency}
				defaultValue={value}
				disabled={disabled}
				onChange={() => {
					setChanged(true);
					// Dont do anything else on change otherwise it will adjust the ranges as they type the first character
				}}
				onBlur={(e: { target: { value: string } }) => {
					if (changed) {
						onRangeChange(+id, +e.target.value);
						setChanged(false);
					}
				}}
				step={step}
				variant="outlined"
				size="small"
			/>
		);
	} else {
		return (
			<TextField
				data-cy={`range-${id}`}
				key={`range-${id}-${value}`}
				style={{ width: '100%' }}
				defaultValue={value}
				disabled={disabled}
				onChange={() => {
					setChanged(true);
					// Dont do anything else on change otherwise it will adjust the ranges as they type the first character
				}}
				onBlur={(e: { target: { value: string } }) => {
					if (changed) {
						onRangeChange(+id, +e.target.value);
						setChanged(false);
					}
				}}
				inputProps={{
					step: step,
				}}
				variant="outlined"
				type="number"
				size="small"
			/>
		);
	}
};
