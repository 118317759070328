/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { addRouteNamespace } from '@Utils/actions';
import {
	getSubscriptions,
	PropertyCard,
	useCompanyDateTime,
} from '@innovyze/stylovyze';
import '../Utils/listCard.css';
import { fullAccess } from '@innovyze/shared-utils';
import { useGlobalization } from '@Translations/useGlobalization';
import { formatDateTimeWithFormatDate } from '@innovyze/lib_am_common/Utils';
import { AssetCount, AssetCounts } from '@innovyze/lib_am_common';
import { useDispatch } from 'react-redux';
import {
	changeModelConfigPublishState,
	clearModelConfig,
	duplicateModelConfig,
} from '@Actions/model.actions';
import {
	selectAssetCounts,
	selectIsAssetCountsReady,
} from '@innovyze/lib_am_common/Selectors';
import { changeModelConfigsPage } from '@innovyze/lib_am_common/Actions';
import { Link, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { green, grey } from '@mui/material/colors';
import { ConfigPublishState, ModelConfigCard } from '@Types/modelConfig.types';
import { selectModelConfigsPage } from '@Selectors/modelConfigs.selectors';

interface Props {
	modelConfig: ModelConfigCard;
	onDeleteModel: (id: string, publishState: ConfigPublishState) => void;
}
const ModelListCard = ({ modelConfig, onDeleteModel }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const history = useHistory();
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);

	const { formatDate } = useCompanyDateTime();
	const lastRun = formatDateTimeWithFormatDate(
		modelConfig.lastRun,
		formatDate,
	);
	const modelPage = selectModelConfigsPage();
	const isAssetCountsReady = selectIsAssetCountsReady();
	const assetCounts: AssetCounts = selectAssetCounts();

	const handleViewClick = () => {
		dispatch(clearModelConfig());
		history.push(addRouteNamespace(`results/${modelConfig.configId}`));
	};

	const handleDeleteClick = () => {
		onDeleteModel(modelConfig.configId, modelConfig.publishState);
	};

	const onDuplicateComplete = (configId: string) => {
		if (configId) {
			dispatch(clearModelConfig());
			history.push(addRouteNamespace(`results/${configId}`));

			dispatch(changeModelConfigsPage(modelPage.page));
		}
	};

	const handlePublishStateChange = (newPublishState: ConfigPublishState) => {
		dispatch(
			changeModelConfigPublishState({
				newPublishState,
				configId: modelConfig.configId,
				refresh: () => {
					dispatch(changeModelConfigsPage(modelPage.page));
				},
			}),
		);
	};

	const handleDuplicateClick = () => {
		dispatch(
			duplicateModelConfig({
				configId: modelConfig.configId,
				onComplete: onDuplicateComplete,
			}),
		);
	};

	const formatRunBy = (runBy: string) => {
		if (runBy.indexOf('@') !== -1) {
			return runBy.substring(0, runBy.lastIndexOf('@'));
		}
		return runBy;
	};

	const menuItems = () => {
		const items = [];

		if (hasFullAccess) {
			if (modelConfig.publishState === ConfigPublishState.published) {
				items.push({
					onClick: () =>
						handlePublishStateChange(
							ConfigPublishState.unpublished,
						),
					text: t('Unpublish'),
				});
			} else {
				items.push({
					onClick: () =>
						handlePublishStateChange(ConfigPublishState.published),
					text: t('Publish'),
				});
			}

			items.push({
				onClick: handleDuplicateClick,
				text: t('Duplicate'),
			});
			items.push({
				onClick: handleDeleteClick,
				text: t('Delete'),
			});
		}

		return items;
	};

	const typeCell = (type?: string) => {
		switch (type) {
			case 'wwPipe':
				return t('Sanitary Sewer - Pipe');
			case 'wwManhole':
				return t('Sanitary Sewer - Manhole');
			case 'pipe':
				return t('Water Distibution - Pipe');
			default:
				return '-';
		}
	};

	const renderTitle = useCallback(
		(configName: string, publishState: ConfigPublishState) => {
			if (
				hasFullAccess ||
				publishState === ConfigPublishState.published
			) {
				return (
					<Link
						underline="hover"
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							cursor: 'pointer',
							width: '100%',
							whiteSpace: 'nowrap',
						}}
						title={configName}
						onClick={() => {
							handleViewClick();
						}}
						data-cy="card-title">
						{configName}
					</Link>
				);
			} else {
				return (
					<div
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: '100%',
							whiteSpace: 'nowrap',
						}}>
						{configName}
					</div>
				);
			}
		},
		[],
	);

	const renderHeader = (
		configName: string,
		configPublishState: ConfigPublishState,
	) => {
		const tooltipText =
			configPublishState === ConfigPublishState.published
				? t('Published')
				: t('Unpublished');

		const icon =
			configPublishState === ConfigPublishState.published ? (
				<CheckCircleIcon sx={{ color: green[500] }} />
			) : (
				<RemoveCircleIcon sx={{ color: grey[500] }} />
			);

		const publishState = <Tooltip title={tooltipText}>{icon}</Tooltip>;

		return (
			<div className="header-container">
				{renderTitle(configName, configPublishState)} {publishState}
			</div>
		);
	};

	return (
		<div
			key={modelConfig.configId}
			style={{
				fontSize: '1rem',
			}}>
			<PropertyCard
				columns={2}
				forceMenu
				className="adjusted-menu-position"
				menuItems={menuItems()}
				properties={[
					{
						title: t('Last Run'),
						value: lastRun.length == 0 ? '-' : lastRun,
						ellipsis: true,
					},
					{
						title: t('Status'),
						type: modelConfig.status ? 'status' : 'text',
						value: '',
					},
					{
						title: t('Grouped By'),
						value: 'Material',
						ellipsis: true,
					},
					{
						title: t('Cohorts'),
						value: '5',
						ellipsis: true,
					},
					{
						title: t('System and Asset Type'),
						value: typeCell(modelConfig.assetType),
					},
					{
						title: t('Asset Count'),
						value:
							isAssetCountsReady && assetCounts
								? assetCounts.find((assetCount: AssetCount) => {
										return (
											assetCount.systemType ==
												modelConfig.systemType &&
											assetCount.assetType ==
												modelConfig.assetType
										);
								  })?.count ?? '-'
								: '-',
						// modelConfig.assetCount && modelConfig.assetCount >= 0
						// 	? modelConfig.assetCount
						// 	: '-',
					},
					{
						title: t('Last Updated'),
						value: lastRun.length == 0 ? '-' : lastRun,
						ellipsis: true,
					},
					{
						title: t('Updated By'),
						value: modelConfig.runBy
							? formatRunBy(modelConfig.runBy)
							: '-',
						ellipsis: true,
					},
				]}
				title={renderHeader(
					modelConfig.name ?? t('Default Deterioration Model'),
					modelConfig.publishState,
				)}
			/>
		</div>
	);
};
export default ModelListCard;
