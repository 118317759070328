import {
	getModelLog,
	getModelLogRejected,
	getModelLogResolved,
	getModelConfig,
	getModelConfigResolved,
	setModelSetupDirty,
	saveModelConfigAction,
	createModelConfig,
	setModelConfigNameError,
	setModelConfigIsCreating,
	setModelIsSaving,
	renameModelConfigAction,
	deleteModel,
	setModelIsDeleting,
	duplicateModelConfig,
	changeModelConfigPublishState,
} from '@Actions/model.actions';
import { error, success } from '@innovyze/stylovyze';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import {
	saveModelConfigApi,
	getModelCalcLogApi,
	getModelConfigApi,
	createModelConfigApi,
	deleteModelApi,
	duplicateModelConfigApi,
	changeModelConfigPublishStateApi,
} from '@Services/Model/Model.services';
import { CreateModelConfig, LogReply, ModelConfig } from '@Types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';
import { t } from 'i18next';
import { addRouteNamespace } from '@Utils/actions';
import { changeModelConfigsPage } from '@innovyze/lib_am_common/Actions';

export function* getModelConfigSaga(action: AnyAction) {
	const configId = action.payload;
	const response: AxiosResponse = yield call(getModelConfigApi, configId);

	yield put(getModelConfigResolved(response.data));
}

function* watchGetModelConfig() {
	yield takeLatest(getModelConfig, getModelConfigSaga);
}

interface ConfigData {
	errorMessage: string;
}

export function* createModelConfigSaga(action: AnyAction) {
	try {
		const config: CreateModelConfig = {
			...action.payload.config,
			publishState: 'unpublished',
		};

		const response: AxiosResponse = yield call(
			createModelConfigApi,
			config,
		);
		yield put(setModelConfigNameError(t('')));

		action.payload.history.push(
			addRouteNamespace(`results/${response.data.insertedId}`),
		);
		yield put(setModelConfigIsCreating(false));
		yield put(changeModelConfigsPage(action.payload.page));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setModelConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Failed to create deterioration model: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Failed to create deterioration model: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setModelConfigIsCreating(false));
	}
}

function* duplicateModelConfigSaga(action: AnyAction) {
	try {
		const configId: string = action.payload.configId;
		const response: AxiosResponse = yield call(
			duplicateModelConfigApi,
			configId,
		);
		yield put(setModelConfigIsCreating(false));
		action.payload.onComplete(response.data.configId);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			yield put(
				error(
					t('Failed to create deterioration model: {{info}}', {
						info:
							axiosError.response?.statusText ??
							data?.errorMessage ??
							t('No additional information'),
					}),
				),
			);
		} else {
			yield put(
				error(
					t('Failed to create deterioration model: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setModelConfigIsCreating(false));
	}
}

function* watchCreateModelConfig() {
	yield takeLatest(duplicateModelConfig, duplicateModelConfigSaga);
	yield takeLatest(createModelConfig, createModelConfigSaga);
}

export function* renameModelConfigSaga(action: PayloadAction<ModelConfig>) {
	try {
		yield put(setModelIsSaving(true));
		yield call(saveModelConfigApi, action.payload);
		yield put(success(t('Deterioration model renamed')));
		yield put(getModelConfigResolved(action.payload));
		yield put(setModelIsSaving(false));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setModelConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Deterioration model failed to save: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Deterioration model failed to save: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setModelIsSaving(false));
	}
}

export function* saveModelConfigSaga(action: PayloadAction<ModelConfig>) {
	try {
		yield put(setModelIsSaving(true));

		yield call(saveModelConfigApi, action.payload);
		yield put(success(t('Deterioration model saved')));
		yield put(setModelSetupDirty(false));
		yield put(setModelIsSaving(false));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setModelConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Deterioration model failed to save: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Deterioration model failed to save: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setModelIsSaving(false));
	}
}

function* watchStoreModelConfig() {
	yield takeLatest(renameModelConfigAction, renameModelConfigSaga);
	yield takeLatest(saveModelConfigAction, saveModelConfigSaga);
}

function* getLogSaga(action: AnyAction) {
	try {
		const configId: string = action.payload;
		const response: AxiosResponse<LogReply> = yield retry(
			5,
			1500,
			getModelCalcLogApi,
			configId,
		);
		yield put(getModelLogResolved(response.data));
	} catch (e) {
		yield put(getModelLogRejected());
	}
}
function* watchGetLog() {
	yield takeLatest(getModelLog, getLogSaga);
}

function* deleteModelSaga(action: AnyAction) {
	try {
		yield retry(3, 1500, deleteModelApi, action.payload.configId);
		yield put(success(t('Deterioration model deleted successfully')));
		yield put(setModelIsDeleting(false));
		action.payload.refresh();
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			yield put(
				error(
					t('Deterioration model failed to delete: {{info}}', {
						info:
							axiosError.response?.statusText ??
							t('No additional information'),
					}),
				),
			);
		} else {
			yield put(
				error(
					t('Deterioration model failed to delete: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setModelIsDeleting(false));
	}
}
function* watchDeleteModeleSaga() {
	yield takeLatest(deleteModel, deleteModelSaga);
}

function* changeModelConfigPublishStateSaga(action: AnyAction) {
	const { newPublishState, configId } = action.payload;
	try {
		yield call(changeModelConfigPublishStateApi, newPublishState, configId);
		yield put(
			success(
				t('Deterioration model {{newPublishState}}', {
					newPublishState,
				}),
			),
		);
		action.payload.refresh();
	} catch (err) {
		yield put(
			error(
				t('Failed to {{newPublishState}} deterioration model', {
					newPublishState,
				}),
			),
		);
	}
}
function* watchChangeModelConfigPublishStateSaga() {
	yield takeLatest(
		changeModelConfigPublishState,
		changeModelConfigPublishStateSaga,
	);
}

const sagaArray = [
	watchCreateModelConfig(),
	watchGetModelConfig(),
	watchStoreModelConfig(),
	watchGetLog(),
	watchDeleteModeleSaga(),
	watchChangeModelConfigPublishStateSaga(),
];

export default sagaArray;
