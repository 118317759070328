import React, { useEffect } from 'react';
import { getModelLog, setModelTab } from '@Actions';
import { useDispatch } from 'react-redux';
import { Button, InfoCard, useCompanyDateTime } from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import { selectModelStore } from '@Selectors';
import { formatDateTimeWithFormatDate } from '@innovyze/lib_am_common/Utils';
import { useGlobalization } from '@Translations/useGlobalization';
import { NoResultsModel } from '@Components/Utils/NoResultsModel.component';

interface Props {
	configId: string;
}

export const RiskLogs = ({ configId }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { formatDate } = useCompanyDateTime();
	useEffect(() => {
		dispatch(setModelTab('logs'));
	}, []);

	const modelStore = selectModelStore();

	const _formatDateTime = (time: string) =>
		formatDateTimeWithFormatDate(time, formatDate);

	const logTitle = () => {
		if (!modelStore.isLoading) {
			if (modelStore.log && modelStore.log.calcStatus) {
				if (modelStore.log.calcStatus === 'risk-calc-complete') {
					return `${t(
						'Last Run Completed, Start',
					)}: ${_formatDateTime(
						modelStore.log.startTime,
					)}, End: ${_formatDateTime(modelStore.log.endTime)}`;
				} else if (modelStore.log.calcStatus === 'risk-calc-failed') {
					return `${t('Last Run Failed, Start')}: ${_formatDateTime(
						modelStore.log.startTime,
					)}, End: ${_formatDateTime(modelStore.log.endTime)}`;
				} else if (
					modelStore.log.calcStatus === 'risk-calc-cancelled'
				) {
					return `${t(
						'Last Run Cancelled, Start',
					)}: ${_formatDateTime(
						modelStore.log.startTime,
					)}, End: ${_formatDateTime(modelStore.log.endTime)}`;
				} else {
					return t('Calculation Run Currently in Progress');
				}
			}
			return t('No Log Available');
		}
		return t('Fetching Log');
	};

	const logBodyErrors = () => {
		const shouldShowErrors =
			!modelStore.isLoading &&
			modelStore.log?.calcStatus === 'risk-calc-failed' &&
			modelStore.log.calcErrors.length > 0;
		if (shouldShowErrors) {
			const width = modelStore.log?.log.length !== 0 ? 6 : 12;

			// Filter out duplicates. We can get duplicates since the errors are reported
			// for every risk calculation lambda
			const errors = Array.from(
				new Set(modelStore.log?.calcErrors ?? []),
			);

			return (
				<Grid item xs={width}>
					<InfoCard title={'Errors'}>
						<ul>
							{errors.map((name, index) => (
								<li key={index}> {name} </li>
							))}
						</ul>
					</InfoCard>
				</Grid>
			);
		}
	};

	const logBodyLog = () => {
		if (
			!modelStore.isLoading &&
			modelStore.log &&
			modelStore.log.log &&
			modelStore.log.log.length != 0
		) {
			const width = modelStore.log?.calcErrors?.length != 0 ? 6 : 12;

			return (
				<Grid item xs={width}>
					<InfoCard title={'Log'}>
						<ul>
							{modelStore.log?.log?.map((name, index) => (
								<li key={index}> {name} </li>
							))}
						</ul>
					</InfoCard>
				</Grid>
			);
		}
	};

	const refreshLog = () => {
		if (!modelStore.isLoading) dispatch(getModelLog(configId));
	};

	return (
		<InfoCard
			title={logTitle()}
			className={modelStore.isLoading ? 'wait' : ''}
			titleArea={
				<Grid
					container
					style={{ width: 'fit-content', justifyContent: 'flex-end' }}
					spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								refreshLog();
							}}>
							{t('Refresh')}
						</Button>
					</Grid>
				</Grid>
			}>
			<Grid container spacing={3} direction={'row'}>
				{!modelStore.isLoading &&
				(!modelStore.log || !modelStore.log.calcStatus) ? (
					<NoResultsModel />
				) : (
					<>
						{modelStore.log ? logBodyErrors() : ''}
						{modelStore.log ? logBodyLog() : ''}
					</>
				)}
			</Grid>
		</InfoCard>
	);
};
