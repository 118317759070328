import {
	Button,
	InfoCard,
	useUnits,
	UnitField,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	useIsFeatureEnabled,
	SecondaryNav,
	CancellableEvent,
	WarningDialog,
	error,
} from '@innovyze/stylovyze';
import {
	CachedField,
	Category,
	Component,
	ComponentCount,
	ComponentField,
	ComponentMetadata,
	ComponentType,
	MultiParamCell,
	MultiScoreItem,
	ScoreDataGridRow,
	UnitMetadata,
	getTypeDisplay,
} from '@Types';
import { FullPage, FullPageWrapper } from '@Utils/styles';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
	clearComponentMetadata,
	setDataGridState,
	changeSpatialProximity,
	setModelScoreDataGrid,
	setModelUnitScores,
	setModelEditScores,
	setModelScores,
	getComponentProximityAttrMetadata,
	getComponentAssetMetadata,
	getRiskCountResolved,
	setRiskCountIsLoading,
	getRiskCountProximity,
	getRiskCountProximityAttribute,
	getRiskCountAttribute,
	renameRiskCount,
} from '@Actions';
import {
	selectAssetFieldsStore,
	useComponentMetadataStore,
	useSelectIsCountLoading,
	selectModelStore,
	selectRiskCount,
	selectRiskScoreDataGrid,
	selectRiskStore,
} from '@Selectors';
import { useGlobalization } from '@Translations/useGlobalization';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Autocomplete,
	createFilterOptions,
	Tooltip,
	Chip,
	Checkbox,
	Typography,
	FormControlLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useStyles } from './EditModel.styles';
import { MapInsertSpatial } from '@innovyze/lib_am_common/Components';
import { MapStatusType } from '@innovyze/lib_am_common/Types';
import ScoreDataGrid, {
	updateModelCounts,
} from './ScoreDataGrid/ScoreDataGrid';
import { Stack } from '@mui/system';
import ParameterDetails from './ParameterDetails/ParameterDetails';

import { getObservationFields } from '@Utils/observationFields';
import { TFunc } from '@Translations/types';
import { SpatialLayer } from '@innovyze/lib_am_common';
import { useWebSockets } from '@innovyze/lib_am_common/Utils';
import { WebSocketData } from '@Types/webSocket.types';

interface Props {
	isNew: boolean;
	assetType: string;
	countKey: string;
	component: Component;
	parameterNumber: number;
	onChange?: (
		changed: boolean,
		clearMatrix: boolean,
		nextTab: boolean,
	) => void;
}

export const EditParameter = ({
	isNew,
	assetType,
	countKey,
	component,
	parameterNumber,
	onChange,
}: Props) => {
	const isWebSocketEnabled = useIsFeatureEnabled(
		'info-360-asset-risk-web-socket',
	);

	const { t } = useGlobalization();
	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		getSystemValueNoIntlFormat,
		getStandardisedValue,
		getSystemUnit,
		system,
	} = useUnits();

	const changedRangesKey = `ChangedRanges${parameterNumber}`;
	const modelStore = selectModelStore();
	const assetStore = selectAssetFieldsStore();
	const riskStore = selectRiskStore();

	const [unusedParam, setUnusedPram] = useState<boolean>(
		component.unused ?? false,
	);

	const [componentName, setComponentName] = useState<string>(
		component ? component.name : '',
	);
	const [field, setField] = useState<string>(
		component && component.field ? component.field : '',
	);
	const [fieldAlias, setFieldAlias] = useState<string>(
		component && component.fieldAlias ? component.fieldAlias : '',
	);
	const [uploadId, setUploadId] = useState<string>(component?.uploadId ?? '');

	const [simulationUploadId, setSimulationUploadId] = useState<string>(
		(component && component.simulationUploadId) ?? '',
	);
	const [type, setType] = useState<ComponentType>(
		component && component.type
			? (component.type as ComponentType)
			: ComponentType.Attribute,
	);

	const [dataSource, setDataSource] = useState<string>(
		component && component.layer ? component.layer : '',
	);
	const [distance, setDistance] = useState<number>(
		component && component.maxDistance ? component.maxDistance : 0,
	);
	const [metadata, setMetadata] = useState<ComponentMetadata[] | undefined>(
		component && component.metadata ? component.metadata : undefined,
	);
	const [metadataType, setMetadataType] = useState<string>(
		component && component.metadataType ? component.metadataType : '',
	);
	const [nullScore, setNullScore] = useState<number>(
		component && component.nullScore ? component.nullScore : 0,
	);
	const [unitMetadata, setUnitMetadata] = useState<UnitMetadata | undefined>(
		component && component.unitMetadata
			? component.unitMetadata
			: undefined,
	);
	const [unitMetadataName, setUnitMetadataName] = useState<string>(
		component && component.unitMetadataName
			? component.unitMetadataName
			: '',
	);

	const [componentChanged, setComponentChanged] = useState<boolean>(false);

	const [validName, setValidName] = useState<string>('');
	const [validConfig, setValidConfig] = useState<boolean>(false);

	const [showScoreTable, setShowScoreTable] = useState<boolean>(false);

	const [isUnique, setIsUnique] = React.useState(true);
	const [rangeClasses, setRangeClasses] = React.useState<number>(
		component && component.metadata?.length
			? component.metadata.length - 3
			: 5,
	);
	const [hasMap, setHasMap] = React.useState(
		component &&
			(component.type === ComponentType.SpatialProximity ||
				component.type === ComponentType.SpatialAttribute),
	);
	const [isUniqueGrouped, setIsUniqueGrouped] = React.useState(false);

	const [rangeChanged, setRangeChanged] = React.useState(false);
	const [fetchingMetadata, setFetchingMetadata] = useState<boolean>(false);
	const [disableFieldSelect, setDisableFieldSelect] =
		useState<boolean>(false);
	const [selectedCachedField, setSelectedCachedField] =
		useState<CachedField | null>(null);

	const scoreRows = selectRiskScoreDataGrid();
	const componentMetadataStore = useComponentMetadataStore();
	const metadataStore = useMemo(() => {
		return componentMetadataStore;
	}, [type, selectedCachedField, componentMetadataStore]);

	const riskCounts = selectRiskCount(
		component.name.length !== 0 && rangeChanged
			? changedRangesKey
			: countKey,
	);
	const isModelCountLoading = useSelectIsCountLoading(
		component.name.length !== 0 && rangeChanged
			? changedRangesKey
			: countKey,
	);
	const [requestedCountMetadata, setRequestedCountMetadata] =
		React.useState<ComponentMetadata[]>();

	const webSocket = useWebSockets();
	const riskCalCountCallBack = async (data: WebSocketData) => {
		console.log('riskCalcCount', data);

		const key = data.payload.key;
		const response = data.payload.payload;
		const requestId = data.requestId;
		const connectionId = data.connectionId;
		console.log('riskCalcCount', connectionId, requestId);

		console.log('dispatch', {
			key: key,
			values: response,
		});
		dispatch(
			getRiskCountResolved({
				key: key,
				values: response,
			}),
		);
		dispatch(
			setRiskCountIsLoading({
				key: key,
				loading: false,
			}),
		);
	};
	const riskCalCountErrorCallBack = async (data: WebSocketData) => {
		dispatch(
			setRiskCountIsLoading({
				key: data.requestBody.key,
				loading: false,
			}),
		);
	};
	webSocket.setupRoutes(
		'riskCalcCount',
		riskCalCountCallBack,
		riskCalCountErrorCallBack,
	);

	const clearFields = (changeType: boolean) => {
		if (!changeType) {
			setComponentName('');
			setValidName(t('Enter a valid name'));
			setType(ComponentType.Attribute);
			if (onChange) onChange(false, true, false);
		}
		dispatch(
			setRiskCountIsLoading({
				key: countKey,
				loading: false,
			}),
		);
		setField('');
		setFieldAlias('');
		setSelectedCachedField(null);
		setUploadId('');
		setSimulationUploadId('');

		setDistance(0);
		setDisableFieldSelect(false);
		dispatch(
			setDataGridState({
				key: countKey,
				initialState: undefined,
			}),
		);
	};

	const menuItemsAsset = useMemo(() => {
		return riskStore?.componentFields.map(
			(item: ComponentField, index: number) => (
				<MenuItem
					key={index}
					value={item.cachedField}
					disabled={!item.isCached}>
					{item.userFriendlyName}
				</MenuItem>
			),
		);
	}, [riskStore]);

	const menuItemsType = useMemo(() => {
		const fieldTypes = [
			{
				value: ComponentType.Attribute,
				label: getTypeDisplay(ComponentType.Attribute, t),
			},
			{
				value: ComponentType.SpatialProximity,
				label: getTypeDisplay(ComponentType.SpatialProximity, t),
			},
			{
				value: ComponentType.SpatialAttribute,
				label: getTypeDisplay(ComponentType.SpatialAttribute, t),
			},
		];

		return fieldTypes.map(
			(item: { value: string; label: string }, index: number) => {
				return (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				);
			},
		);
	}, [parameterNumber]);

	const menuItemsLayerList = useMemo(() => {
		if (
			assetStore.spatialLayerList &&
			assetStore.spatialLayerList.length > 0
		) {
			const layerList = assetStore.spatialLayerList.map(
				layer => layer.LAYER_NAME,
			);
			const uniqLayer = layerList
				.filter((item, index) => layerList.indexOf(item) === index)
				.sort((a, b) => {
					return a.localeCompare(b);
				});
			return uniqLayer.map((layer, index: number) => (
				<MenuItem key={index} value={layer}>
					{layer}
				</MenuItem>
			));
		} else {
			return [];
		}
	}, [type, assetStore, dataSource]);

	const menuItemsSpatialAttrList = useMemo(() => {
		if (
			assetStore.spatialProximityAttr &&
			assetStore.spatialProximityAttr.length > 0
		) {
			const layerList = assetStore.spatialProximityAttr.map(
				layer => layer.layerName,
			);
			const uniqLayer = layerList
				.filter((item, index) => layerList.indexOf(item) === index)
				.sort((a, b) => {
					return a.localeCompare(b);
				});
			return uniqLayer.map((layer, index: number) => (
				<MenuItem key={index} value={layer}>
					{layer}
				</MenuItem>
			));
		} else {
			return [];
		}
	}, [type, assetStore, dataSource]);

	const menuItemsSpatialAttrFieldList = useMemo(() => {
		if (
			assetStore.spatialProximityAttr &&
			assetStore.spatialProximityAttr.length > 0 &&
			dataSource != ''
		) {
			const fieldList = assetStore.spatialProximityAttr
				.filter(layer => layer.layerName == dataSource)
				.map(layer => layer.cachedField)
				.sort((a, b) => {
					return a.localeCompare(b);
				});
			return fieldList.map((field2, index: number) => (
				<MenuItem key={index} value={field2}>
					{field2}
				</MenuItem>
			));
		} else {
			return [];
		}
	}, [type, assetStore, dataSource]);

	const validateName = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.value.toUpperCase();
		let error = '';
		if (name !== component.name.toUpperCase()) {
			if (name.trim().length == 0) {
				error = t('Enter a valid name');
			}
		}

		setValidName(error);
		setComponentName(error.length == 0 ? event.target.value : '');
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
	};

	const handleTypeSelectChange = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		const type = target.value as ComponentType;

		setType(type);
		setValidConfig(false);
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
		clearFields(true);

		component.paramComponents = undefined;
	};

	const handleDataSourceSelectChange = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		setDataSource(target.value);
		setField('');
		setFieldAlias('');
		setDistance(0);
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
		const sl = assetStore.spatialLayerList.find(
			s => s.LAYER_NAME === target.value,
		);
		const slm: SpatialLayer = {
			_ID: sl?._ID ?? '',
			TENANT_ID: sl?.TENANT_ID ?? '',
			LAYER_NAME: sl?.LAYER_NAME ?? '',
			EXTENT: sl?.EXTENT ?? undefined,
			GEOMETRY_TYPE: sl?.GEOMETRY_TYPE ?? undefined,
		};
		dispatch(changeSpatialProximity(slm));
	};

	const handleFieldSelectChangeSpatialAttr = (
		event: SelectChangeEvent<string>,
	) => {
		if (assetStore) {
			const target = event?.target as HTMLInputElement;
			const result = assetStore.spatialProximityAttr.find(
				({ cachedField }) => cachedField === target.value,
			);
			if (result) {
				setField(result.cachedField);
				setFieldAlias(result.userFriendlyName);
				setDistance(0);
				setComponentChanged(true);
				if (onChange) onChange(true, false, false);

				// This is a workaround to fix the MUI issue that disabling a select with focus does not correctly keep the focus when un-disabled
				setTimeout(() => {
					const field = document.getElementById('fieldSelectSA');
					if (field) field.blur();
					setDisableFieldSelect(true);
				}, 0);
			}
		}
	};

	const handleFieldSelectChangeAsset = (event: SelectChangeEvent<string>) => {
		if (riskStore) {
			const target = event?.target as HTMLInputElement;
			const result = riskStore.componentFields.find(
				({ cachedField }) => cachedField === target.value,
			);
			if (result) {
				setField(result.cachedField);
				setFieldAlias(result.userFriendlyName);
				setComponentChanged(true);
				if (onChange) onChange(true, false, false);
				// This is a workaround to fix the MUI issue that disabling a select with focus does not correctly keep the focus when un-disabled
				setTimeout(() => {
					const field = document.getElementById('fieldSelectA');
					if (field) field.blur();
					setDisableFieldSelect(true);
				}, 0);
			}
		}
	};

	const handleDistanceChange = (
		event: ChangeEvent<
			HTMLInputElementExtended | HTMLTextAreaElementExtended
		>,
	) => {
		const distance = parseFloat(event.target.value);
		setDistance(distance);
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
	};

	const recalculateCount = (
		scoreRows: ScoreDataGridRow[],
		isUnique: boolean,
		changedRange: boolean,
		metadataType: string,
	) => {
		if (scoreRows && !isModelCountLoading) {
			// let requestMetadata = component.metadata ?? [];
			let requestMetadata = metadata ?? [];

			if (scoreRows && scoreRows.length !== 0) {
				if (isUnique) {
					requestMetadata = scoreRows.map(row => ({
						key: row.value,
						value: row.score,
						ix: row.id,
					}));
				} else {
					requestMetadata = scoreRows
						.filter(row => row.value !== '--')
						.map(row => ({
							key: row.value,
							value: row.score,
							ix: row.id,
						}));
				}
			}

			setRequestedCountMetadata(requestMetadata);

			// Use the name to check if the configuration has been saved and if so then we can use the changedRangesKey if changed
			const key =
				component.name.length !== 0 && changedRange
					? changedRangesKey
					: countKey;
			if (isWebSocketEnabled) {
				dispatch(
					getRiskCountResolved({
						key: key,
						values: undefined,
					}),
				);
				dispatch(
					setRiskCountIsLoading({
						key: key,
						loading: true,
					}),
				);
			}
			switch (type) {
				case ComponentType.SpatialProximity:
					if (isWebSocketEnabled) {
						webSocket.sendMessage(
							JSON.stringify({
								path: 'risk',
								action: 'riskCalcCount',
								key: key,
								metadata: requestMetadata,
								metadataType: metadataType ?? 'unique',
								assetType: assetType,
								layerName: dataSource,
								fieldType: 'proximity',
							}),
						);
					} else {
						dispatch(
							getRiskCountProximity({
								key: key,
								metadata: requestMetadata,
								metadataType: metadataType ?? 'unique',
								assetType: assetType,
								layerName: dataSource,
							}),
						);
					}
					break;
				case ComponentType.SpatialAttribute:
					if (isWebSocketEnabled) {
						webSocket.sendMessage(
							JSON.stringify({
								path: 'risk',
								action: 'riskCalcCount',
								key: key,
								metadata: requestMetadata,
								metadataType: metadataType ?? 'unique',
								assetType: assetType,
								layerName: dataSource,
								fieldName: field ?? '',
								fieldType: 'proximityAttribute',
								valueType: '', // isUnique ? 'string' : 'number', // TODO - Need to work out how to get this/what it actually is
								distance: distance,
							}),
						);
					} else {
						dispatch(
							getRiskCountProximityAttribute({
								key: key,
								metadata: requestMetadata,
								metadataType: metadataType ?? 'unique',
								assetType: assetType,
								layerName: dataSource,
								fieldName: field ?? '',
								valueType: '', // isUnique ? 'string' : 'number', // TODO - Need to work out how to get this/what it actually is
								distance: distance,
							}),
						);
					}
					break;
				default:
					{
						if (isWebSocketEnabled) {
							webSocket.sendMessage(
								JSON.stringify({
									path: 'risk',
									action: 'riskCalcCount',
									key: key,
									metadata: requestMetadata,
									metadataType: metadataType ?? 'unique',
									assetType: assetType,
									fieldName: field ?? '',
									fieldType: 'attribute',
								}),
							);
						} else {
							dispatch(
								getRiskCountAttribute({
									key: key,
									metadata: requestMetadata,
									metadataType: metadataType ?? 'unique',
									assetType: assetType,
									fieldName: field ?? '',
								}),
							);
						}
					}
					break;
			}
		}
	};

	const onRecalculateCount = () => {
		recalculateCount(scoreRows, isUnique, rangeChanged, metadataType);
	};

	const onUnqueGroups = (groups: boolean) => {
		setIsUniqueGrouped(groups);
	};

	const createScoreRowsFromComponent = (
		changeComponent: boolean,
		changedRange: boolean,
		metadataType: string,
		nullScore: number,
		metadata?: ComponentMetadata[],
		unitMetadata?: UnitMetadata,
	) => {
		if (metadata) {
			let newScoreRows: ScoreDataGridRow[] = [];
			let isUnique = false;
			let fetchCounts =
				riskCounts === undefined ||
				riskCounts.length === 0 ||
				changeComponent ||
				component.name.length === 0;

			if (metadataType === 'unique') {
				isUnique = true;

				const sorted = [...metadata];
				// Sort forcing the '--' (null entry) to the bottom
				sorted.sort((a, b) => {
					if (a.key === '--') return 1;
					if (b.key === '--') return -1;
					return a.key.toString().localeCompare(b.key.toString());
				});

				newScoreRows = sorted.map((row, index) => ({
					id: index,
					score: row.value,
					value: row.key,
				}));

				if (riskCounts && !fetchCounts) {
					// We cant verify spatial attribute data as the scores could not match the request due to items not being in range
					// Or if we have missmatched lenghts, again because the server does not return some empty rows
					if (
						type !== ComponentType.SpatialAttribute &&
						riskCounts.length === newScoreRows.length
					) {
						// But everything else  they actually match just in case
						for (let i = 0; i < newScoreRows.length - 1; i++) {
							if (
								!riskCounts.find(
									({ key }) =>
										(key === undefined || key === null
											? '--'
											: key.toString()) ===
										newScoreRows[i].value.toString(),
								)
							) {
								fetchCounts = true;
								break;
							}
						}
					}

					if (!fetchCounts) {
						newScoreRows = updateModelCounts(
							newScoreRows,
							riskCounts,
							system == 'Metric',
							true,
						);
					}
				}

				dispatch(setModelScoreDataGrid(newScoreRows));
				dispatch(setModelUnitScores(undefined));
				dispatch(setModelEditScores([]));
				dispatch(setModelScores([]));
			} else if (metadata.length !== 0) {
				newScoreRows = metadata.map((row, index) => ({
					id: index,
					score: row.value,
					value: row.key,
				}));

				if (newScoreRows[newScoreRows.length - 1].value !== '--') {
					// Add the null score
					newScoreRows.push({
						id: newScoreRows.length,
						score: nullScore ?? 0,
						value: '--',
					});
				}

				if (riskCounts && !fetchCounts) {
					// Veify they actually match just in case
					for (let i = 0; i < newScoreRows.length - 1; i++) {
						if (
							!riskCounts.find(
								({ key }) =>
									(key === undefined || key === null
										? '--'
										: key.toString()) ===
									newScoreRows[i].value,
							)
						) {
							fetchCounts = true;
							break;
						}
					}

					if (!fetchCounts) {
						newScoreRows = updateModelCounts(
							newScoreRows,
							riskCounts,
							system == 'Metric',
							true,
						);
					}
				}

				dispatch(setModelScoreDataGrid(newScoreRows));

				// Extract the scores, ignoring the null score if needed
				const offset = 1;
				let scores: number[] = [newScoreRows.length - offset];
				for (let i = 0; i < newScoreRows.length - offset; i++) {
					const values = newScoreRows[i].value.split(' ');
					if (values.length == 2) scores[i] = parseFloat(values[1]);
					else scores[i] = parseFloat(values[2]);
				}

				// If we have a unit we may need to do some covnersion stuff later
				if (
					system !== 'Metric' &&
					unitMetadata &&
					unitMetadata.unit &&
					unitMetadata.unit.length !== 0
				) {
					const precision = unitMetadata.precision ?? 0;
					// Create an array with the values in the system display value what ever that might be
					const unitScores = scores.map(score => {
						return parseFloat(
							parseFloat(
								getSystemValueNoIntlFormat(
									`${score} ${unitMetadata.unit}`,
								),
							).toFixed(precision),
						);
					});
					// Now convert the rounded conversion back to metric.... I know !!!
					const internalUnit = getSystemUnit(unitMetadata.unit);
					let adjustment = 1;
					// mm -> in -> cm
					if (unitMetadata.unit === 'mm') adjustment = 10;

					scores = unitScores.map(score => {
						return parseFloat(
							(
								parseFloat(
									getStandardisedValue(
										`${score} ${internalUnit}`,
									),
								) * adjustment
							).toFixed(precision),
						);
					});
					dispatch(setModelUnitScores(unitScores));
				} else {
					dispatch(setModelUnitScores(undefined));
				}

				const stringScores = scores.map(score => {
					return score?.toString();
				});
				dispatch(setModelEditScores(stringScores));
				dispatch(setModelScores(scores));
			}

			// Fetch the counts and lengths
			if (fetchCounts) {
				recalculateCount(
					newScoreRows,
					isUnique,
					changedRange,
					metadataType,
				);
			}
			setIsUnique(isUnique);
			setShowScoreTable(true);
		}
	};

	const updateComponent = () => {
		component.type = type;
		component.field = field;
		component.fieldAlias = fieldAlias;
		component.uploadId = uploadId;
		component.simulationUploadId = simulationUploadId;
		component.layer = dataSource;
		component.maxDistance = distance;
		component.metadata = scoreRows.map(row => ({
			key: row.value,
			value: row.score,
			ix: row.id,
		}));
		component.metadataType = metadataType;

		// If we are saving components for the first time then move to the next tab
		const changeTab = component.name === '';

		if (component.name != componentName) {
			// if (parameterNumber === undefined) {
			// 	dispatch(
			// 		renameModelCount({
			// 			existingKey: countKey,
			// 			newKey: countKey.replace(
			// 				`-${component.name}`,
			// 				`-${componentName}`,
			// 			),
			// 		}),
			// 	);
			// }
			component.name = componentName;
		}

		component.unitMetadata = unitMetadata;
		component.unitMetadataName = unitMetadataName;

		const nullScore = scoreRows.find(row => row.value === '--');
		component.nullScore = nullScore ? nullScore.score : 0;

		dispatch(
			renameRiskCount({
				existingKey: changedRangesKey,
				newKey: countKey,
			}),
		);

		setComponentChanged(false);
		if (onChange) onChange(false, true, changeTab);
	};

	useEffect(() => {
		let valid = false;

		switch (type) {
			case ComponentType.Attribute:
				valid = field.length !== 0;
				break;
			case ComponentType.SpatialProximity:
				valid = dataSource.length !== 0;
				break;
			case ComponentType.SpatialAttribute:
				valid = field.length !== 0 && dataSource.length !== 0;
				break;
		}

		setValidConfig(valid);
		setShowScoreTable(false);

		if (valid) {
			if (
				component.unitMetadataName !== `${field}-${type}-${dataSource}`
			) {
				component.unitMetadata = undefined;
				component.unitMetadataName = undefined;
				setRangeClasses(5);
				setHasMap(
					type === ComponentType.SpatialProximity ||
						type === ComponentType.SpatialAttribute,
				);
				fetchMetadata(false, true);
			}
		}
	}, [field, type, dataSource]);

	const fetchMetadata = (disableField: boolean, multiParam: boolean) => {
		const metaDataName = `${field}-${type}-${dataSource}`;

		if ((field || dataSource) && unitMetadataName !== metaDataName) {
			if (disableField) setDisableFieldSelect(true);

			switch (type) {
				case ComponentType.SpatialAttribute: {
					setFetchingMetadata(true);
					dispatch(
						getComponentProximityAttrMetadata({
							assetType: assetType,
							field: field ?? '',
							fieldAlias: field ?? '',
							layerName: dataSource,
							multiParam,
						}),
					);
					break;
				}
				case ComponentType.SpatialProximity: {
					setFetchingMetadata(true);
					dispatch(
						getComponentAssetMetadata({
							assetType: assetType,
							field: 'proximity',
							fieldAlias: dataSource ?? '',
							multiParam,
						}),
					);
					break;
				}
				default: {
					setFetchingMetadata(true);
					dispatch(
						getComponentAssetMetadata({
							assetType: assetType,
							field: field ?? '',
							fieldAlias: fieldAlias ?? '',
							multiParam,
						}),
					);
					break;
				}
			}
		}
	};

	const createDefaultRangeValues = (
		newRangeClasses: number,
		unitMetadata?: UnitMetadata,
	): ComponentMetadata[] | undefined => {
		let metadata = undefined;
		if (unitMetadata) {
			if (unitMetadata.range) {
				metadata = new Array(newRangeClasses + 2);
				const defaultScore: number[] = [metadata.length];

				let rangeMin = unitMetadata.range.min;
				if (rangeMin == null || rangeMin == undefined) {
					rangeMin = 0;
				}

				let rangeMax = unitMetadata.range.max;
				if (rangeMax == null || rangeMax == undefined) {
					rangeMax = 0;
				}

				// Do we have to care about units at this point?
				if (unitMetadata.unit && system !== 'Metric') {
					const precision: number = unitMetadata.precision ?? 0;
					// To make the ranges work we need to do a set in native unit and one in display units
					// Otherwise the initial display can have too many decimal places until the user edits a value
					const internalUnit = getSystemUnit(unitMetadata.unit);
					const adjustment = unitMetadata.unit === 'mm' ? 10 : 1;
					const unitMin = parseFloat(
						getSystemValueNoIntlFormat(
							`${rangeMin} ${unitMetadata.unit}`,
						),
					);
					const unitMax = parseFloat(
						getSystemValueNoIntlFormat(
							`${rangeMax} ${unitMetadata.unit}`,
						),
					);

					const unitRange = unitMax - unitMin;

					let interval = unitRange / newRangeClasses;
					// If the interval is 0 then make it the minimum increment
					if (interval === 0) {
						interval = +(1 / Math.pow(10, precision)).toFixed(
							precision,
						);
					} else {
						interval = +interval.toFixed(precision);
					}

					let first = unitMin;
					let nativeFirst = rangeMin;
					let pos = 0;

					defaultScore[pos] = nativeFirst;
					metadata[pos] = {
						key: ` <= ${nativeFirst}`,
						value: 0,
						ix: pos++,
					};

					for (let i = 0; i < newRangeClasses - 1; i++) {
						const next = +(first + interval).toFixed(precision);
						const nativeNext =
							parseFloat(
								getStandardisedValue(`${next} ${internalUnit}`),
							) * adjustment;

						metadata[pos] = {
							key: `${nativeFirst} <= ${nativeNext}`,
							value: 0,
							ix: pos++,
						};
						defaultScore[pos] = nativeNext;
						nativeFirst = nativeNext;
						first = next;
					}

					// Ignore the range max as it could be less than we need to make a unique range
					const max = first + interval;
					const nativeMax =
						parseFloat(
							getStandardisedValue(`${max} ${internalUnit}`),
						) * adjustment;

					defaultScore[pos] = nativeMax;
					metadata[pos] = {
						key: `${nativeFirst} <= ${nativeMax}`,
						value: 0,
						ix: pos++,
					};

					defaultScore[pos] = nativeMax;
					metadata[pos] = {
						key: `> ${nativeMax}`,
						value: 0,
						ix: pos++,
					};
				} else {
					const precision: number = unitMetadata.precision ?? 0;
					const range = rangeMax - rangeMin;
					let interval = range / newRangeClasses;
					// If the interval is 0 then make it the minimum increment
					if (interval === 0) {
						interval = +(1 / Math.pow(10, precision)).toFixed(
							precision,
						);
					} else {
						interval = +interval.toFixed(precision);
					}

					let first = rangeMin;
					let pos = 0;

					defaultScore[pos] = first;
					metadata[pos] = {
						key: ` <= ${first}`,
						value: 0,
						ix: pos++,
					};

					for (let i = 0; i < newRangeClasses - 1; i++) {
						const next = +(first + interval).toFixed(precision);
						metadata[pos] = {
							key: `${first} <= ${next}`,
							value: 0,
							ix: pos++,
						};
						defaultScore[pos] = next;
						first = next;
					}

					// Ignore the range max as it could be less than we need to make a unique range
					const max = first + interval;
					defaultScore[pos] = max;
					metadata[pos] = {
						key: `${first} <= ${max}`,
						value: 0,
						ix: pos++,
					};

					defaultScore[pos] = max;
					metadata[pos] = {
						key: `> ${max}`,
						value: 0,
						ix: pos++,
					};
				}

				setMetadata(metadata);
				setMetadataType('range');
				setNullScore(0);

				const stringScores = defaultScore.map(score => {
					return score?.toString();
				});
				dispatch(setModelEditScores(stringScores));
				dispatch(setModelScores(defaultScore));
				setRangeClasses(newRangeClasses);
			}
		}
		return metadata;
	};

	const typeInputs = () => {
		if (isNew) {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel>{t('Type')}</InputLabel>
					<Select
						data-cy="typeField"
						name="type"
						onChange={handleTypeSelectChange}
						disabled={
							isModelCountLoading ||
							fetchingMetadata ||
							unusedParam
						}
						defaultValue={type}
						label={t('Type')}>
						{menuItemsType}
					</Select>
				</FormControl>
			);
		} else {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						data-cy="typeField"
						name="type"
						label={t('Type')}
						variant="outlined"
						defaultValue={getTypeDisplay(type, t)}
						disabled={true}
					/>
				</FormControl>
			);
		}
	};

	const attrInputs = () => {
		if (isNew) {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel>{t('Field')}</InputLabel>
					<Select
						id={'fieldSelectA'}
						key={`f${dataSource}${Date.now}`}
						data-cy="fieldField"
						name="field"
						defaultValue={field}
						onChange={handleFieldSelectChangeAsset}
						disabled={
							isModelCountLoading ||
							disableFieldSelect ||
							unusedParam
						}
						label={t('Field')}>
						{menuItemsAsset}
					</Select>
				</FormControl>
			);
		} else {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						data-cy="fieldField"
						name="field"
						label={t('Field')}
						variant="outlined"
						defaultValue={fieldAlias}
						disabled={true}
					/>
				</FormControl>
			);
		}
	};

	const dataSourceSelect = useMemo(() => {
		switch (type) {
			case ComponentType.SpatialProximity:
				return menuItemsLayerList;
			case ComponentType.SpatialAttribute:
				return menuItemsSpatialAttrList;
		}
	}, [type]);

	const dataSourceInputs = () => {
		if (isNew) {
			return (
				<FormControl
					variant="outlined"
					margin="normal"
					fullWidth
					disabled={
						isModelCountLoading || fetchingMetadata || unusedParam
					}>
					<InputLabel>{t('Data Source')}</InputLabel>
					<Select
						key={`ds${type}${Date.now}`}
						data-cy="dataSourceField"
						name="dataSource"
						defaultValue={dataSource}
						onChange={handleDataSourceSelectChange}
						label={t('Data Source')}>
						{dataSourceSelect}
					</Select>
				</FormControl>
			);
		} else {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						data-cy="dataSourceField"
						name="dataSource"
						label={t('Data Source')}
						variant="outlined"
						defaultValue={dataSource}
						disabled={true}
					/>
				</FormControl>
			);
		}
	};

	const spatialAttriFieldInputs = () => {
		if (isNew) {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel>{t('Field')}</InputLabel>
					<Select
						id={'fieldSelectSA'}
						key={`f${dataSource}${Date.now}`}
						data-cy="fieldField"
						name="saField"
						defaultValue={field}
						onChange={handleFieldSelectChangeSpatialAttr}
						disabled={
							isModelCountLoading ||
							disableFieldSelect ||
							unusedParam
						}
						label={t('Field')}>
						{menuItemsSpatialAttrFieldList}
					</Select>
				</FormControl>
			);
		} else {
			return (
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						data-cy="fieldField"
						name="safield"
						label={t('Field')}
						variant="outlined"
						defaultValue={field}
						disabled={true}
					/>
				</FormControl>
			);
		}
	};

	const distanceInputs = () => {
		// Distance is always editable
		return (
			<FormControl variant="outlined" margin="normal" fullWidth>
				<UnitField
					key={`dis${field}`}
					fullWidth
					data-cy="distanceField"
					name="distance"
					unit="m"
					step="1"
					min={0}
					defaultValue={distance.toString()}
					label={t('Distance')}
					variant="outlined"
					onChange={handleDistanceChange}
					disabled={
						isModelCountLoading ||
						fetchingMetadata ||
						disableFieldSelect ||
						unusedParam
					}
				/>
			</FormControl>
		);
	};

	const handleUseComponentChange = (event: ChangeEvent<HTMLInputElement>) => {
		setUnusedPram(!event.target.checked);
	};

	const useSetup = () => {
		if (parameterNumber === 2) {
			return (
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!unusedParam}
								onChange={handleUseComponentChange}
							/>
						}
						label={t('Enable second parameter')}
					/>
				</Grid>
			);
		} else {
			return <></>;
		}
	};

	const componentSetup = () => {
		let setup = <Grid item xs={12}></Grid>;
		const nextButton = (
			<Grid
				item
				xs={2}
				paddingRight={'20px'}
				display="flex"
				justifyContent="flex-end">
				<div>
					<Button
						style={{ marginTop: '16px' }}
						disabled={
							metadataStore?.isLoading ||
							isModelCountLoading ||
							fetchingMetadata ||
							!showScoreTable ||
							validName.length !== 0 ||
							!componentChanged
						}
						variant="outlined"
						onClick={updateComponent}>
						{component.name === ''
							? t('Set Parameter')
							: t('Update Parameter')}
					</Button>
				</div>
			</Grid>
		);

		switch (type) {
			case ComponentType.Attribute:
				setup = (
					<>
						<Grid item xs={2} paddingLeft={'20px'}>
							{typeInputs()}
						</Grid>
						<Grid item xs={2} paddingLeft={'20px'}>
							{attrInputs()}
						</Grid>
						<Grid item xs={4}></Grid>
						{nextButton}
					</>
				);
				break;
			case ComponentType.SpatialProximity:
				setup = (
					<>
						<Grid item xs={2} paddingLeft={'20px'}>
							{typeInputs()}
						</Grid>
						<Grid item xs={2} paddingLeft={'20px'}>
							{dataSourceInputs()}
						</Grid>
						<Grid item xs={4}></Grid>
						{nextButton}
					</>
				);
				break;
			case ComponentType.SpatialAttribute:
				setup = (
					<>
						<Grid item xs={2} paddingLeft={'20px'}>
							{typeInputs()}
						</Grid>
						<Grid item xs={2} paddingLeft={'20px'}>
							{dataSourceInputs()}
						</Grid>
						<Grid item xs={2} paddingLeft={'20px'}>
							{dataSource && spatialAttriFieldInputs()}
						</Grid>
						<Grid item xs={2} paddingLeft={'20px'}>
							{dataSource && distanceInputs()}
						</Grid>
						{nextButton}
					</>
				);
				break;
		}

		return setup;
	};

	useEffect(() => {
		if (isNew && component.name.length === 0) {
			// New component to clean up as required
			clearFields(false);
			dispatch(clearComponentMetadata());
			dispatch(setModelScoreDataGrid([]));
			dispatch(setModelUnitScores(undefined));
			dispatch(setModelEditScores([]));
			dispatch(setModelScores([]));
			setShowScoreTable(false);
		} else if (field || dataSource) {
			fetchMetadata(true, true);
			createScoreRowsFromComponent(
				component.name.length === 0,
				rangeChanged,
				metadataType,
				nullScore,
				metadata,
				unitMetadata,
			);
		}
	}, [component]);

	useEffect(() => {
		if (
			riskCounts &&
			riskCounts.length !== 0 &&
			// scoreRows can get updated AFTER the risk count is updated so we need to verify they actually match
			(metadata === undefined ||
				(scoreRows.length ==
					metadata.length +
						(metadataType === 'unique' ||
						metadata[metadata.length - 1].key === '--'
							? 0
							: 1) &&
					scoreRows.find(
						row =>
							row.value ===
							(requestedCountMetadata?.[0].key ?? ''),
					)))
		) {
			const rows = updateModelCounts(
				scoreRows,
				riskCounts,
				system === 'Metric',
				isUnique,
			);

			dispatch(setModelScoreDataGrid(rows));
		}
	}, [riskCounts]);

	useEffect(() => {
		const metaDataName = `${field}-${type}-${dataSource}`;
		if (
			(isNew || unitMetadata === undefined) &&
			metadataStore &&
			!metadataStore.isLoading &&
			fetchingMetadata
		) {
			// We will get this back for saved muili param components if the user swaps back but we dont need to reset the data just need the units
			if (
				isNew &&
				(component.name.length === 0 ||
					unitMetadataName !== metaDataName)
			) {
				if (
					(metadataStore.unique && metadataStore.unique.length) ||
					metadataStore.range
				) {
					setUnitMetadata({
						unique: metadataStore.unique,
						precision: metadataStore.precision,
						range: metadataStore.range,
						unit: metadataStore.unit,
						currency: metadataStore.currency,
					});
					setUnitMetadataName(metaDataName);

					if (
						metadataStore.unique &&
						metadataStore.unique.length > 0
					) {
						// Convert the default settings into the component version
						let newMetadata = metadataStore.unique.map(item => ({
							key: item,
							value: 0,
						}));

						// Add the out of range if required
						if (
							newMetadata.find(value => value.key === '--') ===
							undefined
						) {
							newMetadata = [
								{ key: '--', value: 0 },
								...newMetadata,
							];
						}

						setRangeChanged(true);
						setMetadata(newMetadata);
						setMetadataType('unique');
						setNullScore(0);
						createScoreRowsFromComponent(
							true,
							true,
							'unique',
							0,
							newMetadata,
							metadataStore,
						);
					} else if (metadataStore.range) {
						if (
							isNew ||
							(metadata && metadata.length - 2 != rangeClasses)
						) {
							const newMetadata = createDefaultRangeValues(
								rangeClasses,
								metadataStore,
							);
							setRangeChanged(true);
							createScoreRowsFromComponent(
								true,
								true,
								'range',
								0,
								newMetadata,
								metadataStore,
							);
						}
					}
				} else {
					// There is an issue with the data, possibly too many unique values
					setValidConfig(false);
				}
			} else {
				setUnitMetadata({
					unique: metadataStore.unique,
					precision: metadataStore.precision,
					range: metadataStore.range,
					unit: metadataStore.unit,
					currency: metadataStore.currency,
				});
				setUnitMetadataName(metaDataName);
				setIsUnique(metadataType === 'unique');
				setShowScoreTable(true);
			}
			setFetchingMetadata(false);
			setDisableFieldSelect(false);
		}
	}, [metadataStore]);

	const onRangeValuesChanged = () => {
		setRangeChanged(true);
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
	};

	const onSetRangeClasses = (classes: number) => {
		setRangeChanged(true);
		setComponentChanged(true);
		if (onChange) onChange(true, false, false);
		setRangeClasses(classes);
		const newMetadata = createDefaultRangeValues(classes, unitMetadata);
		createScoreRowsFromComponent(
			false,
			true,
			'range',
			0,
			newMetadata,
			unitMetadata,
		);
	};

	const mapInsert = (
		<Grid xs={4} item paddingLeft={'20px'}>
			<InfoCard title={t('Map')} applyBodyPadding={false}>
				<div className={classes.map}>
					<MapInsertSpatial
						layerName={dataSource}
						showSystemTypeLayers={true}
						logLevel={MapStatusType.WARN}
					/>
				</div>
			</InfoCard>
		</Grid>
	);

	return (
		<FullPageWrapper
			key={countKey}
			title={''}
			applyPadding={false}
			headerUnderline={false}
			className={
				metadataStore.isLoading ||
				fetchingMetadata ||
				isModelCountLoading
					? classes.headerWait
					: classes.header
			}>
			<FullPage>
				<Grid container>
					{useSetup()}
					<Grid item xs={2}>
						<FormControl
							variant="outlined"
							margin="normal"
							fullWidth>
							<TextField
								fullWidth
								key={parameterNumber}
								name="ComponentName"
								defaultValue={componentName}
								label={
									parameterNumber === undefined
										? t('Component Name')
										: t('Group Name')
								}
								variant="outlined"
								onChange={validateName}
								helperText={validName}
								error={validName.length != 0}
							/>
						</FormControl>
					</Grid>
					{componentSetup()}
				</Grid>
				{showScoreTable && (
					<div>
						<Grid container paddingTop={'20px'}>
							<Grid item xs={hasMap ? 8 : 12}>
								<ScoreDataGrid
									isLoading={metadataStore?.isLoading}
									isLoadingCount={isModelCountLoading}
									dataGridKey={countKey}
									countKey={
										component.name.length !== 0 &&
										rangeChanged
											? changedRangesKey
											: countKey
									}
									isUnique={isUnique}
									isUniqueGrouped={isUniqueGrouped}
									isNew={true}
									rangeClasses={rangeClasses}
									distance={
										type === ComponentType.SpatialAttribute
											? distance
											: undefined
									}
									unit={unitMetadata?.unit}
									currency={unitMetadata?.currency}
									precision={unitMetadata?.precision}
									hideCounts={false}
									onSetRangeClasses={onSetRangeClasses}
									onRangeValuesChanged={onRangeValuesChanged}
									onRecalculateCount={onRecalculateCount}
									onUnqueGroups={onUnqueGroups}
								/>
							</Grid>
							{hasMap ? mapInsert : []}
						</Grid>
					</div>
				)}
			</FullPage>
		</FullPageWrapper>
	);
};
