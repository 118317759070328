/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium';
import { GridInitialState, UnitsContext } from '@innovyze/stylovyze';
import { RangeCell } from './RangeCell';
import { SkeletonCell } from './SkeletonCell';
import { useGlobalization } from '@Translations/useGlobalization';
import { useSelectIsCountLoading } from '@Selectors/Risk.selectors';
import { Create, Delete } from '@mui/icons-material';

export const getWidth = (
	field: string,
	dataGridState?: GridInitialState,
): number | undefined => {
	// Get the width of the column from the saved state if available
	if (
		dataGridState &&
		dataGridState.columns?.dimensions &&
		dataGridState.columns.dimensions[field]
	) {
		return dataGridState.columns.dimensions[field].width;
	} else return undefined;
};

export const getFlex = (
	field: string,
	flex: number,
	dataGridState?: GridInitialState,
): number | undefined => {
	// If there is a saved width then return undefined
	// We dont want to specify both flex and width since flex will override the width
	if (
		dataGridState &&
		dataGridState.columns?.dimensions &&
		dataGridState.columns.dimensions[field] &&
		dataGridState.columns.dimensions[field].width
	) {
		return undefined;
	} else return flex;
};

export const tableColumns = (
	onRangeChange: (index: number, value: number, valueUnit?: number) => void,
	isUnique: boolean,
	countKey: string,
	isUniqueGrouped: boolean,
	unit?: string,
	currency?: string,
	precision?: number,
	dataGridState?: GridInitialState,
	hideCounts?: boolean,
) => {
	const { t } = useGlobalization();
	const { system } = useContext(UnitsContext);
	const lengthUnit = system === 'Metric' ? t('Kilometers') : t('Miles');

	const isRiskCountLoading = useSelectIsCountLoading(countKey);

	const header = new Array<GridColDef>();

	if (t) {
		if (isUnique) {
			header.push({
				field: 'value',
				headerName: t('Value'),
				sortable: false,
				hideable: false,
				align: 'left',
				flex: hideCounts
					? undefined
					: getFlex('value', 1, dataGridState),
				width:
					getWidth('value', dataGridState) ?? hideCounts
						? 300
						: undefined,
				minWidth: 100,
			});
		} else {
			header.push({
				field: 'value',
				headerName: t('Value'),
				sortable: false,
				hideable: false,
				align: 'left',
				flex: hideCounts
					? undefined
					: getFlex('value', 1.5, dataGridState),
				width:
					getWidth('value', dataGridState) ?? hideCounts
						? 500
						: undefined,
				minWidth: 200,
				renderCell: params => {
					return (
						<RangeCell
							id={params.id}
							value={params.value}
							onRangeChange={onRangeChange}
							disabled={isRiskCountLoading}
							unit={unit}
							currency={currency}
							precision={precision}
						/>
					);
				},
			});
		}

		if (!hideCounts) {
			header.push(
				{
					field: 'count',
					headerName: t('Asset Count'),
					sortable: false,
					hideable: false,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					flex: getFlex('count', 1, dataGridState),
					width: getWidth('count', dataGridState),
					renderCell: params => {
						return (
							<SkeletonCell
								id={params.id}
								countKey={countKey}
								value={params.value}
								percent={false}
							/>
						);
					},
				},
				{
					field: 'countPercent',
					headerName: t('Asset Count (%)'),
					sortable: false,
					hideable: false,
					align: 'left',
					headerAlign: 'left',
					flex: getFlex('countPercent', 1, dataGridState),
					width: getWidth('countPercent', dataGridState),
					renderCell: params => {
						return (
							<SkeletonCell
								id={params.id}
								countKey={countKey}
								value={params.value}
								percent={false}
							/>
						);
					},
				},
				{
					field: 'length',
					headerName: t('Length ({{unit}})', { unit: lengthUnit }),
					sortable: false,
					hideable: false,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					flex: getFlex('length', 1, dataGridState),
					width: getWidth('length', dataGridState),
					renderCell: params => {
						return (
							<SkeletonCell
								id={params.id}
								countKey={countKey}
								value={params.value}
								percent={false}
							/>
						);
					},
				},
				{
					field: 'lengthPercent',
					headerName: t('Length (%)'),
					sortable: false,
					hideable: false,
					align: 'left',
					headerAlign: 'left',
					flex: getFlex('lengthPercent', 1, dataGridState),
					width: getWidth('lengthPercent', dataGridState),
					renderCell: params => {
						return (
							<SkeletonCell
								id={params.id}
								countKey={countKey}
								value={params.value}
								percent={false}
							/>
						);
					},
				},
			);
		}

		if (isUniqueGrouped) {
			header.push({
				field: 'menu',
				headerName: '',
				sortable: false,
				filterable: false,
				hideable: false,
				// flex: 0.5,
				maxWidth: 50,
				minWidth: 50,
				width: 50,
				align: 'center',
				disableColumnMenu: true,
				type: 'actions',
				getActions: () => {
					return [
						// <GridActionsCellItem
						// 	key={'edit'}
						// 	icon={<Create />}
						// 	label={t('Edit')}
						// 	onClick={() => {
						// 		console.log('edit');
						// 	}}
						// 	showInMenu
						// />,
						<GridActionsCellItem
							key={'del'}
							icon={<Delete />}
							label={t('Delete')}
							onClick={() => {
								console.log('delete');
							}}
							showInMenu
						/>,
					];
				},
			});
		}
	}

	return header;
};
