import {
	Pagination,
	ResolvedResponse,
	Sort2,
	Status,
} from '@innovyze/lib_am_common';
import { createAction } from '@reduxjs/toolkit';
import {
	FailureConfigList,
	failureConfigsPaginationDefault,
} from '@Types/failureConfig.types';
import { addNamespace } from '@Utils/actions';

export const getFailureConfigs = createAction<Pagination>(
	addNamespace('failureConfigs/getFailureConfigs'),
);

export const clearFailureConfigs = createAction<Pagination>(
	addNamespace('failureConfigs/clearFailureConfigs'),
);

export const getFailureConfigsClear = () =>
	clearFailureConfigs(failureConfigsPaginationDefault());

export const reloadFailureConfigs = createAction(
	addNamespace('failureConfigs/reloadFailureConfigs'),
);

export const getFailureConfigsResolved = createAction<ResolvedResponse>(
	addNamespace('failureConfigs/getFailureConfigs/resolved'),
);

export const getFailureConfigsRejected = createAction<Status>(
	addNamespace('failureConfigs/getFailureConfigs/rejected'),
);

export const changeFailureConfigsSort = createAction<Sort2>(
	addNamespace('failureConfigs/sort'),
);

export const changeFailureConfigsSlice = createAction<number>(
	addNamespace('failureConfigs/slice'),
);

export const changeFailureConfigsPage = createAction<number>(
	addNamespace('failureConfigs/page'),
);

export const currentFailureConfigsRow = createAction<number>(
	addNamespace('failureConfigs/current/row'),
);

export const setFailureConfigsIsBusy = createAction<boolean>(
	addNamespace('failureConfigs/isBusy'),
);

export const getFailureConfigsList = createAction<{ assetType: string }>(
	addNamespace('failureConfigs/getFailureConfigsList'),
);

export const getFailureConfigsListResolved = createAction<FailureConfigList[]>(
	addNamespace('failureConfigs/getFailureConfigsList/resolved'),
);

export const getFailureConfigsListRejected = createAction<Status>(
	addNamespace('failureConfigs/getFailureConfigsList/rejected'),
);
