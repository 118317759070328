import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const assetFieldsStoreSelector = createSelector(
	parseNamespace,
	state => state.assetFields,
);

export const selectAssetFieldsStore = () =>
	useSelector(assetFieldsStoreSelector);
