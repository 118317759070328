import React from 'react';

function Scales() {
	return (
		<svg
			width="117"
			height="116"
			viewBox="0 0 117 116"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<ellipse
				cx="27.3433"
				cy="88.4043"
				rx="26.3726"
				ry="26.8726"
				fill="white"
			/>
			<circle
				opacity="0.2"
				cx="66.9102"
				cy="50.2769"
				r="50"
				fill="#A5BDC6"
			/>
			<rect
				x="6.89648"
				y="66.4583"
				width="42.8922"
				height="43.8922"
				rx="21.4461"
				fill="#00ABD1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.8482 89.5016H29.4144V96.0854H27.2698V89.5016H20.8359V87.307H27.2698V80.7231H29.4144V87.307H35.8482V89.5016Z"
				fill="white"
			/>
		</svg>
	);
}

export default Scales;
