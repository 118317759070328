import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import { MultiScoreItem, ScoreDataGridRow, ScoreTableRow } from '@Types';
import {
	DataGridStateChange,
	ModelScoreChange,
	ModelValueChange,
} from '@Reducers/util.reducer';

export const setModelScores = createAction<number[]>(
	addNamespace('Util/setModelScores'),
);

export const setModelEditScores = createAction<string[]>(
	addNamespace('Util/setModelEditScores'),
);

export const setModelUnitScores = createAction<number[] | undefined>(
	addNamespace('Util/setModelUnitScores'),
);

export const setModelScoreTable = createAction<ScoreTableRow[]>(
	addNamespace('Util/setModelScoreTable'),
);

export const setModelScoreDataGrid = createAction<ScoreDataGridRow[]>(
	addNamespace('Util/setModelScoreDataGrid'),
);

export const setModelMultiScoreDataGrid = createAction<MultiScoreItem[]>(
	addNamespace('Util/setModelMultiScoreDataGrid'),
);

export const updateModelMultiScoreDataGridRow = createAction<MultiScoreItem>(
	addNamespace('Util/updateModelMultiScoreDataGridRow'),
);

export const updateModelScoreDataGrid = createAction<ModelScoreChange>(
	addNamespace('Util/updateModelScoreDataGrid'),
);

export const updateModelValuesDataGrid = createAction<ModelValueChange[]>(
	addNamespace('Util/updateModelValuesDataGrid'),
);

export const setModelNullScore = createAction<number>(
	addNamespace('Util/setModelNullScore'),
);

export const setModelStatus = createAction<string>(
	addNamespace('Util/setModelStatus'),
);

export const setModelRunning = createAction<boolean>(
	addNamespace('Util/setModelRunning'),
);

export const setModelLastRun = createAction<string>(
	addNamespace('Util/setModelLastRun'),
);

export const setDataGridState = createAction<DataGridStateChange>(
	addNamespace('dataGrid/state'),
);
