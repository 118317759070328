import {
	clearModelConfig,
	getFailureConfigsClear,
	getModelConfigsClear,
} from '@Actions';
import ModelList from '@Components/ModelList';
import { getAssetCounts } from '@innovyze/lib_am_common/Actions';
import { useGlobalization } from '@Translations/useGlobalization';
import { FullPage, FullPageWrapper } from '@Utils/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectModelConfigIsCreating } from '@Selectors/model.selectors';
import { SystemTypes } from '@innovyze/inno-map';
import {
	selectModelConfigsIsLoading,
	selectModelConfigsIsWaiting,
	selectModelConfigsPage,
} from '@Selectors/modelConfigs.selectors';
import FailureList from '@Components/FailureList';

export const ModelManagementList = () => {
	const dispatch = useDispatch();
	const { t } = useGlobalization();

	const isLoading = selectModelConfigsIsLoading();
	const isWaiting = selectModelConfigsIsWaiting();
	const isCreating = selectModelConfigIsCreating();
	const riskPage = selectModelConfigsPage();

	useEffect(() => {
		if (riskPage === undefined || riskPage.total === 0) {
			dispatch(getModelConfigsClear());
			dispatch(getFailureConfigsClear());
			dispatch(
				getAssetCounts([
					SystemTypes.SanitarySewer,
					SystemTypes.WaterDistribution,
				]),
			);
		}
		dispatch(clearModelConfig());
	}, []);

	const modelListTab = () => {
		return (
			<FullPage>
				<ModelList />
			</FullPage>
		);
	};

	const failureListTab = () => {
		return (
			<FullPage>
				<FailureList />
			</FullPage>
		);
	};

	const secondaryNav = () => {
		const tabs = [
			{ content: modelListTab(), title: t('Models') },
			{ content: failureListTab(), title: t('Failure Definition') },
		];
		return tabs;
	};

	return (
		<FullPageWrapper
			className={isLoading || isWaiting || isCreating ? 'wait' : ''}
			title={t('Deterioration Management')}
			applyPadding={true}
			headerUnderline
			//secondary={<CreateSecondaryButtons />}
			secondaryNav={{
				items: secondaryNav(),
				itemIndex: 0,
			}}></FullPageWrapper>
	);
};
export default ModelManagementList;
