// import { ModelPostProcessing } from '@innovyze/lib_am_common';

import { Pagination } from '@innovyze/lib_am_common';
import { paginationDefault } from '@innovyze/lib_am_common/Reducers';

export interface ModelConfigList {
	configId: string;
	name: string;
}

export interface ModelConfigsListState {
	configs: ModelConfigList[];
	isLoading: boolean;
}

export enum ConfigPublishState {
	published = 'published',
	unpublished = 'unpublished',
}

// export interface ModelConfig {
// 	configId: string;
// 	name: string;
// 	assetType: string;
// 	lastRun: string;
// 	lastRunCompleted?: string;
// 	status?: string;
// 	runBy?: string;
// 	assetCount?: number;
// 	publishState: ConfigPublishState;
// 	// postProcessing?: ModelPostProcessing[];
// }

export interface ModelConfigCard {
	configId: string;
	tenantId: string;
	name: string;
	publishState: ConfigPublishState;
	systemType: string;
	assetType: string;
	lastRun: string;
	lastRunCompleted?: string;
	createdAt: string;
	runBy?: string;
	status?: string;
	assetCount?: number;
	// postProcessing?: RiskPostProcessing[];
}

export interface ModelDetailsRow {
	_id: string;
	ASSET_ID: string;
	// 	CURRENT_SERVICE: string;
	// 	CURRENT_STRUCTURAL: string;
	// 	CURRENT_LOF: number;
	// 	CURRENT_COF: number;
	// 	CURRENT_RISK: number;
	// 	GRADE: number;
	// 	RISK_CHANGE: string;
	// 	WEIGHTED_SCORES?: Record<string, number>;
	// 	CONDITION_DATA?: string;
}

export const modelConfigsPaginationDefault = (): Pagination => ({
	...paginationDefault,
	search: undefined,
	sortKey: 'createdAt',
	sortDescending: true,
});
