import { Environments, PartialServiceMap } from '@innovyze/stylovyze';

const config: PartialServiceMap = {
	// Sample config for overwritting service endpoints
	[Environments.LOCAL]: {
		amRisk: 'https://saas-risk-management.info360-dev.com/dev',
		// amDeterioration: 'https://saas-risk-management.info360-dev.com/dev',
		looker: 'http://localhost:3276/dev/looker',
		amUpload: 'https://saas-upload-service.info360-dev.com/dev',
		tiles: 'https://tiles-dev.info360-dev.com/dev',
		export: 'https://saas-export-service.info360-dev.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
	},
};

export const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export default config;
