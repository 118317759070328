// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { createAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { ComponentMetadata } from '@Types/component.types';
import { Activites, ActivitesData } from '@Types/risk.types';
import { Category } from '@Types/category.types';
import {
	IsCountLoadingChange,
	RiskCountChange,
	RiskCountRename,
} from '@Reducers/Risk.reducers';

export const setRiskCountIsLoading = createAction<IsCountLoadingChange>(
	'Risk/setRiskCountIsLoading',
);

export const getRiskCountAttribute = createAction<{
	key: string;
	metadata: ComponentMetadata[];
	metadataType: string;
	assetType: string;
	fieldName: string;
}>('Risk/getRiskCountAttribute');

export const getRiskCountProximity = createAction<{
	key: string;
	metadata: ComponentMetadata[];
	metadataType: string;
	assetType: string;
	layerName: string;
}>('Risk/getRiskCountProximity');

export const getRiskCountProximityAttribute = createAction<{
	key: string;
	metadata: ComponentMetadata[];
	metadataType: string;
	assetType: string;
	layerName: string;
	fieldName: string;
	valueType: string;
	distance: number;
}>('Risk/getRiskCountProximityAttribute');

export const getRiskCountResolved = createAction<RiskCountChange>(
	'Risk/getRiskCountResolved',
);

export const renameRiskCount = createAction<RiskCountRename>(
	'Risk/renameRiskCount',
);

export const setActivitiesDataIsLoading = createAction<boolean>(
	'Risk/setActivitiesDataIsLoading',
);

export const getActivitesData = createAction<Activites>(
	'Risk/getActivitesData',
);

export const getActivitesDataResolved = createAction<ActivitesData[]>(
	'Risk/getActivitesDataResolved',
);

export const getActivitesDataRejected = createAction(
	'Risk/getActivitesDataRejected',
);

export const getProximityAttributeFields = createAction<string>(
	'ProximityAttribute/getProximityAttributeFields',
);
export const getProximityAttributeFieldsResolved = createAction<Category[]>(
	'ProximityAttribute/getProximityAttributeFieldsResolved',
);
export const getProximityAttributeFieldsRejected = createAction(
	'ProximityAttribute/getProximityAttributeFieldsRejected',
);
export const getAttributeFields = createAction<string>(
	'Risk/getAttributeFields',
);
export const getAttributeFieldsResolved = createAction<Category[]>(
	'Risk/getAttributeFieldsResolved',
);
export const getAttributeFieldsRejected = createAction(
	'Risk/getAttributeFieldsRejected',
);
