import { Card } from '@mui/material';
import styled from 'styled-components';

export const NewCardWrapper = styled(Card)`
	height: 100%;

	.create_card__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		flex-direction: column;
		cursor: pointer;
		.create_card__note {
			margin-top: 0.8rem;
		}
		h6 {
			color: '#252F33';
		}
	}
`;
