/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	gridDetailPanelExpandedRowsContentCacheSelector,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-premium';
import { Button, GridInitialState } from '@innovyze/stylovyze';
import { useGlobalization } from '@Translations/useGlobalization';
import { useSelectIsCountLoading } from '@Selectors/Risk.selectors';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const getWidth = (
	field: string,
	dataGridState?: GridInitialState,
): number | undefined => {
	// Get the width of the column from the saved state if available
	if (
		dataGridState &&
		dataGridState.columns?.dimensions &&
		dataGridState.columns.dimensions[field]
	) {
		return dataGridState.columns.dimensions[field].width;
	} else return undefined;
};

export const getFlex = (
	field: string,
	flex: number,
	dataGridState?: GridInitialState,
): number | undefined => {
	// If there is a saved width then return undefined
	// We dont want to specify both flex and width since flex will override the width
	if (
		dataGridState &&
		dataGridState.columns?.dimensions &&
		dataGridState.columns.dimensions[field] &&
		dataGridState.columns.dimensions[field].width
	) {
		return undefined;
	} else return flex;
};

const CustomDetailPanelToggle = (
	props: Pick<GridRenderCellParams, 'id' | 'value'>,
) => {
	const { id, value: isExpanded } = props;
	const apiRef = useGridApiContext();
	const { t } = useGlobalization();

	// To avoid calling ´getDetailPanelContent` all the time, the following selector
	// gives an object with the detail panel content for each row id.
	const contentCache = useGridSelector(
		apiRef,
		gridDetailPanelExpandedRowsContentCacheSelector,
	);

	// If the value is not a valid React element, it means that the row has no detail panel.
	const hasDetail = React.isValidElement(contentCache[id]);

	return (
		<Button
			variant="outlined"
			color="primary"
			tabIndex={-1}
			disabled={!hasDetail}
			aria-label={isExpanded ? t('Close') : t('Open')}>
			{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
		</Button>
	);
};

export const tableColumns = (
	countKey: string,
	dataGridState?: GridInitialState,
) => {
	const { t } = useGlobalization();

	const header = new Array<GridColDef>();

	if (t) {
		header.push({
			field: 'group1',
			headerName: t('Group 1'),
			sortable: true,
			hideable: false,
			align: 'left',
			flex: getFlex('group1', 0.5, dataGridState),
			width: getWidth('group1', dataGridState),
			minWidth: 100,
		});

		header.push({
			field: 'group2',
			headerName: t('Group 2'),
			sortable: true,
			hideable: false,
			align: 'left',
			flex: getFlex('group2', 0.5, dataGridState),
			width: getWidth('group2', dataGridState),
			minWidth: 100,
		});

		header.push(
			{
				field: 'count',
				headerName: t('Asset Count'),
				sortable: false,
				hideable: false,
				align: 'left',
				headerAlign: 'left',
				type: 'number',
				flex: getFlex('count', 1, dataGridState),
				width: getWidth('count', dataGridState),
			},
			{
				field: 'countPercent',
				headerName: t('Asset Count (%)'),
				sortable: false,
				hideable: false,
				align: 'left',
				headerAlign: 'left',
				flex: getFlex('countPercent', 1, dataGridState),
				width: getWidth('countPercent', dataGridState),
			},
			{
				field: 'failures',
				headerName: t('Failures'),
				sortable: false,
				hideable: false,
				align: 'left',
				headerAlign: 'left',
				type: 'number',
				flex: getFlex('length', 1, dataGridState),
				width: getWidth('length', dataGridState),
			},
			{
				field: 'method',
				headerName: t('Method'),
				sortable: false,
				hideable: false,
				align: 'left',
				headerAlign: 'left',
				flex: getFlex('method', 1, dataGridState),
				width: getWidth('method', dataGridState),
			},
			{
				...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
				renderCell: params => (
					<CustomDetailPanelToggle
						id={params.id}
						value={params.value}
					/>
				),
				minWidth: 90,
			},
		);
	}

	return header;
};
