// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import {
	ColumnFilterOptions,
	ColumnFilterValue,
	Pagination,
	ResolvedResponse,
	Sort2 as Sort,
	Status,
} from '@innovyze/lib_am_common';
import { createAction } from '@reduxjs/toolkit';
import {
	CreateFailureConfig,
	LogReply,
	FailureCalcStatusResult,
	FailureConfig,
	ConfigPublishState,
} from '@Types';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';
import { addNamespace } from '@Utils/actions';

export const paginationDefaultFailure = (): Pagination => ({
	...paginationInitialState,
	sortKey: 'CURRENT_RISK',
	sortDescending: true,
});

export const getFailureConfig = createAction<string>(
	'Failure/getFailureConfig',
);
export const getFailureConfigResolved = createAction<FailureConfig>(
	'Failure/getFailureConfigResolved',
);
export const getFailureConfigRejected = createAction(
	'Failure/getFailureConfigRejected',
);
export const clearFailureConfig = createAction('Failure/clearFailureConfig');

export const createFailureConfig = createAction<{
	history: unknown;
	config: CreateFailureConfig;
	page: number;
}>('Failure/createFailureConfig');

export const changeFailureConfigPublishState = createAction<{
	newPublishState: ConfigPublishState;
	configId: string;
	refresh: () => void;
}>('Failure/changeFailureConfigPublishState');

export const duplicateFailureConfig = createAction<{
	onComplete: (configId: string) => void;
	configId: string;
}>('Failure/duplicateFailureConfig');

export const setFailureConfigIsCreating = createAction<boolean>(
	'failureSetup/setFailureConfigIsCreating',
);

export const setFailureConfigNameError = createAction<string>(
	'failureSetup/setFailureConfigNameError',
);

export const renameFailureConfigAction = createAction<FailureConfig>(
	'Failure/renameFailureConfig',
);

export const saveFailureConfigAction = createAction<FailureConfig>(
	'Failure/saveFailureConfig',
);

export const getFailureDetails = createAction<Pagination>(
	'failure/getFailureDetails',
);

export const runFailure = createAction<string>('failure/runFailure');
export const runFailureRejected = createAction('failure/runFailureRejected');
export const runFailureResolved = createAction('failure/runFailureResolved');

export const resetFailure = createAction<string>('failure/resetFailure');
export const resetFailureRejected = createAction(
	'failure/resetFailureRejected',
);
export const resetFailureResolved = createAction(
	'failure/resetFailureResolved',
);

export const getFailureDetailsDefaulted = () =>
	getFailureDetails(paginationDefaultFailure());
export const reloadFailureDetails = createAction<string>(
	'failure/reloadFailureDetails',
);
export const getFailureDetailsResolved = createAction<ResolvedResponse>(
	'Failure/getFailureDetails/resolved',
);
export const getFailureDetailsRejected = createAction<Status>(
	'Failure/getFailureDetails/rejected',
);

export const changePagination = createAction<{ page?: number; slice?: number }>(
	'Failure/paginationFailure',
);

export const clearFailureScanId = createAction(
	addNamespace('Failure/clearFailureScanId'),
);

export const changeFailureSlice = createAction<number>('Failure/slice');

export const changeFailurePage = createAction<number>('Failure/page');

export const changeFailureSearch = createAction<string>('Failure/changeSearch');

export const changeFailureSort = createAction<Sort>('Failure/sort');

export const setFailureSelected = createAction<string[]>(
	'Failure/selected/set',
);

export const addFailureSelected = createAction<string[]>(
	'Failure/selected/add',
);

export const removeFailureSelected = createAction<string[]>(
	'Failure/selected/remove',
);

export const clearFailureSelected = createAction('Failure/selected/clear');

export const currentFailureRow = createAction<number>('Failure/current/row');

export const setFailureDetailsIsBusy = createAction<boolean>('Failure/isBusy');
export const setFailureTab = createAction<string>('Failure/setTab');
export const setFailureSaveRun = createAction<boolean>('Failure/setSaveRun');

export const setFailureCalcStatus = createAction<FailureCalcStatusResult>(
	'Failure/setFailureCalcStatus',
);

export const getFailureLog = createAction<string>('Failure/getFailureLog');
export const getFailureLogResolved = createAction<LogReply>(
	'Failure/getFailureLogResolved',
);
export const getFailureLogRejected = createAction(
	'Failure/getFailureLogRejected',
);

export const changeFilterSelect = createAction<ColumnFilterValue>(
	addNamespace('Failure/filterSelect'),
);
export const changeFailureFiltersSelect = createAction<ColumnFilterValue[]>(
	addNamespace('Failure/filtersSelect'),
);

export const updateColumnFailureFilterSelectOptions =
	createAction<ColumnFilterOptions>(
		addNamespace('Failure/filterSelect/column/options'),
	);

export const setFailureSetupDirty = createAction<boolean>(
	'failureSetup/setFailureSetupDirty',
);

export const setFailureRunCompleteReload = createAction<boolean>(
	'Failure/failureCompleteReload',
);

export const setFailureRefreshList = createAction<boolean>(
	'Failure/setRefreshList',
);

export const setFailureIsSaving = createAction<boolean>(
	'failureSetup/setFailureIsSaving',
);

export const deleteFailure = createAction<{
	configId: string;
	refresh: () => void;
}>('rehab/deleteFailure');
export const setFailureIsDeleting = createAction<boolean>(
	'failure/setFailureIsDeleting',
);
