import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, useGridApiRef, GridRowParams } from '@innovyze/stylovyze';
import { selectDataGridState, selectRiskScoreDataGrid } from '@Selectors';
import { setDataGridState } from '@Actions';
import { tableColumns } from './TabelColumns';
import { TableToolbar } from './TableToolbar';
import { ScoreDataGridRow } from '@Types/scoreDataGridType';
import { ComponentCountReply } from '@Types/risk.types';
import { Component } from '@Types/index';
import { CohortPanel } from './CohortPanel';

export const updateModelCounts = (
	scoreRows: ScoreDataGridRow[],
	riskCounts: ComponentCountReply[] | undefined,
	isMetric: boolean,
	isUnique: boolean,
): ScoreDataGridRow[] => {
	if (riskCounts === undefined || riskCounts.length == 0) {
		const newScoreRows: ScoreDataGridRow[] = [];
		for (let i = 0; i < scoreRows.length; i++) {
			newScoreRows.push({
				...scoreRows[i],
				count: undefined,
				length: undefined,
				countPercent: undefined,
				lengthPercent: undefined,
			});
		}
		return newScoreRows;
	} else {
		const conversion = isMetric ? 1 : 0.06213712;
		const newScoreRows: ScoreDataGridRow[] = [];
		if (isUnique) {
			for (let i = 0; i < scoreRows.length; i++) {
				const count = riskCounts.find(
					({ key }) =>
						(key === undefined || key === null
							? '--'
							: key.toString()) === scoreRows[i].value.toString(),
				);

				if (count) {
					newScoreRows.push({
						...scoreRows[i],
						count: count.count,
						length: Math.round(count.length * conversion) / 100,
						countPercent:
							Math.round(count.count_percent * 1000) / 10,
						lengthPercent:
							Math.round(count.length_percent * 1000) / 10,
					});
				} else {
					newScoreRows.push({
						...scoreRows[i],
						count: 0,
						length: 0,
						countPercent: 0,
						lengthPercent: 0,
					});
				}
			}
		} else if (riskCounts) {
			for (let i = 0; i < scoreRows.length; i++) {
				newScoreRows.push({
					...scoreRows[i],
					count: riskCounts[i].count,
					length: Math.round(riskCounts[i].length * conversion) / 100,
					countPercent:
						Math.round(riskCounts[i].count_percent * 1000) / 10,
					lengthPercent:
						Math.round(riskCounts[i].length_percent * 1000) / 10,
				});
			}
		} else {
			for (let i = 0; i < scoreRows.length; i++) {
				newScoreRows.push({
					...scoreRows[i],
					count: 0,
					length: 0,
					countPercent: 0,
					lengthPercent: 0,
				});
			}
		}

		return newScoreRows;
	}
};

interface ScoreDataGridProps {
	isLoading: boolean;
	isLoadingCount: boolean;
	dataGridKey: string;
	countKey: string;
	groups: Component[];
}

// This ensure we remove any extranious decimal places caused by javascript
const toFixedDP = (value: number, precision: number): number => {
	return +value.toFixed(precision);
};

const CohortDataGrid = ({
	isLoading,
	isLoadingCount,
	dataGridKey,
	countKey,
	groups,
}: ScoreDataGridProps) => {
	const dispatch = useDispatch();

	const apiRef = useGridApiRef();
	const dataGridState = selectDataGridState(dataGridKey);

	const scoreRows = selectRiskScoreDataGrid();

	const tempRows = [
		{
			id: 0,
			group1: 'PVC',
			group2: '<10',
			count: 0,
			countPercent: 0,
			failures: 0,
			method: 'TEST',
		},
	];

	const onStateChange = React.useCallback(() => {
		// We dont want to use the individual states that could be passed but just export the whole state to keep it all in sync
		const state = apiRef.current.exportState();
		dispatch(
			setDataGridState({
				key: dataGridKey,
				initialState: state,
			}),
		);
	}, [dataGridKey]);

	const getCohortPanel = React.useCallback(
		({ row }: GridRowParams) => <CohortPanel />,
		[],
	);

	return (
		<DataGrid
			apiRef={apiRef}
			disableColumnMenu
			disableColumnReorder
			loading={isLoading}
			skeletonRowCount={10} // TODO: Actual row count if possible
			columns={tableColumns(countKey, dataGridState)}
			rows={tempRows}
			slots={{
				toolbar: TableToolbar,
			}}
			slotProps={{
				toolbar: {
					isLoading: isLoading || isLoadingCount,
				},
			}}
			// DONT USE onStateChange={onStateChange} AS THIS GETS CALLED FOR EVERY USER ACTION... SO PROBABLY BEST TO AVOID !!
			onColumnWidthChange={onStateChange}
			dataCy="score-table"
			getDetailPanelContent={getCohortPanel}
		/>
	);
};

export default CohortDataGrid;
