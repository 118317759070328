import {
	clearAssetFields,
	getAssetFields,
	getAssetFieldsRejected,
	getAssetFieldsResolved,
	getSpatialLayerListResolved,
} from '@Actions/asset.actions';
import { createReducer } from '@reduxjs/toolkit';
import { AssetFieldsStoreState } from '@Types';
import { AnyAction } from 'redux';

export const initState: AssetFieldsStoreState = {
	assetFields: [],
	isLoading: false,
	spatialLayerList: [],
	spatialProximityAttr: [],
	customTable: [],
};

interface AssetFieldsReducer {
	[x: string]: (
		state: AssetFieldsStoreState,
		action: AnyAction,
	) => AssetFieldsStoreState | undefined;
}

export const reducer: AssetFieldsReducer = {
	[getAssetFields.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[getAssetFieldsResolved.toString()]: (state, action) => ({
		...state,
		assetFields: action.payload,
		isLoading: false,
	}),
	[getAssetFieldsRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[clearAssetFields.toString()]: state => ({
		...state,
		assetFields: [],
	}),
	[getSpatialLayerListResolved.toString()]: (state, action) => ({
		...state,
		spatialLayerList: action.payload,
	}),
};

export const assetReducer = createReducer(initState, reducer);
