import React from 'react';
import './App.css';

import { ApplicationWrapper } from '@innovyze/stylovyze';
import { miniPageRoutes } from '@Pages';

function App() {
	const pages = miniPageRoutes;

	return <ApplicationWrapper pages={pages} disableMapProvider={true} />;
}

export default App;
