import { createAction } from '@reduxjs/toolkit';
import { AssetField } from '@Types';
import { SpatialLayer } from '@innovyze/lib_am_common';

export const getAssetFields = createAction('asset/getAssetFields');
export const getAssetFieldsResolved = createAction<AssetField[]>(
	'asset/getAssetFieldsResolved',
);
export const getAssetFieldsRejected = createAction(
	'asset/getAssetFieldsRejected',
);

export const clearAssetFields = createAction('asset/clearAssetFields');

export const getSpatialLayerList = createAction('Asset/getSpatialLayerList');
export const getSpatialLayerListResolved = createAction<SpatialLayer[]>(
	'Asset/getSpatialLayerListResolved',
);
export const getSpatialLayerListRejected = createAction(
	'Asset/getSpatialLayerListRejected',
);
