import {
	getComponentMetadataResolved,
	getComponentMetadataRejected,
	getComponentAssetMetadata,
	getComponentProximityMetadata,
	getComponentProximityAttrMetadata,
} from '@Actions';
import { put, takeLatest } from '@redux-saga/core/effects';
import { ComponentMetadataResponse } from '@Types/component.types';
import { AxiosResponse } from 'axios';
import { retry } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { error } from '@innovyze/stylovyze';
import { t } from 'i18next';
import {
	getComponentAssetMetadataApi,
	getComponentProximityAttrMetadataApi,
	getComponentProximityMetadataApi,
} from '@Services/Risk/Risk.services';

const multiParamUniqueLimit = 30;
const singleParamUniqueLimit = 100;

function* getComponentAssetMetadataSaga(action: AnyAction) {
	try {
		const response: AxiosResponse<ComponentMetadataResponse> = yield retry(
			5,
			1500,
			getComponentAssetMetadataApi,
			action.payload.assetType,
			action.payload.field,
		);
		if (response.data.unique) {
			if (
				response.data.unique.length >
				(action.payload.multiParam
					? multiParamUniqueLimit
					: singleParamUniqueLimit)
			) {
				yield put(
					error(
						t('Too many unique values for {{field}}', {
							field: action.payload.fieldAlias,
						}),
					),
				);
				delete response.data.unique;
			} else if (response.data.unique.length === 0)
				delete response.data.unique;
		}
		yield put(getComponentMetadataResolved(response.data));
	} catch (e) {
		yield put(
			error(
				t('Scoring cannot be set due insufficient data for {{field}}', {
					field: action.payload.fieldAlias,
				}),
			),
		);
		yield put(getComponentMetadataRejected());
	}
}

function* getComponentProximityMetadataSaga(action: AnyAction) {
	try {
		const response: AxiosResponse<ComponentMetadataResponse> = yield retry(
			5,
			1500,
			getComponentProximityMetadataApi,
			action.payload,
		);
		if (response.data.unique) {
			if (
				response.data.unique.length >
				(action.payload.multiParam
					? multiParamUniqueLimit
					: singleParamUniqueLimit)
			) {
				yield put(
					error(
						t('Too many unique values for {{field}}', {
							field: action.payload.fieldAlias,
						}),
					),
				);
				delete response.data.unique;
			} else if (response.data.unique.length === 0)
				delete response.data.unique;
		}
		yield put(getComponentMetadataResolved(response.data));
	} catch (e) {
		yield put(getComponentMetadataRejected());
	}
}

function* getComponentProximityAttrMetadataSaga(action: AnyAction) {
	try {
		const response: AxiosResponse<ComponentMetadataResponse> = yield retry(
			5,
			1500,
			getComponentProximityAttrMetadataApi,
			action.payload.assetType,
			action.payload.field,
			action.payload.layerName,
		);
		if (response.data.unique) {
			if (
				response.data.unique.length >
				(action.payload.multiParam
					? multiParamUniqueLimit
					: singleParamUniqueLimit)
			) {
				yield put(
					error(
						t('Too many unique values for {{field}}', {
							field: action.payload.fieldAlias,
						}),
					),
				);
				delete response.data.unique;
			} else if (response.data.unique.length === 0)
				delete response.data.unique;
		}
		yield put(getComponentMetadataResolved(response.data));
	} catch (e) {
		yield put(getComponentMetadataRejected());
	}
}

function* watchGetComponentMetadata() {
	yield takeLatest(getComponentAssetMetadata, getComponentAssetMetadataSaga);
	yield takeLatest(
		getComponentProximityMetadata,
		getComponentProximityMetadataSaga,
	);
	yield takeLatest(
		getComponentProximityAttrMetadata,
		getComponentProximityAttrMetadataSaga,
	);
}

const sagaArray = [watchGetComponentMetadata()];

export default sagaArray;
