import {
	getFailureConfigsList,
	getFailureConfigsListRejected,
	getFailureConfigsListResolved,
} from '@Actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { FailureConfigsListState } from '@Types';

// I HAVE NO IDEA WHY I HAVE TO DUPLICATE THIS HERE... without it I get this error when runnin in a module
// Uncaught Error: The slice reducer for key "riskConfigs" returned undefined during initialization. If the state passed to the reducer is undefined, you must explicitly return the initial state.
// It seems the paginationInitialState is getting initilized AFTER the reducers but I cant see why
export const initialState: FailureConfigsListState = {
	configs: [],
	isLoading: false,
};

interface FailureConfigsListReducer {
	[x: string]: (
		state: FailureConfigsListState,
		action: AnyAction,
	) => FailureConfigsListState | undefined;
}

export const reducer: FailureConfigsListReducer = {
	[getFailureConfigsList.toString()]: () => ({
		...initialState,
		isLoading: true,
	}),
	[getFailureConfigsListResolved.toString()]: (_state, action) => ({
		configs: action.payload,
		isLoading: false,
	}),
	[getFailureConfigsListRejected.toString()]: () => ({
		...initialState,
	}),
};

export const failureConfigsListReducer = createReducer(initialState, reducer);

export default createReducer(initialState, reducer);
