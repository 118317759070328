import { AnyAction } from 'redux';
import { ScoreTableRow, ScoreDataGridRow, MultiScoreItem } from '.';
import { GridInitialState } from '@innovyze/stylovyze';

export interface UtilStoreState {
	riskScores: number[];
	riskEditScores: string[];
	riskUnitScores?: number[];
	riskScoreTable: ScoreTableRow[];
	riskScoreDataGrid: ScoreDataGridRow[];
	riskMultiScoreDataGrid: MultiScoreItem[];
	riskMultiScoreEditDataGrid: MultiScoreItem[];
	riskNullScore: number;
	riskStatus: string;
	riskRunning: boolean;
	riskLastRun: string;
	dataGridState: { [key: string]: GridInitialState | undefined };
}

export interface UtilReducer {
	[x: string]: (
		state: UtilStoreState,
		action: AnyAction,
	) => UtilStoreState | undefined;
}

export const utilStoreStateInitial = (): UtilStoreState => ({
	riskScores: [],
	riskEditScores: [],
	riskUnitScores: undefined,
	riskScoreTable: [],
	riskScoreDataGrid: [],
	riskMultiScoreDataGrid: [],
	riskMultiScoreEditDataGrid: [],
	riskNullScore: -1,
	riskStatus: '',
	riskRunning: false,
	riskLastRun: '',
	dataGridState: {},
});
