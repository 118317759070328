import { Grid } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-premium';
import React from 'react';
import { TextOverflowWrapper } from './ScoreDataGrid.styles';
import { RangeEditCell } from './RangeEditCell';
import { RangeDisplayCell, getRangeDisplayValue } from './RangeDisplayCell';

interface RangeCellProps {
	id: GridRowId;
	value: string;
	onRangeChange: (index: number, value: number, valueUnit?: number) => void;
	disabled: boolean;
	unit?: string;
	currency?: string;
	precision?: number;
}

export const RangeCell = ({
	id,
	value,
	onRangeChange,
	disabled,
	unit,
	currency,
	precision,
}: RangeCellProps) => {
	// Ignore the null value row
	if (value !== '--') {
		const values = value.split(' ');
		if (values[0] === '<') {
			return (
				<Grid container>
					<Grid item xs={4} alignSelf={'center'} />
					<Grid item xs={2} alignSelf={'center'} textAlign={'center'}>
						<TextOverflowWrapper title={values[0]}>
							{values[0]}
						</TextOverflowWrapper>
					</Grid>
					<Grid item xs={6}>
						<RangeEditCell
							id={id}
							value={values[1]}
							onRangeChange={onRangeChange}
							disabled={disabled}
							unit={unit}
							currency={currency}
							precision={precision}
						/>
					</Grid>
				</Grid>
			);
		} else if (values[0] === '') {
			return (
				<Grid container>
					<Grid item xs={4} alignSelf={'center'} />
					<Grid item xs={2} alignSelf={'center'} textAlign={'center'}>
						<TextOverflowWrapper title={values[1]}>
							{values[1]}
						</TextOverflowWrapper>
					</Grid>
					<Grid item xs={6}>
						<RangeEditCell
							id={id}
							value={values[2]}
							onRangeChange={onRangeChange}
							disabled={disabled}
							unit={unit}
							currency={currency}
							precision={precision}
						/>
					</Grid>
				</Grid>
			);
		} else if (values[0] === '>') {
			return (
				<Grid container>
					<Grid item xs={4} alignSelf={'center'} />
					<Grid item xs={2} alignSelf={'center'} textAlign={'center'}>
						<TextOverflowWrapper title={values[0]}>
							{values[0]}
						</TextOverflowWrapper>
					</Grid>
					<Grid item xs={6}>
						<RangeDisplayCell
							id={id}
							value={values[1]}
							unit={unit}
							currency={currency}
						/>
					</Grid>
				</Grid>
			);
		} else {
			return (
				<Grid container>
					<Grid item xs={4} alignSelf={'center'}>
						<RangeDisplayCell
							id={id}
							value={values[0]}
							unit={unit}
							currency={currency}
						/>
					</Grid>
					<Grid item xs={2} alignSelf={'center'} textAlign={'center'}>
						<TextOverflowWrapper title={values[0]}>
							{values[1]}
						</TextOverflowWrapper>
					</Grid>
					<Grid item xs={6}>
						<RangeEditCell
							id={id}
							value={values[2]}
							onRangeChange={onRangeChange}
							disabled={disabled}
							unit={unit}
							currency={currency}
							precision={precision}
						/>
					</Grid>
				</Grid>
			);
		}
	} else {
		return <>{value}</>;
	}
};

export const getRangeDisplayTitle = (
	value: string,
	unit?: string,
	currency?: string,
) => {
	// Ignore the null value row
	let result = value;

	if (value && value !== '--') {
		const values = value.split(' ');

		if (values[0] == '<') {
			result = `< ${getRangeDisplayValue(values[1], unit, currency)}`;
		} else if (values[0] == '>') {
			result = `> ${getRangeDisplayValue(values[1], unit, currency)}`;
		} else {
			result = `${getRangeDisplayValue(values[0], unit, currency)} ${
				values[1]
			} ${getRangeDisplayValue(values[2], unit, currency)}`;
		}
	}

	return result;
};
