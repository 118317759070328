import {
	CardContent,
	DialogProps,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
	DataGridButton,
	DataGridButtonType,
	DropDownButton,
	getSubscriptions,
	rem,
	Text,
	WarningDialog,
} from '@innovyze/stylovyze';
import { NewCardWrapper } from '../Utils/NewCard.style';
import {
	FlexStyledGrid,
	StyledPagination,
	StyledText,
} from '../Utils/cardList.style';
import { fullAccess } from '@innovyze/shared-utils';
import NewRiskIcon from '@Components/Utils/icons/NewRisk';
import FailureListCard from './FailureListCard.component';
import { useGlobalization } from '@Translations/useGlobalization';
import { FullPage } from '@Utils/styles';
import { NewFailure } from '@Components';
import {
	selectFailureConfigIsCreating,
	selectFailureIsDeleting,
	selectFailureRefreshList,
} from '@Selectors/failure.selectors';
import { useDispatch } from 'react-redux';
import { deleteFailure, setFailureRefreshList } from '@Actions';
import { getAssetCounts } from '@innovyze/lib_am_common/Actions';
import { ConfigPublishState, FailureConfigCard } from '@Types';
import { SystemTypes } from '@innovyze/inno-map';
import {
	selectFailureConfigsIsLoading,
	selectFailureConfigsIsWaiting,
	selectFailureConfigsPage,
	selectFailureConfigsPagination,
	selectFailureConfigsPerPage,
} from '@Selectors/failureConfigs.selectors';
import {
	changeFailureConfigsPage,
	changeFailureConfigsSlice,
	changeFailureConfigsSort,
} from '@Actions/failureConfigs.actions';

const FailureList = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);
	const riskPage = selectFailureConfigsPage();
	const pagination = selectFailureConfigsPagination();
	const isLoading = selectFailureConfigsIsLoading();
	const isWaiting = selectFailureConfigsIsWaiting();
	const isCreating = selectFailureConfigIsCreating();
	const isDeleting = selectFailureIsDeleting();
	const [configId, setConfigId] = useState('');
	const [openDelete, setOpenDelete] = useState(false);
	const [isBlockDeleteDialogOpen, setIsBlockDeleteDialogOpen] =
		useState(false);
	const [newFailure, setNewFailure] = useState(false);
	const refreshList = selectFailureRefreshList();

	const dispatchSliceFn = (slice: number) =>
		dispatch(changeFailureConfigsSlice(slice));
	const rowsPerPage = selectFailureConfigsPerPage(dispatchSliceFn);

	const sortOptions = [
		{ id: 'createdAt-D', caption: t('Date Created/Modified - Descending') },
		{ id: 'createdAt-A', caption: t('Date Created/Modified - Ascending') },
		{ id: 'name-D', caption: t('Name - Descending') },
		{ id: 'name-A', caption: t('Name - Ascending') },
		{ id: 'lastRun-D', caption: t('Last Run - Descending') },
		{ id: 'lastRun-A', caption: t('Last Run - Ascending') },
		{ id: 'runBy-D', caption: t('Run By - Descending') },
		{ id: 'runBy-A', caption: t('Run By - Ascending') },
	];
	const [selectedSort, setSelectedSort] = useState('createdAt-D');

	const options = sortOptions.map(idCaption => {
		return (
			<MenuItem value={idCaption.id} key={idCaption.id}>
				{idCaption.caption}
			</MenuItem>
		);
	});

	const onRefresh = () => {
		dispatch(changeFailureConfigsPage(riskPage.page));
	};

	useEffect(() => {
		if (refreshList) {
			onRefresh();
			dispatch(setFailureRefreshList(false));
		}
		if (pagination && pagination.sortKey) {
			setSelectedSort(
				`${pagination.sortKey}-${
					pagination.sortDescending ? 'D' : 'A'
				}`,
			);
		}
	}, []);

	const handleDeleteRisk = (id: string, publishState: ConfigPublishState) => {
		if (publishState === ConfigPublishState.unpublished) {
			setConfigId(id);
			setOpenDelete(true);
		} else {
			setIsBlockDeleteDialogOpen(true);
		}
	};

	const handleDeleteConfirmYes = () => {
		dispatch(
			deleteFailure({
				configId: configId,
				refresh: onRefresh,
			}),
		);
		setOpenDelete(false);
	};

	const handleDeleteConfirmNo = () => {
		setOpenDelete(false);
	};

	const onClickOpenCreateNewFailure = () => {
		if (!(isLoading || isWaiting || isDeleting || isCreating)) {
			setNewFailure(true);
		}
	};

	const onNewFailureClose = () => {
		setNewFailure(false);
	};

	const createFailureCard = () => {
		return (
			<NewCardWrapper>
				<CardContent style={{ height: '100%' }}>
					<div
						role="button"
						onClick={() => onClickOpenCreateNewFailure()}
						onKeyDown={() => onClickOpenCreateNewFailure()}
						tabIndex={0}
						className="create_card__icon"
						data-cy="new-failure">
						<NewRiskIcon />
						<div className="create_card__note">
							<Typography gutterBottom variant="subtitle1">
								{t('Create New Failure Definition')}
							</Typography>
						</div>
					</div>
				</CardContent>
			</NewCardWrapper>
		);
	};

	const sortChange = (event: SelectChangeEvent<typeof selectedSort>) => {
		const target = event?.target as HTMLInputElement;
		const sort = target.value.split('-');
		dispatch(
			changeFailureConfigsSort({
				key: sort[0],
				descending: sort[1] === 'D',
			}),
		);
		setSelectedSort(target.value);
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: openDelete,
		onClose: onCloseDialog,
	};

	const renderBlockRiskDeleteDialog = useCallback(() => {
		return (
			<WarningDialog
				open={isBlockDeleteDialogOpen}
				title={t('Failure Definition Cannot be Deleted')}
				content={t(
					'You cannot delete a Failure Definition that is published. Unpublish and try deleting again.',
				)}
				cancelText={t('Close')}
				onCancel={() => setIsBlockDeleteDialogOpen(false)}
			/>
		);
	}, [isBlockDeleteDialogOpen]);

	return (
		<FullPage
			className={
				isLoading || isWaiting || isDeleting || isCreating ? 'wait' : ''
			}>
			<WarningDialog
				dialogProps={dialogProps}
				data-cy="delete-dialog"
				open={openDelete}
				title={t('Delete Failure Definition?')}
				content={t(
					'Are you sure you want to delete this Failure Definition?',
				)}
				cancelText={t('Cancel')}
				cancelButtonProps={{
					disabled: isDeleting,
					dataCy: 'delete-cancel',
				}}
				confirmText={t('Delete')}
				confirmButtonProps={{
					disabled: isDeleting,
					dataCy: 'delete-confirm',
				}}
				onCancel={handleDeleteConfirmNo}
				onConfirm={handleDeleteConfirmYes}
			/>
			{renderBlockRiskDeleteDialog()}
			<NewFailure open={newFailure} onClose={onNewFailureClose} />
			<Grid container spacing={2} direction="row">
				<Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
					<FormControl fullWidth variant="standard">
						<InputLabel>{t('Sort By')}</InputLabel>
						<Select
							data-cy="sort-by"
							value={selectedSort}
							disabled={
								isLoading ||
								isWaiting ||
								isDeleting ||
								isCreating
							}
							name={t('Sort By')}
							onChange={sortChange}>
							{options}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={2}
					sm={6}
					md={8}
					lg={9}
					xl={10}
					container
					justifyContent="flex-end"
					data-cy="grid-actionbutton">
					<DataGridButton
						type={DataGridButtonType.REFRESH}
						disabled={isWaiting}
						data-cy="refreshButton"
						onClick={onRefresh}
					/>
				</Grid>
				{hasFullAccess ? (
					<Grid xs={12} sm={6} md={4} lg={3} xl={3} item>
						{createFailureCard()}
					</Grid>
				) : (
					<></>
				)}
				{riskPage?.cards.map((riskFailure: FailureConfigCard) => (
					<Grid
						xs={12}
						sm={6}
						md={4}
						lg={3}
						xl={3}
						item
						key={riskFailure?.configId}>
						<FailureListCard
							failureConfig={riskFailure}
							onDeleteFailure={handleDeleteRisk}
						/>
					</Grid>
				))}
				{!isWaiting ? (
					<Grid
						item
						container
						xs={12}
						style={{
							padding: `${rem(16)} ${rem(24)}`,
						}}>
						<Grid item container xs={6}>
							<Stack
								direction={'row'}
								spacing={'1rem'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<Text variant="text-large">
									{t('Failures per page:')}
								</Text>
								<DropDownButton
									arrow
									color="secondary"
									options={rowsPerPage.options}
									placement="bottom-start"
									variant="text"
									initialSelectedIndex={
										rowsPerPage.selectedIndex
									}
									fitButtonToContent
									fitMenuToContent
								/>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							{riskPage.pages > 1 ? (
								<FlexStyledGrid>
									<StyledPagination
										count={pagination.pages}
										page={pagination.page}
										onChange={(
											_e: unknown,
											page: number,
										) => {
											dispatch(
												changeFailureConfigsPage(page),
											);
											dispatch(
												getAssetCounts([
													SystemTypes.SanitarySewer,
													SystemTypes.WaterDistribution,
												]),
											);
										}}
										variant="outlined"
									/>
									<StyledText variant="caption-small">
										{t('{{from}}-{{to}} of {{total}}', {
											from:
												(pagination.page - 1) *
													pagination.slice +
												1,
											to:
												pagination.page ==
												pagination.pages
													? pagination.total
													: pagination.page *
													  pagination.slice,
											total: pagination.total,
										})}
									</StyledText>
								</FlexStyledGrid>
							) : (
								[]
							)}
						</Grid>
					</Grid>
				) : (
					[]
				)}
			</Grid>
		</FullPage>
	);
};
export default FailureList;
