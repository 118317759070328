import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { getFailureConfigurationsApi } from '@Services/Failure/Failure.services';
import { AxiosResponse, AxiosError } from 'axios';
import { t } from 'i18next';
import {
	changeFailureConfigsPage,
	changeFailureConfigsSlice,
	changeFailureConfigsSort,
	clearFailureConfigs,
	getFailureConfigs,
	getFailureConfigsRejected,
	getFailureConfigsResolved,
	reloadFailureConfigs,
	setFailureConfigsIsBusy,
} from '@Actions/failureConfigs.actions';
import { select } from 'redux-saga/effects';
import { selectorFailureConfigsPaginationQuery } from '@Selectors/failureConfigs.selectors';
import { ResolvedResponse } from '@innovyze/lib_am_common';

function* getFailureConfigsSaga() {
	try {
		yield put(setFailureConfigsIsBusy(true));

		const query: string = yield select(
			selectorFailureConfigsPaginationQuery,
		);

		const payload: AxiosResponse<ResolvedResponse> = yield retry(
			5,
			30000,
			getFailureConfigurationsApi,
			query,
		);

		yield put(
			getFailureConfigsResolved({
				pagination: payload.data.pagination,
				data: payload.data.data,
			}),
		);
		yield put(setFailureConfigsIsBusy(false));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getFailureConfigsRejected(rejection));
		yield put(setFailureConfigsIsBusy(false));
	}
}

function* watchGetFailureConfigs() {
	yield takeLatest(
		[getFailureConfigs, clearFailureConfigs, reloadFailureConfigs],
		getFailureConfigsSaga,
	);
}

function* watchChangePagination() {
	yield takeLatest(
		[
			changeFailureConfigsPage,
			changeFailureConfigsSlice,
			changeFailureConfigsSort,
		],
		getFailureConfigsSaga,
	);
}

const sagaArray = [watchGetFailureConfigs(), watchChangePagination()];

export default sagaArray;
