import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
	createStyles({
		header: {
			'& div > div > div > h3': {
				fontSize: '1.125rem !important',
				padding: '8px 24px 8px 5px',
				display: 'flex',
				height: '55px',
				color: '#007CA0',
			},
		},
		headerWait: {
			'& div > div > div > h3': {
				fontSize: '1.125rem !important',
				padding: '8px 24px 8px 5px',
				display: 'flex',
				height: '55px',
				color: '#007CA0',
			},
			cursor: 'wait !important',
		},
		map: {
			height: '710px',
			padding: '20px',
		},
		defectRoot: {
			'& .MuiChip-root': {
				background: '#F1F4F4',
			},
			'& .MuiChip-label': {
				fontSize: '12px',
			},
		},

		codeListTag: {
			background: '#F1F4F4',
		},
		codeListOption: {
			'&[aria-selected="true"]': {
				background: '#007CA0',
				color: 'white',
			},
		},
	}),
);
