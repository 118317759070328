import { NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { FailureConfigCard } from '@Types/failureConfig.types';
import { rowsPerPage } from '@innovyze/lib_am_common/Reducers';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const failureConfigsBaseSelector = createSelector(
	parseNamespace,
	state => state.failureConfigs,
);

export const selectorFailureConfigsPagination = createSelector(
	parseNamespace,
	state => state.failureConfigs.pagination,
);

export const selectorFailureConfigsPaginationQuery = createSelector(
	parseNamespace,
	state => state.failureConfigs.query,
);

export const failureConfigsSelector = createSelector(
	failureConfigsBaseSelector,
	baseState => baseState.cards as FailureConfigCard[],
);

const selectorFailureConfigsCanReload = createSelector(
	parseNamespace,
	state => state.failureConfigs.pagination.total > 0,
);
export const selectFailureConfigsCanReload = () =>
	useSelector(selectorFailureConfigsCanReload);

export const failureConfigsStatusSelector = createSelector(
	failureConfigsBaseSelector,
	baseState => baseState.status,
);

export const failureConfigsIsWaitingSelector = createSelector(
	failureConfigsBaseSelector,
	baseState => baseState.isWaiting,
);

const selectorFailureConfigsPage = () => {
	const pagination = selectorFailureConfigsPagination;
	const cards = failureConfigsSelector;

	return createSelector(pagination, cards, (pagination, cards) => ({
		total: pagination.total,
		pages: pagination.pages,
		page: pagination.page,
		cards: cards,
		sortKey: pagination.sortKey,
		sortDescending: pagination.sortDescending,
	}));
};

const selectorFailureConfigsSlice = createSelector(
	parseNamespace,
	state => state.failureConfigs.pagination.slice,
);

const selectorFailureConfigIsLoading = createSelector(
	parseNamespace,
	state => state.failureConfigs.isLoading,
);

const selectorFailureConfigIsWaiting = createSelector(
	parseNamespace,
	state => state.failureConfigs.isWaiting,
);

export const selectFailureConfigs = () => useSelector(failureConfigsSelector);
export const selectFailureConfigsStatus = () =>
	useSelector(failureConfigsStatusSelector);

export const selectorFailureConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) =>
	createSelector(selectorFailureConfigsSlice, slice =>
		rowsPerPage(slice || 10, dispatchSliceFn),
	);

export const selectFailureConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) => useSelector(selectorFailureConfigsPerPage(dispatchSliceFn));

export const selectFailureConfigsPage = () =>
	useSelector(selectorFailureConfigsPage());
export const selectFailureConfigsIsLoading = () =>
	useSelector(selectorFailureConfigIsLoading);
export const selectFailureConfigsIsWaiting = () =>
	useSelector(selectorFailureConfigIsWaiting);
export const selectFailureConfigsPagination = () =>
	useSelector(selectorFailureConfigsPagination);

export default selectFailureConfigs;
