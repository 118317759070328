import {
	createFailureConfig,
	setFailureConfigNameError,
} from '@Actions/failure.actions';
import { StylovyzeDialog, useIsFeatureEnabled } from '@innovyze/stylovyze';
import {
	DialogProps,
	FormControl,
	FormHelperText,
	Stack,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import {
	selectFailureConfigIsCreating,
	selectFailureConfigNameError,
} from '@Selectors/failure.selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfigPublishState, InspectionStandardType } from '@Types';
import { useGlobalization } from '@Translations/useGlobalization';
import { SystemTypes } from '@innovyze/inno-map';
import {
	AssetTypeManhole,
	AssetTypePipe,
	AssetTypeWDPipe,
} from '@innovyze/lib_am_common/Types';
import { selectRiskConfigsPage } from '@innovyze/lib_am_common/Selectors';

interface Props {
	open: boolean;
	onClose: () => void;
}

export const NewFailure = ({ open, onClose }: Props) => {
	const isWWManholeOn = useIsFeatureEnabled(
		'info-360-asset-deterioration-ww-manhole',
	);

	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const history = useHistory();
	const failurePage = selectRiskConfigsPage();

	const [newName, setNewName] = useState('');
	const [systemType, setSystemType] = useState('');
	const [newType, setNewType] = useState('');
	const [inspectionStandard, setInspectionStandard] = useState('');
	const [disableStandardTypes, setDisableStandardTypes] = useState(true);

	const configNameError = selectFailureConfigNameError();
	const isCreating = selectFailureConfigIsCreating();

	const handleCancelNewFailure = () => {
		onClose();
	};

	useEffect(() => {
		setNewName('');
		dispatch(setFailureConfigNameError(t('Name is required')));
		setNewType('');
		setSystemType('');
		setInspectionStandard('');
		setDisableStandardTypes(true);
	}, [open]);

	const assetTypeOptions = useMemo(() => {
		setNewType('');

		if (systemType === SystemTypes.SanitarySewer) {
			const items = [
				<MenuItem key={AssetTypePipe} value={AssetTypePipe}>
					{t('Pipe')}
				</MenuItem>,
			];

			if (isWWManholeOn) {
				items.push(
					<MenuItem key={AssetTypeManhole} value={AssetTypeManhole}>
						{t('Manhole')}
					</MenuItem>,
				);
			}

			return items;
		} else if (systemType === SystemTypes.WaterDistribution) {
			return [
				<MenuItem key={AssetTypeWDPipe} value={AssetTypeWDPipe}>
					{t('Pipe')}
				</MenuItem>,
			];
		} else {
			return [];
		}
	}, [systemType]);

	//TODO: We should we really get this from the overview call to only list the ones they have OR just expand to all inspection standards?
	const inspectionStandardOptions = useMemo(() => {
		setInspectionStandard('');

		if (systemType === SystemTypes.SanitarySewer) {
			if (newType === AssetTypePipe)
				return [
					<MenuItem
						key={InspectionStandardType.PACP}
						value={InspectionStandardType.PACP}>
						{t('PACP')}
					</MenuItem>,
					<MenuItem
						key={InspectionStandardType.WSA}
						value={InspectionStandardType.WSA}>
						{t('WSA05')}
					</MenuItem>,
				];
			else if (newType === AssetTypeManhole) {
				return [
					<MenuItem
						key={InspectionStandardType.MACP}
						value={InspectionStandardType.MACP}>
						{t('MACP')}
					</MenuItem>,
				];
			} else {
				return [];
			}
		} else {
			return [];
		}
	}, [newType]);

	const handleCreateNewFailure = () => {
		if (configNameError.length == 0) {
			dispatch(
				createFailureConfig({
					history: history,
					config: {
						name: newName.trim(),
						systemType,
						assetType: newType,
						publishState: ConfigPublishState.unpublished,
						inspectionStandard:
							inspectionStandard.length !== 0
								? (inspectionStandard as InspectionStandardType)
								: InspectionStandardType.PACP, // FOR SOME REASON THE CREATE NEEDS AN INSPECTION TYPE EVEN WHEN NOT AVAILABLE
					},
					page: failurePage.page,
				}),
			);
		}
	};

	const handlelNameChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		let error = '';
		if (event.target && event.target.value !== '') {
			setNewName(event.target.value);
			const rexp = new RegExp('^[a-zA-Z0-9 ]+$');
			const test = rexp.test(event.target.value);
			if (!test) {
				error = t('Only alphanumeric characters are allowed');
			}

			const rexp2 = new RegExp('^.{1,50}$');
			const test2 = rexp2.test(event.target.value);
			if (!test2) {
				error = t('Name cannot contain more than 50 characters');
			}
		} else {
			setNewName('');
			error = t('Name is required');
		}
		dispatch(setFailureConfigNameError(error));
	};

	const handleSystemTypeSelectChange = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		if (target != undefined) {
			setSystemType(target.value);
			setDisableStandardTypes(target.value !== SystemTypes.SanitarySewer);
		} else {
			setSystemType('');
			setDisableStandardTypes(true);
		}
	};

	const handleAssetTypeSelectChange = (
		event: SelectChangeEvent<typeof newType>,
	) => {
		const target = event?.target as HTMLInputElement;
		setNewType(target.value);
	};

	const handleInspectionStandardSelectChange = (
		event: SelectChangeEvent<typeof newType>,
	) => {
		const target = event?.target as HTMLInputElement;
		setInspectionStandard(target.value);
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: open,
		maxWidth: 'md',
		onClose: onCloseDialog,
	};

	const isCreateButtonDisabled = () =>
		isCreating ||
		configNameError.length !== 0 ||
		systemType.length === 0 ||
		newType.length === 0 ||
		(inspectionStandardOptions.length !== 0 &&
			inspectionStandard.length === 0);

	return (
		<StylovyzeDialog
			dialogProps={dialogProps}
			data-cy="new-dialog"
			title={t('New Failure Definition')}
			open={open}
			cancelText={t('Cancel')}
			cancelButtonProps={{
				disabled: isCreating,
				dataCy: 'create-cancel',
			}}
			onCancel={handleCancelNewFailure}
			confirmText={t('Create Failure Definition')}
			confirmButtonProps={{
				disabled: isCreateButtonDisabled(),
				dataCy: 'create-confirm',
			}}
			onConfirm={handleCreateNewFailure}>
			<Stack style={{ width: '400px' }}>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						error={configNameError.length !== 0}
						name="name"
						label={t('Enter Name')}
						variant="outlined"
						onChange={handlelNameChangeValue}
						helperText={
							configNameError.length !== 0 ? configNameError : ' '
						}
					/>
				</FormControl>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel error={systemType.length === 0}>
						{t('System Type')}
					</InputLabel>
					<Select
						name="systemType"
						error={systemType.length === 0}
						onChange={handleSystemTypeSelectChange}
						label={t('System Type')}>
						<MenuItem value={SystemTypes.SanitarySewer}>
							{t('Sanitary Sewer')}
						</MenuItem>
						<MenuItem value={SystemTypes.WaterDistribution}>
							{t('Water Distribution')}
						</MenuItem>
					</Select>
					<FormHelperText error>
						{systemType.length === 0
							? t('Select a System Type')
							: ' '}
					</FormHelperText>
				</FormControl>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel
						error={systemType.length !== 0 && newType.length === 0}>
						{t('Asset Type')}
					</InputLabel>
					<Select
						disabled={systemType.length === 0}
						data-cy="assetTypeField"
						name="assetType"
						value={newType}
						error={systemType.length !== 0 && newType.length === 0}
						onChange={handleAssetTypeSelectChange}
						label={t('Asset Type')}>
						{assetTypeOptions}
					</Select>
					<FormHelperText error>
						{systemType.length !== 0 && newType.length === 0
							? t('Select an asset type')
							: ' '}
					</FormHelperText>
				</FormControl>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel
						error={
							!disableStandardTypes &&
							inspectionStandard.length === 0
						}>
						{t('Inspection Standard')}
					</InputLabel>
					<Select
						disabled={disableStandardTypes || newType.length === 0}
						name="inspectionStandard"
						value={inspectionStandard}
						error={
							!disableStandardTypes &&
							inspectionStandard.length === 0
						}
						onChange={handleInspectionStandardSelectChange}
						label={t('Inspection Standard')}>
						{inspectionStandardOptions}
					</Select>
					<FormHelperText error>
						{!disableStandardTypes &&
						inspectionStandard.length === 0
							? t('Select an Inspection Standard')
							: ' '}
					</FormHelperText>
				</FormControl>
			</Stack>
		</StylovyzeDialog>
	);
};
