import {
	getComponentMetadataRejected,
	getComponentMetadataResolved,
	getComponentAssetMetadata,
	clearComponentMetadata,
	getComponentProximityMetadata,
	getComponentProximityAttrMetadata,
} from '@Actions';
import { createReducer } from '@reduxjs/toolkit';
import { ComponentField, ComponentMetadataStoreState } from '@Types';
import { sortFields } from '@Utils/sort';
import { AnyAction } from 'redux';

export const initState: ComponentMetadataStoreState = {
	isLoading: false,
	unique: undefined,
	precision: 0,
	range: undefined,
	unit: undefined,
};

interface ComponentReducer {
	[x: string]: (
		state: ComponentMetadataStoreState,
		action: AnyAction,
	) => ComponentMetadataStoreState | undefined;
}

export const reducer: ComponentReducer = {
	[clearComponentMetadata.toString()]: state => ({
		...state,
		unique: undefined,
		precision: 0,
		range: undefined,
		unit: undefined,
		currency: undefined,
		isLoading: false,
	}),
	[getComponentAssetMetadata.toString()]: state => ({
		...state,
		unique: undefined,
		precision: 0,
		range: undefined,
		unit: undefined,
		currency: undefined,
		isLoading: true,
	}),
	[getComponentProximityMetadata.toString()]: state => ({
		...state,
		unique: undefined,
		precision: 0,
		range: undefined,
		unit: undefined,
		currency: undefined,
		isLoading: true,
	}),
	[getComponentProximityAttrMetadata.toString()]: state => ({
		...state,
		unique: undefined,
		precision: 0,
		range: undefined,
		unit: undefined,
		currency: undefined,
		isLoading: true,
	}),
	[getComponentMetadataResolved.toString()]: (state, action) => {
		const payload = action.payload;
		if (action.payload.range) {
			const precision =
				action.payload.precision == undefined ||
				action.payload.precision == ''
					? 0
					: action.payload.precision;
			if (action.payload.range.max) {
				payload.range.max =
					Math.round(
						action.payload.range.max * Math.pow(10, precision),
					) / Math.pow(10, precision);
			}
			if (action.payload.range.min) {
				payload.range.min =
					Math.round(
						action.payload.range.min * Math.pow(10, precision),
					) / Math.pow(10, precision);
			}
		}
		return {
			...state,
			unique: payload.unique,
			precision: payload.precision,
			range: payload.range,
			unit: payload.unit,
			currency: payload.type == 'currency' ? payload.currency : undefined,
			isLoading: false,
		};
	},
	[getComponentMetadataRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
};

export const componentReducer = createReducer(initState, reducer);
