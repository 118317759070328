import {
	addModelSelected,
	changeModelPage,
	changeModelConfigPublishState,
	changeModelSearch,
	changeModelSlice,
	changeModelSort,
	clearModelConfig,
	clearModelSelected,
	createModelConfig,
	currentModelRow,
	deleteModel,
	duplicateModelConfig,
	getModelLog,
	getModelLogRejected,
	getModelLogResolved,
	getModelConfig,
	getModelConfigRejected,
	getModelConfigResolved,
	getModelDetails,
	getModelDetailsRejected,
	getModelDetailsResolved,
	removeModelSelected,
	renameModelConfigAction,
	saveModelConfigAction,
	setModelRefreshList,
	setModelCalcStatus,
	setModelConfigIsCreating,
	setModelConfigNameError,
	setModelDetailsIsBusy,
	setModelIsDeleting,
	setModelIsSaving,
	setModelSetupDirty,
	setModelRunCompleteReload,
	setModelSaveRun,
	setModelSelected,
	setModelTab,
	changeModelPagination,
	clearModelScanId,
} from '@Actions';
import { createReducer } from '@reduxjs/toolkit';
import { LogReply, ModelConfig, ModelStoreState } from '@Types';
import { AnyAction } from 'redux';
import * as AM_COMMON from '@innovyze/lib_am_common/Reducers';
import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';
import { ConfigPublishState } from '@Types/modelConfig.types';
import { Pagination } from '@innovyze/lib_am_common';

const configInitState: ModelConfig = {
	modelSetting: {
		publishState: ConfigPublishState.unpublished,
		LastRun: '',
		lastRunCompleted: '',
		configId: '',
		assetType: '',
		name: '',
	},
	components: [],
};

const TEMPState: ModelConfig = {
	modelSetting: {
		publishState: ConfigPublishState.unpublished,
		LastRun: '',
		lastRunCompleted: '',
		configId: 'ca85f58a-fed8-4cd0-844a-1013d9cbb863',
		assetType: 'wwPipe',
		name: 'TEMP CONFIG',
	},
	components: [],
};

export const initState: ModelStoreState = {
	modelConfig: configInitState,
	isLoading: false,
	tab: '',
	modelDetails: paginationInitialState,
	search: '',
	saveRun: false,
	isSetupDirty: false,
	runCompleteReload: false,
	isCreating: false,
	isSaving: false,
	isDeleting: false,
	refreshList: true,
	enablePreview: false,
	cancelPreview: false,
	isPreviewLoading: false,
	previewStatus: '',
	modelConfigNameError: '',
};

interface ModelReducer {
	[x: string]: (
		state: ModelStoreState,
		action: AnyAction,
	) => ModelStoreState | undefined;
}

export const reducer: ModelReducer = {
	[getModelConfig.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[getModelConfigResolved.toString()]: (state, action) => {
		return {
			...state,
			modelConfig: TEMPState, //action.payload,
			previewStatus: '',
			isPreviewOnLoad: false,
			isPreviewLoading: false,
			isLoading: false,
		};
	},
	[getModelConfigRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[clearModelConfig.toString()]: state => ({
		...state,
		modelConfig: configInitState,
		modelDetails: paginationInitialState,
		search: '',
		saveRun: false,
		isSetupDirty: false,
	}),
	[createModelConfig.toString()]: state => ({
		...state,
		isCreating: true,
	}),
	[duplicateModelConfig.toString()]: state => ({
		...state,
		isCreating: true,
	}),
	[changeModelConfigPublishState.toString()]: (state, action) => ({
		...state,
		modelConfig: {
			...state.modelConfig,
			modelSetting: {
				...state.modelConfig.modelSetting,
				publishState: action.payload.newPublishState,
			},
		},
	}),
	[renameModelConfigAction.toString()]: (state, action) => ({
		...state,
		...action.payload,
		isSaving: true,
	}),
	[saveModelConfigAction.toString()]: (state, action) => ({
		...state,
		modelConfig: { ...action.payload },
		editedCellsList: [],
		isSaving: true,
	}),
	[setModelTab.toString()]: (state, action) => ({
		...state,
		tab: action.payload,
	}),
	[getModelDetails.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateGet(state.modelDetails, action.payload),
	}),
	[getModelDetailsResolved.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateResolved(
			state.modelDetails,
			action.payload,
		),
	}),
	[getModelDetailsRejected.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateRejected(
			state.modelDetails,
			action.payload,
		),
	}),
	[clearModelScanId.toString()]: state => ({
		...state,
		modelDetails: { ...state.modelDetails, scanId: '' },
	}),
	[changeModelPage.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updatePage(state.modelDetails, action.payload),
	}),
	[changeModelSlice.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateSlice(state.modelDetails, action.payload),
	}),
	[changeModelPagination.toString()]: (state, action) => {
		const query: Pagination = AM_COMMON.makePaginationQuery({
			page: action.payload?.page,
			slice: action.payload?.slice,
			pages: state.modelDetails.pagination.pages,
			total: state.modelDetails.pagination.total,
			subtotal: state.modelDetails.pagination.subtotal,
			search: state.modelDetails.pagination.search,
			sortKey: state.modelDetails.pagination.sortKey,
			sortDescending: state.modelDetails.pagination.sortDescending,
		});
		const pagination = {
			...state.modelDetails.pagination,
			slice:
				action.payload?.slice ?? state.modelDetails?.pagination?.slice,
			page: action.payload?.page ?? state.modelDetails?.pagination?.page,
		};

		return {
			...state,
			modelDetails: {
				...state.modelDetails,
				isWaiting: true,
				status: AM_COMMON.statusLoading(),
				pagination,
				query,
			},
		};
	},
	[changeModelSearch.toString()]: (state, action) => ({
		...state,
		search: action.payload,
		modelDetails: AM_COMMON.updateSearch(
			state.modelDetails,
			action.payload,
		),
	}),
	[changeModelSort.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateSort(state.modelDetails, action.payload),
	}),
	[clearModelSelected.toString()]: state => ({
		...state,
		modelDetails: AM_COMMON.setSelected(state.modelDetails, []),
	}),
	[setModelSelected.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.setSelected(state.modelDetails, action.payload),
	}),
	[addModelSelected.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.addSelected(state.modelDetails, action.payload),
	}),
	[removeModelSelected.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.removeSelected(
			state.modelDetails,
			action.payload,
		),
	}),
	[setModelDetailsIsBusy.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.updateIsBusy(
			state.modelDetails,
			action.payload,
		),
	}),
	[currentModelRow.toString()]: (state, action) => ({
		...state,
		modelDetails: AM_COMMON.setCurrentDbId(
			state.modelDetails,
			action.payload,
		),
	}),
	[setModelSaveRun.toString()]: (state, action) => ({
		...state,
		saveRun: action.payload,
	}),
	[setModelCalcStatus.toString()]: (state, action) => ({
		...state,
		calcStatus: action.payload,
	}),
	[getModelLog.toString()]: state => ({
		...state,
		log: undefined,
		isLoading: true,
	}),
	[getModelLogResolved.toString()]: (state, action) => {
		const log: LogReply = action.payload;
		if (log.startTime && log.startTime != '')
			log.startTime = new Date(Date.parse(log.startTime)).toISOString();
		if (log.endTime && log.endTime != '')
			log.endTime = new Date(Date.parse(log.endTime)).toISOString();
		return {
			...state,
			log: log,
			isLoading: false,
			modelConfig: {
				...state?.modelConfig,
				modelSetting: {
					...state?.modelConfig?.modelSetting,
					LastRun: log.startTime,
					lastRunCompleted:
						// Update the lastRunCompleted time only if we completed the calc otherwise just keep it
						log.calcStatus === 'risk-calc-complete'
							? log.startTime
							: state.modelConfig.modelSetting.lastRunCompleted,
				},
			},
		};
	},
	[getModelLogRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[setModelSetupDirty.toString()]: (state, action) => ({
		...state,
		isSetupDirty: action.payload,
		enablePreview: action.payload,
	}),
	[setModelRunCompleteReload.toString()]: (state, action) => ({
		...state,
		runCompleteReload: action.payload,
	}),
	[setModelRefreshList.toString()]: (state, action) => ({
		...state,
		refreshList: action.payload,
	}),
	[setModelConfigNameError.toString()]: (state, action) => ({
		...state,
		modelConfigNameError: action.payload,
	}),
	[setModelConfigIsCreating.toString()]: (state, action) => ({
		...state,
		isCreating: action.payload,
	}),
	[setModelIsSaving.toString()]: (state, action) => ({
		...state,
		isSaving: action.payload,
	}),
	[deleteModel.toString()]: state => ({
		...state,
		isDeleting: true,
	}),
	[setModelIsDeleting.toString()]: (state, action) => ({
		...state,
		isDeleting: action.payload,
	}),
};

export const modelReducer = createReducer(initState, reducer);
