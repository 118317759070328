import React, { useMemo } from 'react';
import { DataGrid, useUnits } from '@innovyze/stylovyze';
import { Component, ComponentType, getTypeDisplay } from '@Types';
import { tableColumns } from './TabelColumns';
import { useGlobalization } from '@Translations/useGlobalization';
import { TableToolbar } from './TableToolbar';

interface ParameterDetailsProps {
	groups: Component[];
}

interface ParameterDetailsRow {
	id: number;
	name: string;
	type: string;
	dataSource: string;
	field: string;
	distance: string;
}

const ParameterDetails = ({ groups }: ParameterDetailsProps) => {
	const { t } = useGlobalization();
	const { getSystemValueFormatted } = useUnits();

	const getParamDetails = (
		index: number,
		parameter: Component,
	): ParameterDetailsRow => {
		switch (parameter.type) {
			case ComponentType.SpatialProximity: {
				return {
					id: index,
					name: parameter.name,
					type: getTypeDisplay(parameter.type as ComponentType, t),
					dataSource: parameter.layer ?? '',
					field: parameter.fieldAlias ?? '',
					distance: '',
				};
			}

			case ComponentType.SpatialAttribute: {
				let distance = '';
				if (parameter.maxDistance !== undefined) {
					if (parameter.unitMetadata) {
						distance =
							getSystemValueFormatted(
								`${parameter.maxDistance}m`,
							) ?? '';
					} else {
						distance = parameter.maxDistance.toString();
					}
				}

				return {
					id: index,
					name: parameter.name,
					type: getTypeDisplay(parameter.type as ComponentType, t),
					dataSource: parameter.layer ?? '',
					field: parameter.field ?? '',
					distance: distance,
				};
			}
			default: {
				return {
					id: index,
					name: parameter.name,
					type: getTypeDisplay(parameter.type as ComponentType, t),
					dataSource: '',
					field: parameter.fieldAlias ?? '',
					distance: '',
				};
			}
		}
	};

	const rows = useMemo((): ParameterDetailsRow[] => {
		const rows: ParameterDetailsRow[] = Array<ParameterDetailsRow>();

		if (groups.length > 1 && groups[0].name !== '') {
			rows.push(getParamDetails(1, groups[0]));
		}
		if (groups.length > 2 && groups[1].name !== '') {
			rows.push(getParamDetails(2, groups[1]));
		}

		return rows;
	}, [groups]);

	if (groups.length > 2 && groups[0].metadata && groups[1].metadata) {
		return (
			<DataGrid
				experimentalFeatures={{ columnGrouping: true }}
				disableColumnMenu
				disableColumnReorder
				disableRowSelectionOnClick
				columns={tableColumns()}
				rows={rows}
				slots={{
					toolbar: TableToolbar,
				}}
			/>
		);
	} else {
		return <></>;
	}
};

export default ParameterDetails;
