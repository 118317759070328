import { createStore, applyMiddleware, Store, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '@Reducers';
import { moduleNamespace } from './config.store';
import { reducers as sharedReducers } from '@innovyze/stylovyze';
import libAmCommon from '@innovyze/lib_am_common/Reducers';
import libAmCommonSaga from '@innovyze/lib_am_common/Sagas';
import rootSaga from '../sagas';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import {
	moduleNamespace as mapUiNamespace,
	reducers as mapUiReducers,
	sagas as mapUiSagas,
} from '@innovyze/map-components';

const sagaMiddleware = createSagaMiddleware();

declare global {
	interface Window {
		store: Store;
		Cypress: object;
	}
}

const store: Store = createStore(
	combineReducers({
		[moduleNamespace]: reducers,
		[mapUiNamespace]: mapUiReducers,
		...sharedReducers,
		libAmCommon,
	}),
	composeWithDevTools(applyMiddleware(sagaMiddleware)),
);
const masterRootSaga = function* () {
	yield all([rootSaga(), libAmCommonSaga(), mapUiSagas()]);
};
sagaMiddleware.run(masterRootSaga);

if (window.Cypress) {
	window.store = window.store || {};
	window.store = store;
}

export default store;
