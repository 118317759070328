import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { NamespacedStoreState } from '@Types/store.types';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const ComponentStoreSelector = createSelector(
	parseNamespace,
	state => state?.ComponentStore,
);

const ComponentRangeMetadataStoreSelector = createSelector(
	ComponentStoreSelector,
	store => store,
);

export const useComponentMetadataStore = () =>
	useSelector(ComponentRangeMetadataStoreSelector);
