import {
	renameRiskCount,
	getRiskCountResolved,
	setRiskCountIsLoading,
	setActivitiesDataIsLoading,
	getActivitesDataResolved,
	getActivitesDataRejected,
	getProximityAttributeFieldsResolved,
	getAttributeFields,
	getAttributeFieldsResolved,
	getAttributeFieldsRejected,
} from '@Actions';
import { createReducer } from '@reduxjs/toolkit';
import { ComponentCountReply, ComponentField, RiskStoreState } from '@Types';
import { sortFields } from '@Utils/sort';
import { AnyAction } from 'redux';

export const initState: RiskStoreState = {
	isCountLoading: [],
	riskCount: {},
	isActivitiesDataLoading: false,
	activitiesData: undefined,
	spatialProximityAttr: [],
	componentFields: [],
	isLoadingFields: false,
};

interface RiskReducer {
	[x: string]: (
		state: RiskStoreState,
		action: AnyAction,
	) => RiskStoreState | undefined;
}

export interface RiskCountChange {
	key: string;
	values?: ComponentCountReply[];
}

export interface RiskCountRename {
	existingKey: string;
	newKey: string;
}

export interface IsCountLoadingChange {
	key: string;
	loading: boolean;
}

const updateRiskCount = (state: RiskStoreState, action: RiskCountChange) => {
	if (action.values || state.riskCount[action.key])
		state.riskCount[action.key] = action.values;

	return state;
};

const copyRiskCount = (state: RiskStoreState, action: RiskCountRename) => {
	if (state.riskCount[action.existingKey]) {
		state.riskCount[action.newKey] = state.riskCount[action.existingKey];
		state.riskCount[action.existingKey] = undefined;
	}
	return state;
};

const updateIsCountLoading = (
	state: RiskStoreState,
	action: IsCountLoadingChange,
) => {
	if (action.loading && state.isCountLoading.indexOf(action.key) === -1)
		state.isCountLoading.push(action.key);
	else if (!action.loading && state.isCountLoading.indexOf(action.key) !== -1)
		state.isCountLoading = state.isCountLoading.filter(
			value => value !== action.key,
		);
	return state;
};

export const reducer: RiskReducer = {
	[setRiskCountIsLoading.toString()]: (state, action) =>
		updateIsCountLoading(state, action.payload),
	[getRiskCountResolved.toString()]: (state, action) =>
		updateRiskCount(state, action.payload),
	[renameRiskCount.toString()]: (state, action) =>
		copyRiskCount(state, action.payload),
	[setActivitiesDataIsLoading.toString()]: (state, action) => ({
		...state,
		isActivitiesDataLoading: action.payload,
	}),
	[getActivitesDataResolved.toString()]: (state, action) => ({
		...state,
		activitiesData: action.payload,
	}),
	[getActivitesDataRejected.toString()]: state => ({
		...state,
		activitiesData: undefined,
	}),
	[getProximityAttributeFieldsResolved.toString()]: (state, action) => ({
		...state,
		spatialProximityAttr: action.payload,
	}),
	[getAttributeFields.toString()]: state => ({
		...state,
		componentFields: [],
		isLoadingFields: true,
	}),
	[getAttributeFieldsResolved.toString()]: (state, action) => {
		const fields: ComponentField[] = action.payload;
		return {
			...state,
			componentFields: sortFields(fields),
			isLoadingFields: false,
		};
	},
	[getAttributeFieldsRejected.toString()]: state => ({
		...state,
		isLoadingFields: false,
	}),
};

export const riskReducer = createReducer(initState, reducer);
