import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AssetDetails as AssetDetailsComponent } from '@innovyze/lib_am_common/Components';
import {
	getAssetPipe,
	getInspectionAttributes,
	getAssetDetail,
} from '@innovyze/lib_am_common/Actions';
import { useDispatch } from 'react-redux';
import { getSubscriptions, useTzDateTime } from '@innovyze/stylovyze';
import { InspectionStandard } from '@innovyze/lib_am_common/types/inspection.types';
import { useGlobalization } from '@Translations/useGlobalization';
import { fullAccess } from '@innovyze/shared-utils';
import { SystemTypes } from '@innovyze/inno-map';
import { selectModelSetting } from '@Selectors/model.selectors';

const AssetDetails = () => {
	const { t } = useGlobalization();
	const params: { assetId: string } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const modelSetting = selectModelSetting();
	const assetType = modelSetting.assetType;

	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);

	const [systemType, setSystemType] = useState<string>(
		SystemTypes.SanitarySewer,
	);

	useEffect(() => {
		const systemType = assetType.startsWith('ww')
			? SystemTypes.SanitarySewer
			: SystemTypes.WaterDistribution;

		setSystemType(systemType);

		dispatch(
			getAssetDetail({
				assetId: params.assetId,
				assetType: assetType,
				formatDateUTC: formatDateUTC,
				systemType: systemType,
			}),
		);
		dispatch(
			getAssetPipe({
				assetId: params.assetId,
				assetType: assetType,
				formatDateUTC: formatDateUTC,
				systemType: systemType,
			}),
		);
		dispatch(
			getInspectionAttributes({
				assetId: params.assetId,
				assetType: assetType,
			}),
		);
	}, []);

	const back = () => {
		history.goBack();
	};

	const viewInspection = (
		videoURL: string,
		inspectionId: string,
		standard: InspectionStandard,
	) => {
		history.push(`../riskInspectionDetails/${standard}/${inspectionId}`);
	};

	const actions = {
		onInspectionDetailClicked: viewInspection,
	};

	return (
		<AssetDetailsComponent
			assetId={params.assetId}
			back={{
				action: back,
				label: t('Back to Results'),
			}}
			assetType={assetType}
			actions={actions}
			adminFullAccess={hasFullAccess}
			systemType={systemType}
		/>
	);
};

export default AssetDetails;
