import React, { ChangeEvent, useMemo } from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { StyledGrid } from '@Utils/styles';
import {
	DropDownButton,
	ZIndex,
	Text,
	InputCheckbox,
	InputAutocompleteMulti,
	StylovyzeFormV2,
} from '@innovyze/stylovyze';
import { Button, Checkbox, Grid, Stack } from '@mui/material';
import { ScoreDataGridRow } from '@Types/scoreDataGridType';

export const ClassesButton = (
	isLoading: boolean,
	onSetRangeClasses: (classes: number) => void,
	rangeClasses: number,
) => {
	const { t } = useGlobalization();

	const classOptions = () => {
		const options = [];
		for (let i = 1; i <= 10; ++i) {
			options.push({
				onClick: () => {
					onSetRangeClasses(i);
				},
				name: t('{{classes}} Classes', { classes: i + 2 }),
			});
		}
		return options;
	};

	return (
		<DropDownButton
			key={Date.now()}
			options={classOptions()}
			initialSelectedIndex={rangeClasses - 1}
			color="primary"
			variant="outlined"
			fitMenuToContent
			fitButtonToContent
			disabled={isLoading}
			popupZIndex={ZIndex.DropDownButtonMenu}
		/>
	);
};

interface TableToolbarProps {
	isLoading: boolean;
	isUnique: boolean;
	hideCounts: boolean;
	onSetRangeClasses: (classes: number) => void;
	onRecalculateCount: () => void;
	rangeClasses: number;
	recalculateOnly: boolean;
	groupRows: ScoreDataGridRow[];
	onUnqueGroups: (groups: boolean) => void;
}

export const TableToolbar = ({
	isLoading,
	isUnique,
	hideCounts,
	onSetRangeClasses,
	onRecalculateCount,
	rangeClasses,
	recalculateOnly,
}: TableToolbarProps) => {
	const { t } = useGlobalization();

	const heading = t('Group Values');
	let subHeading = '';
	if (isUnique) {
		subHeading = t(
			'These are the unique values that will be used for the cohort.',
		);
	} else {
		subHeading = t(
			'These are the ranges that will be used for the cohort. You can edit the number of classes shown and the values in the table.',
		);
	}

	return (
		<StyledGrid
			container
			style={{
				width: '100%',
				marginTop: '1rem',
				marginBottom: '1rem',
			}}>
			<Grid item xs={6}>
				<Text variant="heading-large-strong">{heading}</Text>
				<Text variant="caption-medium">{subHeading}</Text>
			</Grid>
			<Grid item xs={6} display="flex" justifyContent="flex-end">
				<Stack direction={'row'} spacing={'20px'}>
					{hideCounts ? (
						[]
					) : (
						<div>
							<Button
								disabled={isLoading}
								variant="outlined"
								onClick={() => {
									onRecalculateCount();
								}}>
								{t('Recalculate Asset Count')}
							</Button>
						</div>
					)}
					{!recalculateOnly &&
						!isUnique &&
						ClassesButton(
							isLoading,
							onSetRangeClasses,
							rangeClasses,
						)}
				</Stack>
			</Grid>
		</StyledGrid>
	);
};

export const TableToolbarUnique = ({
	isLoading,
	groupRows,
	onRecalculateCount,
	onUnqueGroups,
}: TableToolbarProps) => {
	const { t } = useGlobalization();
	const heading = t('Group Values');
	const subHeading = t(
		'These are the unique values that will be used for the cohort.',
	);

	const groupOptions = useMemo(() => {
		return groupRows
			.filter(value => {
				return value.value !== '--';
			})
			.map(row => {
				return { key: row.id.toString(), value: row.value };
			});
	}, [groupRows]);

	return (
		<StyledGrid
			container
			style={{
				width: '100%',
				marginTop: '1rem',
				marginBottom: '1rem',
			}}>
			<Grid item xs={8}>
				<Text variant="heading-large-strong">{heading}</Text>
				<Text variant="caption-medium">{subHeading}</Text>
			</Grid>
			<Grid item xs={4} display="flex" justifyContent="flex-end">
				<div>
					<Button
						disabled={isLoading}
						variant="outlined"
						onClick={() => {
							onRecalculateCount();
						}}>
						{t('Recalculate Asset Count')}
					</Button>
				</div>
			</Grid>
			<Grid
				item
				container
				xs={12}
				style={{
					alignItems: 'center',
				}}>
				<StylovyzeFormV2
					initialValues={{
						groupUnique: false,
						group: [],
					}}
					onSubmit={values => console.log(values)}>
					{({ values, submitForm }) => {
						return (
							<>
								<Grid item container xs={2}>
									<InputCheckbox
										label={t('Group unique values')}
										fieldAttrs={{ name: 'groupUnique' }}
										checkbox={{}}
										onChange={event =>
											onUnqueGroups(event.target.checked)
										}
									/>
								</Grid>
								<Grid item container xs={2}>
									<InputAutocompleteMulti
										autocompleteProps={{
											disabled: !values.groupUnique,
										}}
										fieldAttrs={{
											name: 'group',
										}}
										options={groupOptions}
										label={'Value'}
									/>
								</Grid>
								<Grid item container xs={2}>
									<Button
										disabled={values.group.length === 0}
										variant="contained"
										color="primary"
										style={{
											height: '36px',
											marginLeft: '12px',
										}}
										onClick={submitForm}>
										Add Row
									</Button>
								</Grid>
							</>
						);
					}}
				</StylovyzeFormV2>
			</Grid>
		</StyledGrid>
	);
};
