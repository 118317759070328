import riskApi from '@Apis/risks.api';
import { ComponentField, ComponentMetadata } from '@Types/component.types';

const getRiskCountApi = (
	assetType: string,
	fieldType: string,
	metadata: ComponentMetadata[],
	metadataType: string,
	additionalParams?: Record<string, string | number>,
) => {
	const params = new URLSearchParams({
		assetType,
		fieldType,
		...additionalParams,
	});
	const address = `/risk/count?${params.toString()}`;

	return riskApi.post(address, {
		metadata,
		metadataType,
	});
};

export const getRiskCountAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	fieldName: string,
) => {
	return getRiskCountApi(assetType, 'attribute', metadata, metadataType, {
		fieldName,
	});
};

export const getRiskCountObservationApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	fieldName: string,
) => {
	fieldName = fieldName === 'Code' ? 'PACP_CODE' : fieldName;
	return getRiskCountApi(assetType, 'observation', metadata, metadataType, {
		fieldName,
	});
};

export const getRiskCountProximityApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
) => {
	return getRiskCountApi(assetType, 'proximity', metadata, metadataType, {
		layerName,
	});
};

export const getRiskCountProximityAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
	fieldName: string,
	valueType: string,
	distance: number,
) => {
	return getRiskCountApi(
		assetType,
		'proximityAttribute',
		metadata,
		metadataType,
		{
			layerName,
			fieldName,
			maxDistance: distance,
		},
	);
};

export const getAttributeFieldsApi = (assetType: string) => {
	const address = `/risk/fields?assetType=${assetType}&fieldType=lof`;
	return riskApi.get<ComponentField[], string>(address);
};

export const getComponentProximityMetadataApi = (assetType: string) => {
	const address = `/risk/fields?assetType=${assetType}&fieldType=}&fieldType=${encodeURIComponent(
		'cof/proximity',
	)}`;
	return riskApi.get<ComponentMetadata[], string>(address);
};

export const getComponentAssetMetadataApi = (
	assetType: string,
	param: string,
) => {
	const address = `/lof/field/score?assetType=${assetType}&fieldName=${param}`;
	return riskApi.get<ComponentMetadata[], string>(address);
};

export const getComponentProximityAttrMetadataApi = (
	assetType: string,
	fieldName: string,
	layerName: string,
) => {
	const address = `/lof/field/score?assetType=${assetType}&fieldName=${fieldName}&layerName=${layerName}`;
	return riskApi.get<ComponentMetadata[], string>(address);
};

export const getActivitiesDataApi = (
	assetType: string,
	uploadType: 'task' | 'event',
) => {
	return riskApi.get(
		`/assets/activities?assetType=${assetType}&uploadType=${uploadType}`,
	);
};

export const getProximityAttributeFieldsApi = (assetType: string) => {
	const address = `/risk/fields?assetType=${assetType}&fieldType=proximityAttribute`;
	return riskApi.get<ComponentField[], string>(address);
};
