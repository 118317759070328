import {
	setModelNullScore,
	setModelScores,
	setModelEditScores,
	setModelScoreTable,
	setModelUnitScores,
	setModelStatus,
	setModelRunning,
	setModelLastRun,
	setModelScoreDataGrid,
	updateModelScoreDataGrid,
	updateModelValuesDataGrid,
	setModelMultiScoreDataGrid,
	updateModelMultiScoreDataGridRow,
	setDataGridState,
} from '@Actions';
import { GridInitialState } from '@innovyze/stylovyze';

import { createReducer } from '@reduxjs/toolkit';
import { MultiScoreItem } from '@Types/scoreDataGridType';
import {
	UtilReducer,
	UtilStoreState,
	utilStoreStateInitial,
} from '@Types/utils.types';

export interface ModelScoreChange {
	index: number;
	score: number;
}

export interface ModelValueChange {
	index: number;
	value: string;
}

const updateModelScore = (state: UtilStoreState, action: ModelScoreChange) => {
	const rows = state.riskScoreDataGrid;

	state.riskScoreDataGrid = rows.map(row => {
		if (row.id == action.index) return { ...row, score: action.score };
		else return row;
	});
	return state;
};

const updateModelMultiScoreRow = (
	state: UtilStoreState,
	action: MultiScoreItem,
) => {
	const rows =
		state.riskMultiScoreEditDataGrid.length == 0
			? state.riskMultiScoreDataGrid
			: state.riskMultiScoreEditDataGrid;

	const id = action['id'];
	state.riskMultiScoreEditDataGrid = rows.map(row => {
		if (row['id'] == id) return action;
		else return row;
	});
	return state;
};

const updateModelValues = (
	state: UtilStoreState,
	action: ModelValueChange[],
) => {
	const rows = state.riskScoreDataGrid;

	state.riskScoreDataGrid = rows.map(row => {
		const update = action.find(item => item.index == row.id);
		if (update) return { ...row, value: update.value };
		else return row;
	});
	return state;
};

export interface DataGridStateChange {
	key: string;
	initialState?: GridInitialState;
}

const updateInitialState = (
	state: UtilStoreState,
	action: DataGridStateChange,
) => {
	state.dataGridState[action.key] = action.initialState;
	return state;
};

const reducer: UtilReducer = {
	[setModelScores.toString()]: (state, action) => ({
		...state,
		riskScores: action.payload,
	}),
	[setModelEditScores.toString()]: (state, action) => ({
		...state,
		riskEditScores: action.payload,
	}),
	[setModelUnitScores.toString()]: (state, action) => ({
		...state,
		riskUnitScores: action.payload,
	}),
	[setModelScoreTable.toString()]: (state, action) => ({
		...state,
		riskScoreTable: action.payload,
	}),
	[setModelScoreDataGrid.toString()]: (state, action) => ({
		...state,
		riskScoreDataGrid: action.payload,
	}),
	[setModelMultiScoreDataGrid.toString()]: (state, action) => ({
		...state,
		riskMultiScoreDataGrid: action.payload,
		riskMultiScoreEditDataGrid: [],
	}),
	[setModelNullScore.toString()]: (state, action) => ({
		...state,
		riskNullScore: action.payload,
	}),
	[setModelStatus.toString()]: (state, action) => ({
		...state,
		riskStatus: action.payload,
	}),
	[setModelRunning.toString()]: (state, action) => ({
		...state,
		riskRunning: action.payload,
	}),
	[setModelLastRun.toString()]: (state, action) => ({
		...state,
		riskLastRun: action.payload,
	}),
	[updateModelScoreDataGrid.toString()]: (state, action) =>
		updateModelScore(state, action.payload),
	[updateModelMultiScoreDataGridRow.toString()]: (state, action) =>
		updateModelMultiScoreRow(state, action.payload),
	[updateModelValuesDataGrid.toString()]: (state, action) =>
		updateModelValues(state, action.payload),
	[setDataGridState.toString()]: (state, action) =>
		updateInitialState(state, action.payload),
};

export const utilReducer = createReducer(utilStoreStateInitial(), reducer);
