import React, { useState } from 'react';
import {
	SecondaryNav,
	CancellableEvent,
	WarningDialog,
} from '@innovyze/stylovyze';
import { Category, Component, MultiScoreItem } from '@Types';
import { FullPage } from '@Utils/styles';
import { useGlobalization } from '@Translations/useGlobalization';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/system';
import MultiScoreDataGrid from './MultiScoreDataGrid/MultiScoreDataGrid';
import ParameterDetails from './ParameterDetails/ParameterDetails';
import { EditParameter } from './EditParameter';
import CohortDataGrid from './CohortDataGrid/CohortDataGrid';

interface Props {
	isNew: boolean;
	assetType: string;
	countKey: string;
	groups: Component[];
	onChange?: (
		changed: boolean,
		clearMatrix: boolean,
		nextTab: boolean,
	) => void;
}

export const EditModel = ({ isNew, assetType, countKey, groups }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const [modelChanged, setModelChanged] = useState<boolean>(false);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [moveToIndex, setMoveToIndex] = useState<number | undefined>(
		undefined,
	);

	const onParameterChanged = (
		changed: boolean,
		clearMatrix: boolean,
		nextTab: boolean,
	) => {
		setModelChanged(changed);
		// if (clearMatrix) setComponentMatrix(undefined);
		if (nextTab) setCurrentIndex(currentIndex + 1);
	};

	const parameter1 = () => {
		if (groups.length > 0 && groups[0]) {
			return (
				<Stack paddingTop={'20px'}>
					<EditParameter
						assetType={assetType}
						isNew={isNew}
						key={'param1'}
						countKey={`${countKey}-param1`}
						component={groups[0]}
						parameterNumber={1}
						onChange={onParameterChanged}
					/>
				</Stack>
			);
		}
	};

	const parameter2 = () => {
		if (groups.length > 1 && groups[1]) {
			return (
				<Stack paddingTop={'20px'}>
					<EditParameter
						assetType={assetType}
						isNew={isNew}
						key={'param2'}
						countKey={`${countKey}-param2`}
						component={groups[1]}
						parameterNumber={2}
						onChange={onParameterChanged}
					/>
				</Stack>
			);
		}
	};

	const cohort = () => {
		return (
			<Stack paddingTop={'20px'} spacing={'20px'}>
				<ParameterDetails groups={groups} />
				<CohortDataGrid
					isLoading={false}
					isLoadingCount={false}
					countKey={countKey}
					dataGridKey={countKey}
					groups={groups}
				/>
			</Stack>
		);
	};

	const beforeChangeCurrentStep = (event: CancellableEvent) => {
		if (modelChanged) {
			setMoveToIndex(event.toIndex);
			return { ...event, cancelled: true };
		} else {
			setCurrentIndex(event.toIndex);
			return { ...event, cancelled: false };
		}
	};

	const onKeepChanges = () => {
		setMoveToIndex(undefined);
	};

	const onLoseChanges = () => {
		if (moveToIndex !== undefined) {
			setCurrentIndex(moveToIndex);
		}
		setMoveToIndex(undefined);
		setModelChanged(false);
		// dispatch(
		// 	getRiskCountResolved({
		// 		key: changedRangesKey,
		// 		values: undefined,
		// 	}),
		// );
	};

	return (
		// <FullPageWrapper
		// 	key={countKey}
		// 	title={title}
		// 	applyPadding={parameterNumber === undefined}
		// 	headerUnderline={parameterNumber === undefined}
		// 	className={
		// 		metadataStore.isLoading ||
		// 		fetchingMetadata ||
		// 		isRiskCountLoading
		// 			? classes.headerWait
		// 			: classes.header
		// 	}
		// 	secondary={secondary()}>
		<FullPage>
			<SecondaryNav
				itemIndex={currentIndex}
				beforeItemIndexChanged={beforeChangeCurrentStep}
				items={[
					{
						title: t('Group 1'),
						content: parameter1(),
					},
					{
						title: t('Group 2'),
						content: parameter2(),
					},
					{
						title: t('Cohort'),
						content: cohort(),
					},
				]}
				// actionButton={{
				// 	title: t('Save'),
				// 	onClick: onUpdateCreate,
				// 	enabled: validModel,
				// }}
			/>
			<WarningDialog
				title={t('Lose Changes')}
				open={moveToIndex !== undefined}
				onCancel={onKeepChanges}
				onConfirm={onLoseChanges}
				confirmText={t('OK')}
				cancelText={t('Cancel')}>
				{t('This will lose any changes made.')}
			</WarningDialog>
		</FullPage>
		// </FullPageWrapper>
	);
};
