import { LifeExpectancy } from '@Components/Charts/LifeExpectancy';
import { InfoCard, useGlobalization } from '@innovyze/stylovyze';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox } from '@mui/icons-material';

export const CohortPanel = () => {
	const { t } = useGlobalization();

	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) =>
		(event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	return (
		<Grid container>
			<Grid xs={8} padding={'1rem'}>
				<InfoCard title={t('Cohort Life Expectancy')}>
					<LifeExpectancy />
				</InfoCard>
			</Grid>
			<Grid xs={4} padding={'1rem'}>
				<InfoCard title={t('Curve Configuration')}>
					<Accordion
						disableGutters
						expanded={expanded === 'panel1'}
						onChange={handleChange('panel1')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header">
							<CheckBox></CheckBox>
							<div> Industry Standard</div>
						</AccordionSummary>
						<AccordionDetails>
							<div>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Suspendisse malesuada lacus ex,
								sit amet blandit leo lobortis eget. Lorem ipsum
								dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit
								leo lobortis eget.
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion
						disableGutters
						expanded={expanded === 'panel2'}
						onChange={handleChange('panel2')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header">
							<CheckBox></CheckBox>
							<div> Failure Definition Name</div>
						</AccordionSummary>
						<AccordionDetails>
							<div>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Suspendisse malesuada lacus ex,
								sit amet blandit leo lobortis eget. Lorem ipsum
								dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit
								leo lobortis eget.
							</div>
						</AccordionDetails>
					</Accordion>
				</InfoCard>
			</Grid>
		</Grid>
	);
};
