import {
	getActivitesData,
	getActivitesDataResolved,
	getProximityAttributeFields,
	getProximityAttributeFieldsRejected,
	getProximityAttributeFieldsResolved,
	getRiskCountResolved,
	getRiskCountAttribute,
	getRiskCountProximity,
	getRiskCountProximityAttribute,
	setRiskCountIsLoading,
	getAttributeFieldsResolved,
	getAttributeFieldsRejected,
	getAttributeFields,
} from '@Actions';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from '@reduxjs/toolkit';
import {
	getRiskCountAtributeApi,
	getRiskCountProximityAtributeApi,
	getRiskCountProximityApi,
	getActivitiesDataApi,
	getProximityAttributeFieldsApi,
	getAttributeFieldsApi,
} from '@Services/Risk/Risk.services';
import { ActivitesData, Category, ComponentCountReply } from '@Types';
import axios, { AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';

function* getRiskCountAttributeSaga(action: AnyAction) {
	const { key, metadata, metadataType, assetType, fieldName } =
		action.payload;
	try {
		yield put(getRiskCountResolved({ key: key, values: undefined }));
		yield put(setRiskCountIsLoading({ key: key, loading: true }));

		const response: AxiosResponse<ComponentCountReply[]> = yield call(
			getRiskCountAtributeApi,
			metadata,
			metadataType,
			assetType,
			fieldName,
		);
		yield put(
			getRiskCountResolved({
				key: key,
				values: response.data,
			}),
		);
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	} catch (err) {
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	}
}

function* getRiskCountProximitySaga(action: AnyAction) {
	const { key, metadata, metadataType, assetType, layerName } =
		action.payload;
	try {
		yield put(getRiskCountResolved({ key: key, values: undefined }));
		yield put(setRiskCountIsLoading({ key: key, loading: true }));

		const response: AxiosResponse<ComponentCountReply[]> = yield call(
			getRiskCountProximityApi,
			metadata,
			metadataType,
			assetType,
			layerName,
		);
		yield put(
			getRiskCountResolved({
				key: key,
				values: response.data,
			}),
		);
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	} catch (err) {
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	}
}

function* getRiskCountProximityAttributeSaga(action: AnyAction) {
	const {
		key,
		metadata,
		metadataType,
		assetType,
		layerName,
		fieldName,
		valueType,
		distance,
	} = action.payload;
	try {
		yield put(getRiskCountResolved({ key: key, values: undefined }));
		yield put(setRiskCountIsLoading({ key: key, loading: true }));

		const response: AxiosResponse<ComponentCountReply[]> = yield call(
			getRiskCountProximityAtributeApi,
			metadata,
			metadataType,
			assetType,
			layerName,
			fieldName,
			valueType,
			distance,
		);
		yield put(
			getRiskCountResolved({
				key: key,
				values: response.data,
			}),
		);
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	} catch (err) {
		yield put(setRiskCountIsLoading({ key: key, loading: false }));
	}
}

function* watchGetRiskCount() {
	yield takeLatest(
		getProximityAttributeFields,
		getProximityAttributeFieldsSaga,
	);
	yield takeLatest(getRiskCountAttribute, getRiskCountAttributeSaga);
	yield takeLatest(getRiskCountProximity, getRiskCountProximitySaga);
	yield takeLatest(
		getRiskCountProximityAttribute,
		getRiskCountProximityAttributeSaga,
	);
}

function* getProximityAttributeFieldsSaga(action: AnyAction) {
	try {
		const response: AxiosResponse<Category[]> = yield retry(
			5,
			1500,
			getProximityAttributeFieldsApi,
			action.payload,
		);
		yield put(getProximityAttributeFieldsResolved(response.data));
	} catch (e) {
		yield put(getProximityAttributeFieldsRejected());
	}
}
function* getAttributeFieldsSaga(action: AnyAction) {
	try {
		const response: AxiosResponse<Category[]> = yield retry(
			5,
			1500,
			getAttributeFieldsApi,
			action.payload,
		);
		yield put(getAttributeFieldsResolved(response.data));
	} catch (e) {
		yield put(getAttributeFieldsRejected());
	}
}
function* watchGetFields() {
	yield takeLatest(
		getProximityAttributeFields,
		getProximityAttributeFieldsSaga,
	);
	yield takeLatest(getAttributeFields, getAttributeFieldsSaga);
}

function* getActivitesDataSaga(action: AnyAction) {
	const { assetType, uploadType } = action.payload;

	const response: AxiosResponse<ActivitesData[]> = yield retry(
		5,
		1500,
		getActivitiesDataApi,
		assetType,
		uploadType,
	);
	yield put(getActivitesDataResolved(response.data));
}

function* watchGetActivitiesData() {
	yield takeLatest(getActivitesData, getActivitesDataSaga);
}

const sagaArray = [
	watchGetRiskCount(),
	watchGetFields(),
	watchGetActivitiesData(),
];

export default sagaArray;
