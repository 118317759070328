import {
	getFailureLog,
	getFailureLogRejected,
	getFailureLogResolved,
	getFailureConfig,
	getFailureConfigResolved,
	setFailureSetupDirty,
	saveFailureConfigAction,
	createFailureConfig,
	setFailureConfigNameError,
	setFailureConfigIsCreating,
	setFailureIsSaving,
	renameFailureConfigAction,
	deleteFailure,
	setFailureIsDeleting,
	duplicateFailureConfig,
	changeFailureConfigPublishState,
} from '@Actions/failure.actions';
import { error, success } from '@innovyze/stylovyze';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import {
	saveFailureConfigApi,
	getFailureCalcLogApi,
	getFailureConfigApi,
	createFailureConfigApi,
	deleteFailureApi,
	duplicateFailureConfigApi,
	changeFailureConfigPublishStateApi,
} from '@Services/Failure/Failure.services';
import { CreateFailureConfig, LogReply, FailureConfig } from '@Types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';
import { t } from 'i18next';
import { addRouteNamespace } from '@Utils/actions';
import { changeFailureConfigsPage } from '@innovyze/lib_am_common/Actions';

export function* getFailureConfigSaga(action: AnyAction) {
	const configId = action.payload;
	const response: AxiosResponse = yield call(getFailureConfigApi, configId);

	yield put(getFailureConfigResolved(response.data));
}

function* watchGetFailureConfig() {
	yield takeLatest(getFailureConfig, getFailureConfigSaga);
}

interface ConfigData {
	errorMessage: string;
}

export function* createFailureConfigSaga(action: AnyAction) {
	try {
		const config: CreateFailureConfig = {
			...action.payload.config,
			publishState: 'unpublished',
		};

		const response: AxiosResponse = yield call(
			createFailureConfigApi,
			config,
		);
		yield put(setFailureConfigNameError(t('')));

		action.payload.history.push(
			addRouteNamespace(`results/${response.data.insertedId}`),
		);
		yield put(setFailureConfigIsCreating(false));
		yield put(changeFailureConfigsPage(action.payload.page));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setFailureConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Failed to create failure definition: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Failed to create failure definition: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setFailureConfigIsCreating(false));
	}
}

function* duplicateFailureConfigSaga(action: AnyAction) {
	try {
		const configId: string = action.payload.configId;
		const response: AxiosResponse = yield call(
			duplicateFailureConfigApi,
			configId,
		);
		yield put(setFailureConfigIsCreating(false));
		action.payload.onComplete(response.data.configId);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			yield put(
				error(
					t('Failed to create failure definition: {{info}}', {
						info:
							axiosError.response?.statusText ??
							data?.errorMessage ??
							t('No additional information'),
					}),
				),
			);
		} else {
			yield put(
				error(
					t('Failed to create failure definition: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setFailureConfigIsCreating(false));
	}
}

function* watchCreateFailureConfig() {
	yield takeLatest(duplicateFailureConfig, duplicateFailureConfigSaga);
	yield takeLatest(createFailureConfig, createFailureConfigSaga);
}

export function* renameFailureConfigSaga(action: PayloadAction<FailureConfig>) {
	try {
		yield put(setFailureIsSaving(true));
		yield call(saveFailureConfigApi, action.payload);
		yield put(success(t('Failure definition renamed')));
		yield put(getFailureConfigResolved(action.payload));
		yield put(setFailureIsSaving(false));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setFailureConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Failure definition failed to save: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Failure definition failed to save: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setFailureIsSaving(false));
	}
}

export function* saveFailureConfigSaga(action: PayloadAction<FailureConfig>) {
	try {
		yield put(setFailureIsSaving(true));

		yield call(saveFailureConfigApi, action.payload);
		yield put(success(t('Deterioration failure saved')));
		yield put(setFailureSetupDirty(false));
		yield put(setFailureIsSaving(false));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as ConfigData;
			if (data?.errorMessage === '[400] Duplicate config name') {
				yield put(setFailureConfigNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Failure definition failed to save: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Failure definition failed to save: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setFailureIsSaving(false));
	}
}

function* watchStoreFailureConfig() {
	yield takeLatest(renameFailureConfigAction, renameFailureConfigSaga);
	yield takeLatest(saveFailureConfigAction, saveFailureConfigSaga);
}

function* getLogSaga(action: AnyAction) {
	try {
		const configId: string = action.payload;
		const response: AxiosResponse<LogReply> = yield retry(
			5,
			1500,
			getFailureCalcLogApi,
			configId,
		);
		yield put(getFailureLogResolved(response.data));
	} catch (e) {
		yield put(getFailureLogRejected());
	}
}
function* watchGetLog() {
	yield takeLatest(getFailureLog, getLogSaga);
}

function* deleteFailureSaga(action: AnyAction) {
	try {
		yield retry(3, 1500, deleteFailureApi, action.payload.configId);
		yield put(success(t('Failure failure deleted successfully')));
		yield put(setFailureIsDeleting(false));
		action.payload.refresh();
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			yield put(
				error(
					t('Failure definition failed to delete: {{info}}', {
						info:
							axiosError.response?.statusText ??
							t('No additional information'),
					}),
				),
			);
		} else {
			yield put(
				error(
					t('Failure definition failed to delete: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setFailureIsDeleting(false));
	}
}
function* watchDeleteFailureeSaga() {
	yield takeLatest(deleteFailure, deleteFailureSaga);
}

function* changeFailureConfigPublishStateSaga(action: AnyAction) {
	const { newPublishState, configId } = action.payload;
	try {
		yield call(
			changeFailureConfigPublishStateApi,
			newPublishState,
			configId,
		);
		yield put(
			// eslint-disable-next-line prettier/prettier
			success(t('Failure definition {{newPublishState}}', { newPublishState })),
		);
		action.payload.refresh();
	} catch (err) {
		yield put(
			error(
				t('Failed to {{newPublishState}} failure definition', {
					newPublishState,
				}),
			),
		);
	}
}
function* watchChangeFailureConfigPublishStateSaga() {
	yield takeLatest(
		changeFailureConfigPublishState,
		changeFailureConfigPublishStateSaga,
	);
}

const sagaArray = [
	watchCreateFailureConfig(),
	watchGetFailureConfig(),
	watchStoreFailureConfig(),
	watchGetLog(),
	watchDeleteFailureeSaga(),
	watchChangeFailureConfigPublishStateSaga(),
];

export default sagaArray;
