/*eslint import/no-unresolved: [2, { ignore: ['\\.lib_am_common/Reducers$'] }]*/
import * as AM_COMMON from '@innovyze/lib_am_common/Reducers';

import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
// import { mapRowToCells, mapRowToCellsNoCD } from '@Reducers';
// import { noConditionData } from '@Components/FailureDetails/FailureDetails.component';
import { FailureDetailsRow } from '@Types';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const failureStoreSelector = createSelector(
	parseNamespace,
	state => state.FailureStore,
);

export const FailureConfigSelector = createSelector(
	failureStoreSelector,
	FailureStore => FailureStore.failureConfig,
);

const FailureSettingSelector = createSelector(
	failureStoreSelector,
	FailureStore => FailureStore.failureConfig.failureSetting,
);

const FailureTabSelector = createSelector(
	failureStoreSelector,
	riskStore => riskStore.tab,
);

export const selectorFailureDetailsPagination = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails?.pagination,
);

export const selectorFailureDetailsRows = createSelector(
	failureStoreSelector,
	riskStore =>
		riskStore.failureDetails?.rows as unknown as FailureDetailsRow[],
);

const selectorIsFailureSetupDirty = createSelector(
	failureStoreSelector,
	riskStore => riskStore.isSetupDirty,
);

export const searchFailureQuerySelector = createSelector(
	failureStoreSelector,
	riskStore =>
		riskStore.search.length
			? `&search=${encodeURIComponent(riskStore.search)}`
			: '',
);

// export const failureDetailsQuerySelector = createSelector(
// 	riskStoreSelector,
// 	selectorPaginationQuery,
// 	selectorFilterQuery,
// 	searchQuerySelector,
// 	(riskStore, pag, filters, search) => {
// 		const settings = riskStore.failureConfig.failureSetting;
// 		const failureDetails = riskStore.failureDetails;
// 		let q = pag;
// 		q = q.length
// 			? q + `&configId=${settings.configId}`
// 			: `?configId=${settings.configId}`;
// 		if (failureDetails.scanId) {
// 			q = q.length
// 				? q + `&scanId=${failureDetails.scanId}`
// 				: `?scanId=${failureDetails.scanId}`;
// 		}
// 		if (filters.length) {
// 			q += filters;
// 		}
// 		if (search.length) {
// 			q += search;
// 		}
// 		q = q + '&includeScores=true';
// 		return q;
// 	},
// );

// const selectorFailureDetailsTablePage = createSelector(
// 	[
// 		selectorFailureDetailsPagination,
// 		selectorFailureDetailsRows,
// 		FailureConfigSelector,
// 	],
// 	(pagination, rows, config) => ({
// 		pages: pagination.pages,
// 		page: pagination.page,
// 		cells: rows.map(r =>
// 			noConditionData(config.failureSetting.assetType)
// 				? mapRowToCellsNoCD(r)
// 				: mapRowToCells(r),
// 		),
// 		rowIds: rows.map(r => r?._id || ''),
// 		sortKey: pagination.sortKey,
// 		sortDescending: pagination.sortDescending,
// 		total: pagination.total,
// 	}),
// );

const selectorFailureCanReload = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails?.pagination?.total > 0,
);

const selectorFailureRunCompleteReload = createSelector(
	failureStoreSelector,
	riskStore => riskStore.runCompleteReload,
);

const selectorFailureDetailsSlice = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails.pagination.slice,
);

const selectorFailureDetailsIsLoading = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails.isLoading,
);
const selectorFailureIsLoading = createSelector(
	failureStoreSelector,
	riskStore => riskStore.isLoading,
);

const selectorFailureDetailsIsWaiting = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails.isWaiting,
);

const selectorFailureSaveRunSettings = createSelector(
	failureStoreSelector,
	riskStore => riskStore.saveRun,
);

const selectorFailureDetailsSearch = createSelector(
	failureStoreSelector,
	riskStore => riskStore.search,
);

const selectorFailureConfigNameError = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureConfigNameError,
);

const selectorFailureConfigIsCreating = createSelector(
	failureStoreSelector,
	riskStore => riskStore.isCreating,
);

const selectorFailureConfigIsSaving = createSelector(
	failureStoreSelector,
	riskStore => riskStore.isSaving,
);

const selectorFailureIsDeleting = createSelector(
	failureStoreSelector,
	riskStore => riskStore.isDeleting,
);

const failureDetailsStatusSelector = createSelector(
	failureStoreSelector,
	riskStore => riskStore.failureDetails.status,
);

const selectorFailureRefreshList = createSelector(
	failureStoreSelector,
	riskStore => riskStore.refreshList,
);

export const selectFailureCanReload = () =>
	useSelector(selectorFailureCanReload);

export const selectorFailureRowsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) =>
	createSelector(selectorFailureDetailsSlice, slice =>
		AM_COMMON.rowsPerPage(slice || 10, dispatchSliceFn),
	);
export const selectFailureRunCompleteReload = () =>
	useSelector(selectorFailureRunCompleteReload);

export const selectFailureRefreshList = () =>
	useSelector(selectorFailureRefreshList);

export const selectFailureStore = () => useSelector(failureStoreSelector);

export const selectFailureConfig = () => useSelector(FailureConfigSelector);

export const selectFailureSetting = () => useSelector(FailureSettingSelector);

export const selectFailureTab = () => useSelector(FailureTabSelector);

// export const selectFailureDetailsQuery = () =>
// 	useSelector(failureDetailsQuerySelector);

// export const selectFailureDetailsTablePage = () =>
// 	useSelector(selectorFailureDetailsTablePage);

export const selectFailureDetailsPagination = () =>
	useSelector(selectorFailureDetailsPagination);

export const selectFailureRowsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) => useSelector(selectorFailureRowsPerPage(dispatchSliceFn));
export const selectFailureDetailsIsLoading = () =>
	useSelector(selectorFailureDetailsIsLoading);
export const selectFailureIsLoading = () =>
	useSelector(selectorFailureIsLoading);
export const selectFailureDetailsIsWaiting = () =>
	useSelector(selectorFailureDetailsIsWaiting);

export const selectFailureSaveRunSettings = () =>
	useSelector(selectorFailureSaveRunSettings);

export const selectFailureDetailsSearch = () =>
	useSelector(selectorFailureDetailsSearch);
export const selectFailureDetailsFilterProps = () =>
	useSelector(selectorIsFailureSetupDirty);
export const selectFailureConfigNameError = () =>
	useSelector(selectorFailureConfigNameError);
export const selectFailureConfigIsCreating = () =>
	useSelector(selectorFailureConfigIsCreating);
export const selectFailureIsSaving = () =>
	useSelector(selectorFailureConfigIsSaving);
export const selectFailureIsDeleting = () =>
	useSelector(selectorFailureIsDeleting);

export const selectFailureDetailsStatus = () =>
	useSelector(failureDetailsStatusSelector);
