import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	getModelConfigs,
	getModelConfigsResolved,
	getModelConfigsRejected,
	changeModelConfigsPage,
	changeModelConfigsSlice,
	changeModelConfigsSort,
	clearModelConfigs,
	setModelConfigsIsBusy,
} from '@Actions';
import {
	clearPagination,
	makePaginationQuery,
	status200,
	updateGet,
	updateIsBusyCards,
	updatePage,
	updateRejected,
	updateSlice,
	updateSort,
} from '@innovyze/lib_am_common/Reducers';
import { PaginationState, ResolvedResponse } from '@innovyze/lib_am_common';
import { ModelConfigCard } from '@Types';

// I HAVE NO IDEA WHY I HAVE TO DUPLICATE THIS HERE... without it I get this error when runnin in a module
// Uncaught Error: The slice reducer for key "modelConfigs" returned undefined during initialization. If the state passed to the reducer is undefined, you must explicitly return the initial state.
// It seems the paginationInitialState is getting initilized AFTER the reducers but I cant see why
const paginationInitialState: PaginationState = {
	query: '',
	status: { code: 800, text: 'Uncalled' },
	pagination: {
		total: 0,
		subtotal: 0,
		slice: 10,
		pages: 1,
		page: 1,
	},
	rows: [],
	cards: [],
	selectedDbIds: [],
	currentDbId: '',
	isLoading: false,
	isWaiting: false,
};

interface ModelConfigsReducer {
	[x: string]: (
		state: PaginationState,
		action: AnyAction,
	) => PaginationState | undefined;
}

/**
 * Update resolved for model config cards
 * @param state current redux state
 * @param response response from the server
 * @returns the pagination object to put into redux
 */
export const updateResolvedModelConfigCard = (
	state: PaginationState,
	response: ResolvedResponse,
): PaginationState => {
	return {
		...paginationInitialState,
		status: status200(),
		pagination: { ...response.pagination },
		cards: response.data as ModelConfigCard[],
		query: makePaginationQuery(response.pagination),
	};
};

export const reducer: ModelConfigsReducer = {
	[getModelConfigs.toString()]: (state, action) =>
		updateGet(state, action.payload),
	[clearModelConfigs.toString()]: (state, action) =>
		clearPagination(state, action.payload),
	[getModelConfigsResolved.toString()]: (state, action) =>
		updateResolvedModelConfigCard(state, action.payload),
	[getModelConfigsRejected.toString()]: (state, action) =>
		updateRejected(state, action.payload),
	[changeModelConfigsPage.toString()]: (state, action) =>
		updatePage(state, action.payload),
	[changeModelConfigsSlice.toString()]: (state, action) =>
		updateSlice(state, action.payload),
	[changeModelConfigsSort.toString()]: (state, action) =>
		updateSort(state, action.payload),
	[setModelConfigsIsBusy.toString()]: (state, action) =>
		updateIsBusyCards(state, action.payload),
};

export const modelConfigsReducer = createReducer(
	paginationInitialState,
	reducer,
);
