import { TFunc } from '@Translations/types';
import { Category } from '.';

export interface GetComponentMetadataParams {
	assetType: string;
	field: string;
	fieldAlias: string;
	multiParam?: boolean;
	layerName?: string;
}

export interface ComponentUniqueMetadata {
	value: string[];
}

export interface ComponentRangeMetadata {
	min: number;
	max: number;
}

export interface UnitMetadata {
	unique?: string[];
	precision?: number;
	range?: ComponentRangeMetadata;
	unit?: string;
	currency?: string;
}
export interface ComponentMetadataStoreState extends UnitMetadata {
	isLoading: boolean;
}

export interface ComponentMetadataResponse {
	unique?: string[];
	precision?: number;
	range?: ComponentRangeMetadata;
	unit?: string;
	userFriendlyName?: string;
}

export interface ComponentProps {
	onAddComponent: (
		forLoF: boolean,
		forInspection: boolean,
		category: Category | undefined,
	) => void;
	onEditComponent: (
		forLoF: boolean,
		forInspection: boolean,
		category: Category | undefined,
		component: Component,
	) => void;
}

export interface ComponentMetadata {
	key: string;
	value: number;
	ix?: number;
}

export interface ParamMetadata {
	index: number;
	meta: ComponentMetadata;
}

export interface MultiParamCell {
	params: ParamMetadata[];
	score: number;
}

export interface Component {
	name: string;
	select: boolean;
	weighting: number;
	type: string;
	field?: string;
	fieldAlias?: string;
	simulationName?: string; // only for simulation type components
	simulationUploadId?: string; // only for simulation type components
	uploadId?: string;
	taskName?: string; // only for Task components
	layer?: string;
	maxDistance?: number;
	metadata?: ComponentMetadata[];
	metadataType?: string;
	nullScore?: number;
	paramComponents?: Component[];
	scoreMatrix?: MultiParamCell[];
	unitMetadataName?: string;
	unitMetadata?: UnitMetadata;
	unused?: boolean;
}

export interface ComponentField {
	fieldType: string;
	userFriendlyName: string;
	cachedField: string;
	isCached: boolean;
}

export enum ComponentType {
	SpatialProximity = 'Spatial Proximity',
	SpatialAttribute = 'ProximityAttribute',
	Attribute = 'attribute',
}

export const getTypeDisplay = (value: ComponentType, t: TFunc) => {
	switch (value) {
		case ComponentType.SpatialProximity:
			return t('Spatial Proximity');
		case ComponentType.SpatialAttribute:
			return t('Spatial Proximity -- Attribute');
		default:
			return t('Attribute');
	}
};

export interface ComponentCount {
	name: string;
	type: string;
	field?: string;
	layer?: string;
	uploadId?: string;
	maxDistance?: number;
	metadataType?: string;
	metadata?: ComponentMetadata[];
}
