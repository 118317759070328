import { NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { ModelConfigCard } from '@Types/modelConfig.types';
import { rowsPerPage } from '@innovyze/lib_am_common/Reducers';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const modelConfigsBaseSelector = createSelector(
	parseNamespace,
	state => state.modelConfigs,
);

export const selectorModelConfigsPagination = createSelector(
	parseNamespace,
	state => state.modelConfigs.pagination,
);

export const selectorModelConfigsPaginationQuery = createSelector(
	parseNamespace,
	state => state.modelConfigs.query,
);

export const modelConfigsSelector = createSelector(
	modelConfigsBaseSelector,
	baseState => baseState.cards as ModelConfigCard[],
);

const selectorModelConfigsCanReload = createSelector(
	parseNamespace,
	state => state.modelConfigs.pagination.total > 0,
);
export const selectModelConfigsCanReload = () =>
	useSelector(selectorModelConfigsCanReload);

export const modelConfigsStatusSelector = createSelector(
	modelConfigsBaseSelector,
	baseState => baseState.status,
);

export const modelConfigsIsWaitingSelector = createSelector(
	modelConfigsBaseSelector,
	baseState => baseState.isWaiting,
);

const selectorModelConfigsPage = () => {
	const pagination = selectorModelConfigsPagination;
	const cards = modelConfigsSelector;

	return createSelector(pagination, cards, (pagination, cards) => ({
		total: pagination.total,
		pages: pagination.pages,
		page: pagination.page,
		cards: cards,
		sortKey: pagination.sortKey,
		sortDescending: pagination.sortDescending,
	}));
};

const selectorModelConfigsSlice = createSelector(
	parseNamespace,
	state => state.modelConfigs.pagination.slice,
);

const selectorModelConfigIsLoading = createSelector(
	parseNamespace,
	state => state.modelConfigs.isLoading,
);

const selectorModelConfigIsWaiting = createSelector(
	parseNamespace,
	state => state.modelConfigs.isWaiting,
);

export const selectModelConfigs = () => useSelector(modelConfigsSelector);
export const selectModelConfigsStatus = () =>
	useSelector(modelConfigsStatusSelector);

export const selectorModelConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) =>
	createSelector(selectorModelConfigsSlice, slice =>
		rowsPerPage(slice || 10, dispatchSliceFn),
	);

export const selectModelConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) => useSelector(selectorModelConfigsPerPage(dispatchSliceFn));

export const selectModelConfigsPage = () =>
	useSelector(selectorModelConfigsPage());
export const selectModelConfigsIsLoading = () =>
	useSelector(selectorModelConfigIsLoading);
export const selectModelConfigsIsWaiting = () =>
	useSelector(selectorModelConfigIsWaiting);
export const selectModelConfigsPagination = () =>
	useSelector(selectorModelConfigsPagination);

export default selectModelConfigs;
