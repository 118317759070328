import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { ComponentCountReply } from '@Types/risk.types';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const riskStoreSelector = createSelector(
	parseNamespace,
	state => state.RiskStore,
);

const RiskIsCountLoadingStoreSelector = createSelector(
	riskStoreSelector,
	riskStore => riskStore.isCountLoading,
);

const RiskCountStoreSelector = createSelector(
	riskStoreSelector,
	state => state.riskCount,
);

export const selectorIsCountLoading = (key: string) => {
	return createSelector(RiskIsCountLoadingStoreSelector, state => {
		return state.indexOf(key) !== -1;
	});
};

export const useSelectIsCountLoading = (key: string): boolean =>
	useSelector(selectorIsCountLoading(key));

const selectorRiskCount = (key: string) => {
	return createSelector(RiskCountStoreSelector, state => {
		if (state) {
			return state[key];
		}
	});
};

export const selectRiskCount = (
	key: string,
): ComponentCountReply[] | undefined => useSelector(selectorRiskCount(key));

export const selectRiskStore = () => useSelector(riskStoreSelector);
