import {
	Pagination,
	ResolvedResponse,
	Sort2,
	Status,
} from '@innovyze/lib_am_common';
import { createAction } from '@reduxjs/toolkit';
import {
	ModelConfigList,
	modelConfigsPaginationDefault,
} from '@Types/modelConfig.types';
import { addNamespace } from '@Utils/actions';

export const getModelConfigs = createAction<Pagination>(
	addNamespace('modelConfigs/getModelConfigs'),
);

export const clearModelConfigs = createAction<Pagination>(
	addNamespace('modelConfigs/clearModelConfigs'),
);

export const getModelConfigsClear = () =>
	clearModelConfigs(modelConfigsPaginationDefault());

export const reloadModelConfigs = createAction(
	addNamespace('modelConfigs/reloadModelConfigs'),
);

export const getModelConfigsResolved = createAction<ResolvedResponse>(
	addNamespace('modelConfigs/getModelConfigs/resolved'),
);

export const getModelConfigsRejected = createAction<Status>(
	addNamespace('modelConfigs/getModelConfigs/rejected'),
);

export const changeModelConfigsSort = createAction<Sort2>(
	addNamespace('modelConfigs/sort'),
);

export const changeModelConfigsSlice = createAction<number>(
	addNamespace('modelConfigs/slice'),
);

export const changeModelConfigsPage = createAction<number>(
	addNamespace('modelConfigs/page'),
);

export const currentModelConfigsRow = createAction<number>(
	addNamespace('modelConfigs/current/row'),
);

export const setModelConfigsIsBusy = createAction<boolean>(
	addNamespace('modelConfigs/isBusy'),
);

export const getModelConfigsList = createAction<{ assetType: string }>(
	addNamespace('modelConfigs/getModelConfigsList'),
);

export const getModelConfigsListResolved = createAction<ModelConfigList[]>(
	addNamespace('modelConfigs/getModelConfigsList/resolved'),
);

export const getModelConfigsListRejected = createAction<Status>(
	addNamespace('modelConfigs/getModelConfigsList/rejected'),
);
