import React from 'react';
import { ModelManagement, AssetDetails } from '.';
import { addRouteNamespace } from '@Utils/actions';
import RiskManagementList from './ModelManagementList';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

// NOTE WE DONT NEED TO TRANSLATE THESE AS MOST ARE NEVER SHOWN
export const mainPageRoutes = [
	{
		title: 'Results',
		to: addRouteNamespace('results/:configId'),
		render: <ModelManagement page={0} />,
	},
	{
		title: 'Deterioration Model',
		to: addRouteNamespace('modelSetup/:configId'),
		render: <ModelManagement page={1} />,
	},
	{
		title: 'Deterioration Log',
		to: addRouteNamespace('modelLog/:configId'),
		render: <ModelManagement page={2} />,
	},
	{
		title: 'Asset Details',
		to: addRouteNamespace('modelAssetDetails/:assetId'),
		render: <AssetDetails />,
	},
];

export const miniPageRoutes = [
	{
		title: 'Deterioration',
		to: '/deterioration',
		icon: <AutoGraphIcon />,
		render: <RiskManagementList />,
	},
	...mainPageRoutes,
];

export default [mainPageRoutes, miniPageRoutes];
