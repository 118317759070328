// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import {
	ColumnFilterOptions,
	ColumnFilterValue,
	Pagination,
	ResolvedResponse,
	Sort2 as Sort,
	Status,
} from '@innovyze/lib_am_common';
import { createAction } from '@reduxjs/toolkit';
import {
	CreateModelConfig,
	LogReply,
	ModelCalcStatusResult,
	ModelConfig,
} from '@Types/model.types';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';
import { addNamespace } from '@Utils/actions';
import { ConfigPublishState } from '@Types/modelConfig.types';

export const paginationDefaultModel = (): Pagination => ({
	...paginationInitialState,
	sortKey: 'CURRENT_RISK',
	sortDescending: true,
});

export const getModelConfig = createAction<string>('Model/getModelConfig');
export const getModelConfigResolved = createAction<ModelConfig>(
	'Model/getModelConfigResolved',
);
export const getModelConfigRejected = createAction(
	'Model/getModelConfigRejected',
);
export const clearModelConfig = createAction('Model/clearModelConfig');

export const createModelConfig = createAction<{
	history: unknown;
	config: CreateModelConfig;
	page: number;
}>('Model/createModelConfig');

export const changeModelConfigPublishState = createAction<{
	newPublishState: ConfigPublishState;
	configId: string;
	refresh: () => void;
}>('Model/changeModelConfigPublishState');

export const duplicateModelConfig = createAction<{
	onComplete: (configId: string) => void;
	configId: string;
}>('Model/duplicateModelConfig');

export const setModelConfigIsCreating = createAction<boolean>(
	'modelSetup/setModelConfigIsCreating',
);

export const setModelConfigNameError = createAction<string>(
	'modelSetup/setModelConfigNameError',
);

export const renameModelConfigAction = createAction<ModelConfig>(
	'Model/renameModelConfig',
);

export const saveModelConfigAction = createAction<ModelConfig>(
	'Model/saveModelConfig',
);

export const getModelDetails = createAction<Pagination>(
	'model/getModelDetails',
);

export const runModel = createAction<string>('model/runModel');
export const runModelRejected = createAction('model/runModelRejected');
export const runModelResolved = createAction('model/runModelResolved');

export const resetModel = createAction<string>('model/resetModel');
export const resetModelRejected = createAction('model/resetModelRejected');
export const resetModelResolved = createAction('model/resetModelResolved');

export const getModelDetailsDefaulted = () =>
	getModelDetails(paginationDefaultModel());
export const reloadModelDetails = createAction<string>(
	'model/reloadModelDetails',
);
export const getModelDetailsResolved = createAction<ResolvedResponse>(
	'Model/getModelDetails/resolved',
);
export const getModelDetailsRejected = createAction<Status>(
	'Model/getModelDetails/rejected',
);

export const changeModelPagination = createAction<{
	page?: number;
	slice?: number;
}>('Model/paginationModel');

export const clearModelScanId = createAction(
	addNamespace('Model/clearModelScanId'),
);

export const changeModelSlice = createAction<number>('Model/slice');

export const changeModelPage = createAction<number>('Model/page');

export const changeModelSearch = createAction<string>('Model/changeSearch');

export const changeModelSort = createAction<Sort>('inspections/sort');

export const setModelSelected = createAction<string[]>('Model/selected/set');

export const addModelSelected = createAction<string[]>('Model/selected/add');

export const removeModelSelected = createAction<string[]>(
	'Model/selected/remove',
);

export const clearModelSelected = createAction('Model/selected/clear');

export const currentModelRow = createAction<number>('Model/current/row');

export const setModelDetailsIsBusy = createAction<boolean>('Model/isBusy');
export const setModelTab = createAction<string>('Model/setTab');
export const setModelSaveRun = createAction<boolean>('Model/setSaveRun');

export const setModelCalcStatus = createAction<ModelCalcStatusResult>(
	'Model/setModelCalcStatus',
);

export const getModelLog = createAction<string>('Model/getModelLog');
export const getModelLogResolved = createAction<LogReply>(
	'Model/getModelLogResolved',
);
export const getModelLogRejected = createAction('Model/getModelLogRejected');

export const changeModelFilterSelect = createAction<ColumnFilterValue>(
	addNamespace('Model/filterSelect'),
);
export const changeModelFiltersSelect = createAction<ColumnFilterValue[]>(
	addNamespace('Model/filtersSelect'),
);

export const updateColumnFilterSelectOptions =
	createAction<ColumnFilterOptions>(
		addNamespace('Model/filterSelect/column/options'),
	);

export const setModelSetupDirty = createAction<boolean>(
	'modelSetup/setModelSetupDirty',
);

export const setModelRunCompleteReload = createAction<boolean>(
	'Model/modelCompleteReload',
);

export const setModelRefreshList = createAction<boolean>(
	'Model/setRefreshList',
);

export const setModelIsSaving = createAction<boolean>(
	'modelSetup/setModelIsSaving',
);

export const deleteModel = createAction<{
	configId: string;
	refresh: () => void;
}>('rehab/deleteModel');
export const setModelIsDeleting = createAction<boolean>(
	'model/setModelIsDeleting',
);
