import { ComponentField } from '@Types';

export const sortFields = (fields: ComponentField[]) => {
	return fields.sort((a: ComponentField, b: ComponentField) =>
		a.userFriendlyName < b.userFriendlyName
			? -1
			: a.userFriendlyName > b.userFriendlyName
			? 1
			: 0,
	);
};
