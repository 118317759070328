import { Grid, Typography } from '@mui/material';
import React from 'react';
import Scales from '@Components/Utils/icons/Scales';
import { useGlobalization } from '@Translations/useGlobalization';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoResultsModel = () => {
	const { t } = useGlobalization();

	return (
		<Grid container style={{ height: '100%' }}>
			<Grid
				container
				item
				xs={12}
				justifyContent="center"
				style={{ paddingTop: '80px' }}>
				<Scales />
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">{t('No results to show')}</Typography>
			</Grid>
		</Grid>
	);
};
