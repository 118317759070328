import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { StyledGrid } from '@Utils/styles';
import { Text } from '@innovyze/stylovyze';
import { Grid } from '@mui/material';

interface TableToolbarProps {
	isLoading: boolean;
}

export const TableToolbar = () => {
	const { t } = useGlobalization();

	return (
		<StyledGrid
			container
			style={{
				width: '100%',
				marginTop: '1rem',
				marginBottom: '1rem',
			}}>
			<Grid item xs={8}>
				<Text variant="heading-large-strong">{t('Cohorts')}</Text>
			</Grid>
			<Grid item xs={4} display="flex" justifyContent="flex-end"></Grid>
		</StyledGrid>
	);
};
