import {
	getAssetFields,
	getAssetFieldsRejected,
	getAssetFieldsResolved,
	getSpatialLayerList,
	getSpatialLayerListRejected,
	getSpatialLayerListResolved,
} from '@Actions/asset.actions';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { getAssetFieldsApi } from '@Services/asset/asset.services';
import { getSpatialLayerListApi } from '@Services/Model/Model.services';
import { AssetFieldsResolvedResponse } from '@Types/asset.types';
import { AxiosResponse } from 'axios';
import { SpatialLayer } from '@innovyze/lib_am_common';

function* getAssetFieldsSaga() {
	try {
		const payload: AxiosResponse<AssetFieldsResolvedResponse> = yield retry(
			5,
			1500,
			getAssetFieldsApi,
		);

		const assetData = payload.data.asset;
		const result = Object.keys(assetData).map(property => ({
			name: property,
			data_type: assetData[property].data_type,
			display_name: assetData[property].display_name,
			incoming_imperial_unit: assetData[property].incoming_imperial_unit,
		}));

		yield put(getAssetFieldsResolved(result));
	} catch (e) {
		yield put(getAssetFieldsRejected());
	}
}
function* watchGetAssetFields() {
	yield takeLatest(getAssetFields, getAssetFieldsSaga);
}

function* getSpatialLayerListSaga() {
	try {
		const payload: AxiosResponse<SpatialLayer[]> = yield retry(
			5,
			1500,
			getSpatialLayerListApi,
		);

		yield put(getSpatialLayerListResolved(payload.data));
	} catch (e) {
		yield put(getSpatialLayerListRejected());
	}
}
function* watchGetSpatialLayerList() {
	yield takeLatest(getSpatialLayerList, getSpatialLayerListSaga);
}

const sagaArray = [watchGetAssetFields(), watchGetSpatialLayerList()];

export default sagaArray;
