import config, { apiEnv } from './config.api';

import axios from 'axios';
import cookies from 'browser-cookies';
import { getService } from '@innovyze/stylovyze';

const options = {
	baseURL: getService('amUpload', config, apiEnv),
	timeout: 15000,
};

const uploadService = axios.create(options);

uploadService.interceptors.request.use(
	config => {
		config.headers.Authorization =
			'Bearer ' + cookies.get('auth0.user.token');
		return config;
	},
	error => {
		Promise.reject(error);
	},
);

export default uploadService;
