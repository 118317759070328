import {
	UnitDisplay,
	getCurrency,
	useSettings,
	useUnits,
} from '@innovyze/stylovyze';
import { GridRowId } from '@mui/x-data-grid-premium';
import React from 'react';

interface RageDisplayCellProps {
	id: GridRowId;
	value: string;
	unit?: string;
	currency?: string;
}

export const RangeDisplayCell = ({
	id,
	value,
	unit,
	currency,
}: RageDisplayCellProps) => {
	const { companySettings } = useSettings();
	if (unit && unit.length != 0) {
		return <UnitDisplay cy={`score-${id}`} value={`${value} ${unit}`} />;
	} else if (currency !== undefined && companySettings) {
		return <>{getCurrency(+value, companySettings)}</>;
	} else {
		return <>{value}</>;
	}
};

export const getRangeDisplayValue = (
	value: string,
	unit?: string,
	currency?: string,
) => {
	const { companySettings } = useSettings();
	const { getSystemValueFormatted } = useUnits();

	if (unit && unit.length != 0) {
		return getSystemValueFormatted(`${value}${unit}`);
	} else if (currency !== undefined && companySettings) {
		return getCurrency(+value, companySettings);
	} else {
		return value;
	}
};
