import riskApi, { riskBaseUrl } from '@Apis/risks.api';
import { ResolvedResponse, SpatialLayer } from '@innovyze/lib_am_common';
import {
	CreateModelConfig,
	CreateModelConfigPayload,
	LogReply,
	ModelRunReply,
} from '@Types/model.types';
import { AxiosResponse } from 'axios';
import { ConfigPublishState, ModelConfig, ComponentMetadata } from '@Types';
import { t } from 'i18next';

export const getModelConfigurationsApi = (query: string) => {
	const address = '/risk/configurations' + query;
	return riskApi.get<ResolvedResponse>(address);
};

export const getModelConfigApi = (
	configId: string,
): Promise<AxiosResponse<ModelConfig>> => {
	const address = `/risk/config?configId=${configId}`;
	return riskApi.get<ModelConfig>(address);
};

export const createModelConfigApi = (config: CreateModelConfig) => {
	const address = '/risk/config';
	return riskApi.post<CreateModelConfigPayload>(address, config);
};

export const saveModelConfigApi = (config: ModelConfig) => {
	const address = '/risk/config/version';
	return riskApi.post(address, config);
};

export const duplicateModelConfigApi = (configId: string) => {
	const address = `/risk/config/duplicate?configId=${configId}`;
	return riskApi.post(address);
};

export const startModelCalculationApi = (configId: string) => {
	const address = `/risk/calc?configId=${configId}`;
	return riskApi.post(address);
};

export const resetModelCalculationApi = (configId: string) => {
	const address = `/risk/calc/reset?configId=${configId}`;
	return riskApi.put(address);
};

export const resetModelPreviewCalculationApi = (configId: string) => {
	const address = `/risk/calc/reset?configId=${configId}&isMatrixPreview=true`;
	return riskApi.put(address);
};

export const getModelDetailsApi = (query: string) => {
	const address = '/risk/score';
	return riskApi.get<ResolvedResponse>(`${address}${query}`);
};

export default class ModelCalcStatusService {
	constructor(private token: string, private configId: string) {}

	public async getProcessStatus(): Promise<ModelRunReply> {
		const response = await fetch(
			`${riskBaseUrl}risk/calc/status?configId=${this.configId}`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${this.token}`,
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			},
		);

		if (response.ok) {
			return await response.json();
		}

		throw new Error(t('Unable to get risk calculation status'));
	}
}

export const getModelCalcStatusService = (token: string, configId: string) => {
	return new ModelCalcStatusService(token, configId);
};

export const getModelCalcLogApi = (configId: string) => {
	const address = `/risk/calc/log?configId=${configId}`;
	return riskApi.get<LogReply>(`${address}`);
};

export const getSpatialLayerListApi = () => {
	const address = '/spatial/layerlist';
	return riskApi.get<SpatialLayer[]>(address);
};

export const deleteModelApi = (id: string) => {
	const address = `/risk/config?configId=${id}`;
	return riskApi.delete(address);
};

export const changeModelConfigPublishStateApi = (
	newPublishState: ConfigPublishState,
	configId: string,
) => {
	const address = `/risk/config/publish?configId=${configId}`;
	return riskApi.put(address, { newPublishState });
};

const getModelCountApi = (
	assetType: string,
	fieldType: string,
	metadata: ComponentMetadata[],
	metadataType: string,
	additionalParams?: Record<string, string | number>,
) => {
	const params = new URLSearchParams({
		assetType,
		fieldType,
		...additionalParams,
	});
	const address = `/risk/count?${params.toString()}`;

	return riskApi.post(address, {
		metadata,
		metadataType,
	});
};

export const getModelCountAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	fieldName: string,
) => {
	return getModelCountApi(assetType, 'attribute', metadata, metadataType, {
		fieldName,
	});
};

export const getModelCountProximityApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
) => {
	return getModelCountApi(assetType, 'proximity', metadata, metadataType, {
		layerName,
	});
};

export const getModelCountProximityAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
	fieldName: string,
	valueType: string,
	distance: number,
) => {
	return getModelCountApi(
		assetType,
		'proximityAttribute',
		metadata,
		metadataType,
		{
			layerName,
			fieldName,
			maxDistance: distance,
		},
	);
};
