import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUnits, DataGrid, useGridApiRef } from '@innovyze/stylovyze';
import {
	selectDataGridState,
	selectRiskScoreDataGrid,
	selectRiskScores,
	selectRiskUnitScores,
} from '@Selectors';
import {
	setDataGridState,
	setModelEditScores,
	setModelScoreDataGrid,
	setModelScores,
	setModelUnitScores,
	updateModelScoreDataGrid,
	updateModelValuesDataGrid,
} from '@Actions';
import { tableColumns } from './TabelColumns';
import { TableToolbar, TableToolbarUnique } from './TableToolbar';
import { ModelValueChange } from '@Reducers/util.reducer';
import { ScoreDataGridRow } from '@Types/scoreDataGridType';
import { ComponentCountReply } from '@Types/risk.types';

export const updateModelCounts = (
	scoreRows: ScoreDataGridRow[],
	riskCounts: ComponentCountReply[] | undefined,
	isMetric: boolean,
	isUnique: boolean,
): ScoreDataGridRow[] => {
	if (riskCounts === undefined || riskCounts.length == 0) {
		const newScoreRows: ScoreDataGridRow[] = [];
		for (let i = 0; i < scoreRows.length; i++) {
			newScoreRows.push({
				...scoreRows[i],
				count: undefined,
				length: undefined,
				countPercent: undefined,
				lengthPercent: undefined,
			});
		}
		return newScoreRows;
	} else {
		const conversion = isMetric ? 1 : 0.06213712;
		const newScoreRows: ScoreDataGridRow[] = [];
		if (isUnique) {
			for (let i = 0; i < scoreRows.length; i++) {
				const count = riskCounts.find(
					({ key }) =>
						(key === undefined || key === null
							? '--'
							: key.toString()) === scoreRows[i].value.toString(),
				);

				if (count) {
					newScoreRows.push({
						...scoreRows[i],
						count: count.count,
						length: Math.round(count.length * conversion) / 100,
						countPercent:
							Math.round(count.count_percent * 1000) / 10,
						lengthPercent:
							Math.round(count.length_percent * 1000) / 10,
					});
				} else {
					newScoreRows.push({
						...scoreRows[i],
						count: 0,
						length: 0,
						countPercent: 0,
						lengthPercent: 0,
					});
				}
			}
		} else if (riskCounts) {
			for (let i = 0; i < scoreRows.length; i++) {
				newScoreRows.push({
					...scoreRows[i],
					count: riskCounts[i].count,
					length: Math.round(riskCounts[i].length * conversion) / 100,
					countPercent:
						Math.round(riskCounts[i].count_percent * 1000) / 10,
					lengthPercent:
						Math.round(riskCounts[i].length_percent * 1000) / 10,
				});
			}
		} else {
			for (let i = 0; i < scoreRows.length; i++) {
				newScoreRows.push({
					...scoreRows[i],
					count: 0,
					length: 0,
					countPercent: 0,
					lengthPercent: 0,
				});
			}
		}

		return newScoreRows;
	}
};

interface ScoreDataGridProps {
	isLoading: boolean;
	isLoadingCount: boolean;
	dataGridKey: string;
	countKey: string;
	isUnique: boolean;
	isUniqueGrouped: boolean;
	isNew: boolean;
	rangeClasses: number;
	distance?: number;
	unit?: string;
	currency?: string;
	precision?: number;
	hideCounts?: boolean;
	onSetRangeClasses: (classes: number) => void;
	onRangeValuesChanged: () => void;
	onRecalculateCount: () => void;
	onUnqueGroups: (groups: boolean) => void;
}

// This ensure we remove any extranious decimal places caused by javascript
const toFixedDP = (value: number, precision: number): number => {
	return +value.toFixed(precision);
};

const ScoreDataGrid = ({
	isLoading,
	isLoadingCount,
	dataGridKey,
	countKey,
	isUnique,
	isUniqueGrouped,
	rangeClasses,
	distance,
	unit,
	currency,
	precision,
	hideCounts,
	onSetRangeClasses,
	onRangeValuesChanged,
	onRecalculateCount,
	onUnqueGroups,
}: ScoreDataGridProps) => {
	const dispatch = useDispatch();
	const { getSystemUnit, getStandardisedValue, system } = useUnits();
	const [currentDistance, setCurrentDistance] = useState<number | undefined>(
		distance,
	);

	const apiRef = useGridApiRef();
	const dataGridState = selectDataGridState(dataGridKey);

	const scoreRows = selectRiskScoreDataGrid();
	const riskScore = selectRiskScores();
	const riskUnitScore = selectRiskUnitScores();

	const scoreMax = 1000;

	useEffect(() => {
		if (distance !== currentDistance) {
			setCurrentDistance(distance);
			if (scoreRows[0].count !== undefined) {
				onRangeValuesChanged();
				const newScoreRows = updateModelCounts(
					scoreRows,
					undefined,
					system === 'Metric',
					isUnique,
				);
				dispatch(setModelScoreDataGrid(newScoreRows));
			}
		}
	}, [distance]);

	const onScoreChange = (index: number, value: number) => {
		dispatch(updateModelScoreDataGrid({ index, score: value }));
	};

	const onRangeChange = (
		index: number,
		value: number,
		valueUnit?: number,
	) => {
		if (riskScore[index] !== value) {
			onRangeValuesChanged();

			// Clear the existing counts so they have to manual request them
			if (scoreRows[0].count !== undefined) {
				const newScoreRows = updateModelCounts(
					scoreRows,
					undefined,
					system === 'Metric',
					isUnique,
				);
				dispatch(setModelScoreDataGrid(newScoreRows));
			}

			const decimalPt = precision !== undefined ? precision : 1;
			const minDiff = toFixedDP(1 / Math.pow(10, decimalPt), decimalPt);

			let workingValue = value;
			let newScore = [...riskScore];

			// We need to work out if we need do this next bit in native or system units
			if (
				system === 'Imperial' &&
				riskUnitScore &&
				unit &&
				valueUnit != undefined
			) {
				workingValue = valueUnit;
				newScore = [...riskUnitScore];
			}

			// Has the user decremented or incremented the value?
			if (workingValue < newScore[index]) {
				newScore[index] = workingValue;
				// Decrementing so we only need to worry about the items BEFORE this index and if it's the first item we dont need to do anything at all
				if (index !== 0) {
					// Have we overlapped the previous value?
					let nextValue = workingValue;
					for (let i = index - 1; i >= 0; i--) {
						if (newScore[i] >= nextValue) {
							nextValue = toFixedDP(
								nextValue - minDiff,
								decimalPt,
							);
							newScore[i] = nextValue;
						}
					}
				}
			} else {
				newScore[index] = workingValue;
				// Incremeting so we only need to worry about the items AFTER this index and if it's the last item we just need to update the last entry
				if (index == newScore.length - 2)
					newScore[index + 1] = workingValue;
				else {
					// Have we overlapped the next value?
					let nextValue = workingValue;
					for (let i = index + 1; i <= newScore.length - 2; i++) {
						if (newScore[i] <= nextValue) {
							nextValue = toFixedDP(
								nextValue + minDiff,
								decimalPt,
							);
							newScore[i] = nextValue;
						}
					}
				}
			}

			// Ensure the last two values are the same
			newScore[newScore.length - 1] = newScore[newScore.length - 2];

			// Are we running a different display unit?
			if (
				system === 'Imperial' &&
				riskUnitScore &&
				unit &&
				valueUnit != undefined
			) {
				const internalUnit = getSystemUnit(unit);
				let adjustment = 1;
				if (valueUnit !== undefined && value != valueUnit) {
					// mm -> in -> cm
					if (unit === 'mm') adjustment = 10;
				}

				const nativeScores = newScore.map(score => {
					return (
						parseFloat(
							getStandardisedValue(`${score} ${internalUnit}`),
						) * adjustment
					);
				});
				const stringScores = nativeScores.map(score => {
					return score.toString();
				});
				dispatch(setModelEditScores(stringScores));
				dispatch(setModelScores(nativeScores));
				dispatch(setModelUnitScores(newScore));

				// Now switch back to the native scores for the next bit as thats in native units
				newScore = nativeScores;
			} else {
				const stringScores = newScore.map(score => {
					return score.toString();
				});
				dispatch(setModelEditScores(stringScores));
				dispatch(setModelScores(newScore));
			}

			const newValues: ModelValueChange[] = [];
			for (let i = 0; i < newScore.length; i++) {
				if (i == 0) {
					newValues.push({
						index: i,
						value: ` <= ${newScore[0]}`,
					});
				} else if (i < newScore.length - 1) {
					newValues.push({
						index: i,
						value: `${newScore[i - 1]} <= ${newScore[i]}`,
					});
				} else {
					newValues.push({
						index: i,
						value: `> ${newScore[i]}`,
					});
				}
			}

			dispatch(updateModelValuesDataGrid(newValues));
		}
	};

	const onStateChange = React.useCallback(() => {
		// We dont want to use the individual states that could be passed but just export the whole state to keep it all in sync
		const state = apiRef.current.exportState();
		dispatch(
			setDataGridState({
				key: dataGridKey,
				initialState: state,
			}),
		);
	}, [dataGridKey]);

	return (
		<DataGrid
			apiRef={apiRef}
			disableColumnMenu
			disableColumnReorder
			loading={isLoading}
			skeletonRowCount={rangeClasses + (isUnique ? 0 : 3)}
			columns={tableColumns(
				onRangeChange,
				isUnique,
				countKey,
				isUniqueGrouped,
				unit,
				currency,
				precision,
				dataGridState,
				hideCounts,
			)}
			rows={scoreRows}
			slots={{
				toolbar:
					isUnique && currentDistance === undefined
						? TableToolbarUnique
						: TableToolbar,
			}}
			slotProps={{
				toolbar: {
					isLoading: isLoading || isLoadingCount,
					isUnique: isUnique,
					isUniqueGrouped: isUniqueGrouped,
					hideCounts: hideCounts,
					onSetRangeClasses: onSetRangeClasses,
					onRecalculateCount: onRecalculateCount,
					rangeClasses: rangeClasses,
					recalculateOnly: isUnique,
					onUnqueGroups: onUnqueGroups,
					groupRows: scoreRows,
				},
			}}
			// DONT USE onStateChange={onStateChange} AS THIS GETS CALLED FOR EVERY USER ACTION... SO PROBABLY BEST TO AVOID !!
			onColumnWidthChange={onStateChange}
			dataCy="score-table"
		/>
	);
};

export default ScoreDataGrid;
