import { Pagination, Grid } from '@mui/material';
import styled from 'styled-components';
import { Text, rem } from '@innovyze/stylovyze';

interface PaginationProps {
	padding?: string;
}

export const FlexStyledGrid = styled(Grid)<PaginationProps>`
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	padding: ${props => (props.padding ? props.padding : rem(16) + rem(24))};
	align-items: center;
`;

export const StyledText = styled(Text)`
	align-self: flex-end;
	margin-left: auto;
	margin-top: ${rem(3)};
	margin-right: ${rem(3)};
`;

export const StyledPagination = styled(Pagination)`
	margin-left: auto;
	ul {
		font-style: normal;
		font-weight: 600;
		font-size: 1em;
		line-height: 1em;
	}

	.Mui-selected {
		border: 1px solid #007ca0;
		background-color: transparent;
	}
`;
