import {
	getModelConfigsList,
	getModelConfigsListRejected,
	getModelConfigsListResolved,
} from '@Actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { ModelConfigsListState } from '@Types';

// I HAVE NO IDEA WHY I HAVE TO DUPLICATE THIS HERE... without it I get this error when runnin in a module
// Uncaught Error: The slice reducer for key "riskConfigs" returned undefined during initialization. If the state passed to the reducer is undefined, you must explicitly return the initial state.
// It seems the paginationInitialState is getting initilized AFTER the reducers but I cant see why
export const initialState: ModelConfigsListState = {
	configs: [],
	isLoading: false,
};

interface ModelConfigsListReducer {
	[x: string]: (
		state: ModelConfigsListState,
		action: AnyAction,
	) => ModelConfigsListState | undefined;
}

export const reducer: ModelConfigsListReducer = {
	[getModelConfigsList.toString()]: () => ({
		...initialState,
		isLoading: true,
	}),
	[getModelConfigsListResolved.toString()]: (_state, action) => ({
		configs: action.payload,
		isLoading: false,
	}),
	[getModelConfigsListRejected.toString()]: () => ({
		...initialState,
	}),
};

export const modelConfigsListReducer = createReducer(initialState, reducer);

export default createReducer(initialState, reducer);
