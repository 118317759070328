import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InspDetails as InspectionDetailsComponent } from '@innovyze/lib_am_common/Components';
import { InspectionStandard } from '@innovyze/lib_am_common/types/inspection.types';

const InspectionDetails = () => {
	const params: { inspectionId: string; standard: InspectionStandard } =
		useParams();
	const history = useHistory();

	const back = () => {
		history.goBack();
	};

	return (
		<InspectionDetailsComponent
			inspectionId={params.inspectionId}
			standard={params.standard}
			back={{
				action: back,
				label: 'Back to Asset Details',
			}}
		/>
	);
};

export default InspectionDetails;
