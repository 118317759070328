import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
	ScoreDataGrid: {
		height: '100%',
		width: '100%',
		'& > div > div': {
			overflow: 'auto',
			height: '100%',
			width: '100%',
		},
		'& > div ': {
			overflow: 'auto',
			height: '100%',
			width: '100%',
		},
	},
}));

export const TextOverflowWrapper = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
