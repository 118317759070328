import { all } from 'redux-saga/effects';
import RiskSagas from './Risk.sagas';
import modelSagas from './model.sagas';
import modelConfigsSagas from './modelConfigs.sagas';
import failureSagas from './failure.sagas';
import failureConfigsSagas from './failureConfigs.sagas';
import assetFieldsSaga from './asset.sagas';
import componentSaga from './component.saga';

export default function* rootSaga() {
	yield all([
		...RiskSagas,
		...modelSagas,
		...modelConfigsSagas,
		...failureSagas,
		...failureConfigsSagas,
		...assetFieldsSaga,
		...componentSaga,
	]);
}
