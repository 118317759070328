import {
	addFailureSelected,
	changeFailurePage,
	changeFailureConfigPublishState,
	changeFailureSearch,
	changeFailureSlice,
	changeFailureSort,
	clearFailureConfig,
	clearFailureSelected,
	createFailureConfig,
	currentFailureRow,
	deleteFailure,
	duplicateFailureConfig,
	getFailureLog,
	getFailureLogRejected,
	getFailureLogResolved,
	getFailureConfig,
	getFailureConfigRejected,
	getFailureConfigResolved,
	getFailureDetails,
	getFailureDetailsRejected,
	getFailureDetailsResolved,
	removeFailureSelected,
	renameFailureConfigAction,
	saveFailureConfigAction,
	setFailureRefreshList,
	setFailureCalcStatus,
	setFailureConfigIsCreating,
	setFailureConfigNameError,
	setFailureDetailsIsBusy,
	setFailureIsDeleting,
	setFailureIsSaving,
	setFailureSetupDirty,
	setFailureRunCompleteReload,
	setFailureSaveRun,
	setFailureSelected,
	setFailureTab,
	changePagination,
	clearFailureScanId,
} from '@Actions';
import { createReducer } from '@reduxjs/toolkit';
import { LogReply, FailureConfig, FailureStoreState } from '@Types';
import { AnyAction } from 'redux';
import * as AM_COMMON from '@innovyze/lib_am_common/Reducers';
import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';
import { ConfigPublishState } from '@Types';
import { Pagination } from '@innovyze/lib_am_common';

const configInitState: FailureConfig = {
	failureSetting: {
		publishState: ConfigPublishState.unpublished,
		LastRun: '',
		lastRunCompleted: '',
		configId: '',
		assetType: '',
		name: '',
	},
};

const TEMPState: FailureConfig = {
	failureSetting: {
		publishState: ConfigPublishState.unpublished,
		LastRun: '',
		lastRunCompleted: '',
		configId: 'ca85f58a-fed8-4cd0-844a-1013d9cbb863',
		assetType: 'wwPipe',
		name: 'TEMP CONFIG',
	},
};

export const initState: FailureStoreState = {
	failureConfig: configInitState,
	isLoading: false,
	tab: '',
	failureDetails: paginationInitialState,
	search: '',
	saveRun: false,
	isSetupDirty: false,
	runCompleteReload: false,
	isCreating: false,
	isSaving: false,
	isDeleting: false,
	refreshList: true,
	enablePreview: false,
	cancelPreview: false,
	isPreviewLoading: false,
	previewStatus: '',
	failureConfigNameError: '',
};

interface FailureReducer {
	[x: string]: (
		state: FailureStoreState,
		action: AnyAction,
	) => FailureStoreState | undefined;
}

export const reducer: FailureReducer = {
	[getFailureConfig.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[getFailureConfigResolved.toString()]: (state, action) => {
		return {
			...state,
			failureConfig: TEMPState, //action.payload,
			previewStatus: '',
			isPreviewOnLoad: false,
			isPreviewLoading: false,
			isLoading: false,
		};
	},
	[getFailureConfigRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[clearFailureConfig.toString()]: state => ({
		...state,
		failureConfig: configInitState,
		failureDetails: paginationInitialState,
		search: '',
		saveRun: false,
		isSetupDirty: false,
	}),
	[createFailureConfig.toString()]: state => ({
		...state,
		isCreating: true,
	}),
	[duplicateFailureConfig.toString()]: state => ({
		...state,
		isCreating: true,
	}),
	[changeFailureConfigPublishState.toString()]: (state, action) => ({
		...state,
		failureConfig: {
			...state.failureConfig,
			failureSetting: {
				...state.failureConfig.failureSetting,
				publishState: action.payload.newPublishState,
			},
		},
	}),
	[renameFailureConfigAction.toString()]: (state, action) => ({
		...state,
		...action.payload,
		isSaving: true,
	}),
	[saveFailureConfigAction.toString()]: (state, action) => ({
		...state,
		failureConfig: { ...action.payload },
		editedCellsList: [],
		isSaving: true,
	}),
	[setFailureTab.toString()]: (state, action) => ({
		...state,
		tab: action.payload,
	}),
	[getFailureDetails.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateGet(
			state.failureDetails,
			action.payload,
		),
	}),
	[getFailureDetailsResolved.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateResolved(
			state.failureDetails,
			action.payload,
		),
	}),
	[getFailureDetailsRejected.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateRejected(
			state.failureDetails,
			action.payload,
		),
	}),
	[clearFailureScanId.toString()]: state => ({
		...state,
		failureDetails: { ...state.failureDetails, scanId: '' },
	}),
	[changeFailurePage.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updatePage(
			state.failureDetails,
			action.payload,
		),
	}),
	[changeFailureSlice.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateSlice(
			state.failureDetails,
			action.payload,
		),
	}),
	[changePagination.toString()]: (state, action) => {
		const query: Pagination = AM_COMMON.makePaginationQuery({
			page: action.payload?.page,
			slice: action.payload?.slice,
			pages: state.failureDetails.pagination.pages,
			total: state.failureDetails.pagination.total,
			subtotal: state.failureDetails.pagination.subtotal,
			search: state.failureDetails.pagination.search,
			sortKey: state.failureDetails.pagination.sortKey,
			sortDescending: state.failureDetails.pagination.sortDescending,
		});
		const pagination = {
			...state.failureDetails.pagination,
			slice:
				action.payload?.slice ??
				state.failureDetails?.pagination?.slice,
			page:
				action.payload?.page ?? state.failureDetails?.pagination?.page,
		};

		return {
			...state,
			failureDetails: {
				...state.failureDetails,
				isWaiting: true,
				status: AM_COMMON.statusLoading(),
				pagination,
				query,
			},
		};
	},
	[changeFailureSearch.toString()]: (state, action) => ({
		...state,
		search: action.payload,
		failureDetails: AM_COMMON.updateSearch(
			state.failureDetails,
			action.payload,
		),
	}),
	[changeFailureSort.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateSort(
			state.failureDetails,
			action.payload,
		),
	}),
	[clearFailureSelected.toString()]: state => ({
		...state,
		failureDetails: AM_COMMON.setSelected(state.failureDetails, []),
	}),
	[setFailureSelected.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.setSelected(
			state.failureDetails,
			action.payload,
		),
	}),
	[addFailureSelected.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.addSelected(
			state.failureDetails,
			action.payload,
		),
	}),
	[removeFailureSelected.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.removeSelected(
			state.failureDetails,
			action.payload,
		),
	}),
	[setFailureDetailsIsBusy.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.updateIsBusy(
			state.failureDetails,
			action.payload,
		),
	}),
	[currentFailureRow.toString()]: (state, action) => ({
		...state,
		failureDetails: AM_COMMON.setCurrentDbId(
			state.failureDetails,
			action.payload,
		),
	}),
	[setFailureSaveRun.toString()]: (state, action) => ({
		...state,
		saveRun: action.payload,
	}),
	[setFailureCalcStatus.toString()]: (state, action) => ({
		...state,
		calcStatus: action.payload,
	}),
	[getFailureLog.toString()]: state => ({
		...state,
		log: undefined,
		isLoading: true,
	}),
	[getFailureLogResolved.toString()]: (state, action) => {
		const log: LogReply = action.payload;
		if (log.startTime && log.startTime != '')
			log.startTime = new Date(Date.parse(log.startTime)).toISOString();
		if (log.endTime && log.endTime != '')
			log.endTime = new Date(Date.parse(log.endTime)).toISOString();
		return {
			...state,
			log: log,
			isLoading: false,
			failureConfig: {
				...state?.failureConfig,
				failureSetting: {
					...state?.failureConfig?.failureSetting,
					LastRun: log.startTime,
					lastRunCompleted:
						// Update the lastRunCompleted time only if we completed the calc otherwise just keep it
						log.calcStatus === 'risk-calc-complete'
							? log.startTime
							: state.failureConfig.failureSetting
									.lastRunCompleted,
				},
			},
		};
	},
	[getFailureLogRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[setFailureSetupDirty.toString()]: (state, action) => ({
		...state,
		isSetupDirty: action.payload,
		enablePreview: action.payload,
	}),
	[setFailureRunCompleteReload.toString()]: (state, action) => ({
		...state,
		runCompleteReload: action.payload,
	}),
	[setFailureRefreshList.toString()]: (state, action) => ({
		...state,
		refreshList: action.payload,
	}),
	[setFailureConfigNameError.toString()]: (state, action) => ({
		...state,
		failureConfigNameError: action.payload,
	}),
	[setFailureConfigIsCreating.toString()]: (state, action) => ({
		...state,
		isCreating: action.payload,
	}),
	[setFailureIsSaving.toString()]: (state, action) => ({
		...state,
		isSaving: action.payload,
	}),
	[deleteFailure.toString()]: state => ({
		...state,
		isDeleting: true,
	}),
	[setFailureIsDeleting.toString()]: (state, action) => ({
		...state,
		isDeleting: action.payload,
	}),
};

export const failureReducer = createReducer(initState, reducer);
