import React from 'react';
import { Skeleton } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-premium';
import { useSelectIsCountLoading } from '@Selectors/Risk.selectors';

interface SkeletonCellProps {
	id: GridRowId;
	countKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any | undefined;
	percent: boolean;
}

export const SkeletonCell = ({
	id,
	countKey,
	value,
	percent,
}: SkeletonCellProps) => {
	const isRiskCountLoading = useSelectIsCountLoading(countKey);
	let result;

	if (isRiskCountLoading) {
		result = (
			<Skeleton key={id} sx={{ mx: 1 }} width={'90%'} height={'100%'} />
		);
	} else {
		result = value !== undefined && percent ? `${value} %` : value ?? '';
	}

	return result;
};
