import riskApi, { riskBaseUrl } from '@Apis/risks.api';
import { ResolvedResponse, SpatialLayer } from '@innovyze/lib_am_common';
import {
	CreateFailureConfig,
	CreateFailureConfigPayload,
	FailureLogReply,
	FailureRunReply,
} from '@Types';
import { AxiosResponse } from 'axios';
import { ConfigPublishState, FailureConfig, ComponentMetadata } from '@Types';
import { t } from 'i18next';

export const getFailureConfigurationsApi = (query: string) => {
	const address = '/risk/configurations' + query;
	return riskApi.get<ResolvedResponse>(address);
};

export const getFailureConfigApi = (
	configId: string,
): Promise<AxiosResponse<FailureConfig>> => {
	const address = `/risk/config?configId=${configId}`;
	return riskApi.get<FailureConfig>(address);
};

export const createFailureConfigApi = (config: CreateFailureConfig) => {
	const address = '/risk/config';
	return riskApi.post<CreateFailureConfigPayload>(address, config);
};

export const saveFailureConfigApi = (config: FailureConfig) => {
	const address = '/risk/config/version';
	return riskApi.post(address, config);
};

export const duplicateFailureConfigApi = (configId: string) => {
	const address = `/risk/config/duplicate?configId=${configId}`;
	return riskApi.post(address);
};

export const startFailureCalculationApi = (configId: string) => {
	const address = `/risk/calc?configId=${configId}`;
	return riskApi.post(address);
};

export const resetFailureCalculationApi = (configId: string) => {
	const address = `/risk/calc/reset?configId=${configId}`;
	return riskApi.put(address);
};

export const resetFailurePreviewCalculationApi = (configId: string) => {
	const address = `/risk/calc/reset?configId=${configId}&isMatrixPreview=true`;
	return riskApi.put(address);
};

export const getFailureDetailsApi = (query: string) => {
	const address = '/risk/score';
	return riskApi.get<ResolvedResponse>(`${address}${query}`);
};

export default class FailureCalcStatusService {
	constructor(private token: string, private configId: string) {}

	public async getProcessStatus(): Promise<FailureRunReply> {
		const response = await fetch(
			`${riskBaseUrl}risk/calc/status?configId=${this.configId}`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${this.token}`,
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			},
		);

		if (response.ok) {
			return await response.json();
		}

		throw new Error(t('Unable to get risk calculation status'));
	}
}

export const getFailureCalcStatusService = (
	token: string,
	configId: string,
) => {
	return new FailureCalcStatusService(token, configId);
};

export const getFailureCalcLogApi = (configId: string) => {
	const address = `/risk/calc/log?configId=${configId}`;
	return riskApi.get<FailureLogReply>(`${address}`);
};

export const getSpatialLayerListApi = () => {
	const address = '/spatial/layerlist';
	return riskApi.get<SpatialLayer[]>(address);
};

export const deleteFailureApi = (id: string) => {
	const address = `/risk/config?configId=${id}`;
	return riskApi.delete(address);
};

export const changeFailureConfigPublishStateApi = (
	newPublishState: ConfigPublishState,
	configId: string,
) => {
	const address = `/risk/config/publish?configId=${configId}`;
	return riskApi.put(address, { newPublishState });
};

const getFailureCountApi = (
	assetType: string,
	fieldType: string,
	metadata: ComponentMetadata[],
	metadataType: string,
	additionalParams?: Record<string, string | number>,
) => {
	const params = new URLSearchParams({
		assetType,
		fieldType,
		...additionalParams,
	});
	const address = `/risk/count?${params.toString()}`;

	return riskApi.post(address, {
		metadata,
		metadataType,
	});
};

export const getFailureCountAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	fieldName: string,
) => {
	return getFailureCountApi(assetType, 'attribute', metadata, metadataType, {
		fieldName,
	});
};

export const getFailureCountProximityApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
) => {
	return getFailureCountApi(assetType, 'proximity', metadata, metadataType, {
		layerName,
	});
};

export const getFailureCountProximityAtributeApi = (
	metadata: ComponentMetadata[],
	metadataType: string,
	assetType: string,
	layerName: string,
	fieldName: string,
	valueType: string,
	distance: number,
) => {
	return getFailureCountApi(
		assetType,
		'proximityAttribute',
		metadata,
		metadataType,
		{
			layerName,
			fieldName,
			maxDistance: distance,
		},
	);
};
