import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { Text } from '@innovyze/stylovyze';
import { StyledGrid } from '@Utils/styles';
import { Grid } from '@mui/material';

export const TableToolbar = () => {
	const { t } = useGlobalization();

	return (
		<StyledGrid
			container
			style={{
				width: '100%',
				marginTop: '1rem',
				marginBottom: '1rem',
			}}>
			<Grid item xs={8}>
				<Text variant="heading-large-strong">{t('Group Details')}</Text>
			</Grid>
		</StyledGrid>
	);
};
