import { ComponentMetadataResponse, GetComponentMetadataParams } from '@Types';
import { createAction } from '@reduxjs/toolkit';

export const clearComponentMetadata = createAction(
	'Comp/clearComponentMetadata',
);
export const getComponentAssetMetadata =
	createAction<GetComponentMetadataParams>('Comp/getComponentAssetMetadata');
export const getComponentProximityMetadata =
	createAction<GetComponentMetadataParams>(
		'Comp/getComponentProximityMetadata',
	);
export const getComponentProximityAttrMetadata =
	createAction<GetComponentMetadataParams>(
		'Comp/getComponentProximityAttrMetadata',
	);

export const getComponentMetadataResolved =
	createAction<ComponentMetadataResponse>(
		'Comp/getComponentMetadataResolved',
	);
export const getComponentMetadataRejected = createAction(
	'Comp/getComponentMetadataRejected',
);
