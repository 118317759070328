import { Chart, ChartingErrorBoundary } from '@innovyze/summaryze';
import React, { useMemo, useState } from 'react';

export const LifeExpectancy = () => {
	const [inspectionMonthSeries, setInspectionMonthSeries] = useState<
		Highcharts.SeriesOptionsType[]
	>([]);

	const getOptions = (): Highcharts.Options => {
		return {
			title: {
				text: undefined,
			},
			xAxis: {
				type: 'linear',
				min: 0,
				allowDecimals: false,
				title: {
					text: 'Life Expectancy (Years)',
				},
			},
			yAxis: [
				{
					type: 'linear',
					min: 0,
					max: 100,
					allowDecimals: false,
					title: {
						text: 'Survival Probability (%)',
					},
				},
			],
			legend: { enabled: false },
			chart: {
				type: 'spline',
				reflow: true,
				height: 350,
				zoomType: 'xy',
			},
			plotOptions: {
				spline: {
					marker: {
						enabled: false,
					},
				},
			},
		};
	};

	return (
		<ChartingErrorBoundary chartProps={getOptions()}>
			<Chart
				cy={'LEChart'}
				dataCy={'LEChart'}
				series={[
					{
						name: 'Survival Probability',
						type: 'spline',
						data: [
							[0, 100],
							[10, 80],
							[20, 70],
							[30, 60],
							[40, 40],
							[50, 30],
							[60, 20],
							[70, 10],
						],
					},
				]}
				options={getOptions()}
				minHeight={''}
			/>
		</ChartingErrorBoundary>
	);
};
